<template>
	<div class="tabpane-1">
		<el-row :gutter="20">
			<el-col :span="8">
				<div class="text-label">Customer Name</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="elrow-class">
			<el-col :span="8">
				<el-autocomplete
					class="inline-input"
					v-model="form.customerName"
					:disabled="isDisabled"
					:fetch-suggestions="getCustomerName"
					placeholder=""
				></el-autocomplete>
				<!-- <el-input :disabled="isDisabled" v-model="form.customerName" size="small" class="el-search-input"> </el-input> -->
			</el-col>
		</el-row>
		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label">PIC Name</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">OP Email</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">OP MOB</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="elrow-class">
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.picName" size="small" class="el-search-input"> </el-input>
			</el-col>
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.opEmail" size="small" class="el-search-input"> </el-input>
			</el-col>
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.opMob" size="small" class="el-search-input"> </el-input>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label">PIC Name 1</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">OP Email 1</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">OP MOB 1</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="elrow-class">
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.picNameOne" size="small" class="el-search-input"> </el-input>
			</el-col>
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.opEmailOne" size="small" class="el-search-input"> </el-input>
			</el-col>
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.opMobOne" size="small" class="el-search-input"> </el-input>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label">PIC Name 2</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">OP Email 2</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">OP MOB 2</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="elrow-class">
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.picNameTwo" size="small" class="el-search-input"> </el-input>
			</el-col>
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.opEmailTwo" size="small" class="el-search-input"> </el-input>
			</el-col>
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.opMobTwo" size="small" class="el-search-input"> </el-input>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label">Remarks</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="elrow-class">
			<el-col :span="24">
				<el-input
					:disabled="isDisabled"
					v-model="form.remarks"
					size="small"
					type="textarea"
					style="height: 70px"
					class="el-search-input"
				>
				</el-input>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label-bold">· Master Contact</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label">Master Name</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">Master Email</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">Master Email 2</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="elrow-class">
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.masterName" size="small" class="el-search-input"> </el-input>
			</el-col>
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.masterEmail" size="small" class="el-search-input"> </el-input>
			</el-col>
			<el-col :span="8">
				<el-input :disabled="isDisabled" v-model="form.masterEmailTwo" size="small" class="el-search-input"> </el-input>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { addBasicInfo, updateBasicInfo } from '@/api/basic';
import { request } from '@/api/basic';
import { getToken } from '@/api/cookies';
export default {
	props: {
		customerId: {
			type: String,
			default: '',
		},
		basicData: {
			type: Object,
			default: () => {},
		},
		showEdit: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			form: {},
		};
	},

	watch: {
		basicData: {
			handler(val) {
				//console.log(val, 'val');
				if (val) {
					this.form = {
						...val,
					};
				} else {
					this.form = {};
				}
			},
		},
	},

	computed: {
		isDisabled() {
			return Boolean(this.customerId && this.showEdit);
		},
	},

	mounted() {},
	methods: {
		getCustomerName(queryString, cb) {
			request({
				url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/tenant/list',
				method: 'GET',
				body: {
					keyword: queryString,
					pageNum: 1,
					pageSize: 1000,
				},
				header: {
					Authorization:'Bearer '+ getToken(),
				},
			}).then((res) => {
				let array = [];
				res.data.items.forEach((element) => {
					array.push({
						label: element,
						value: element,
					});
				});
				cb(array);
			});
		},
		async saveBasicInfo(bool) {
			let params = {
				basicCustomer: {
					...this.form,
				},
				type: 'basicCustomer',
			};

			const FUNC = this.basicData ? updateBasicInfo : addBasicInfo;
			let data = await FUNC(params);
			if (data.code === 200) {
				data.data && this.$emit('setCustomerId', data.data);
				if (bool) {
					setTimeout(() => {
						this.$parent.$parent.$parent.saveBasicInfoAwait();
					}, 1000);
				}
				//this.$bus.$emit('refreshList');
				// this.$message.success('Save Success!');
			} else {
				this.$message.info(data.message);
			}
		},
	},
};
</script>
<template>
	<div class="tabpane3">
		<div class="port-list">
			<div :class="['port-item', '']" v-for="(item, index) in portList" :key="item.uuid">
				<div class="port-index">{{ index + 1 }}</div>
				<div class="type">
					<el-select :disabled="isDisabled" class="type-select" size="mini" v-model="item.type" clearable>
						<el-option v-for="v in typeList" :key="v.name" :value="v.name" :label="v.name"> </el-option>
					</el-select>
				</div>
				<div class="port">
					<el-autocomplete
						class="port-select"
						v-model="item.portName"
						size="mini"
						clearable
						:disabled="isDisabled"
						:fetch-suggestions="getPortNameList"
						placeholder="请输入港口"
						@select="handleSelect"
					></el-autocomplete>
					<!-- <el-select
						class="port-select"
						size="mini"
						v-model="item.name"
						filterable
						remote
						:remote-method="remoteMethod"
						clearable
						:disabled="isDisabled"
						@change="(portCode) => handleSelectPort(portCode, index)"
					>
						<el-option
							v-for="v in editPortNameList.concat(portNameList)"
							:key="v.portCode"
							:value="v.portCode"
							:label="v.portName"
						>
						</el-option>
					</el-select> -->
				</div>
				<div class="time">
					<mydatePicker
						:disabled="isDisabled"
						size="mini"
						align="center"
						v-model="item.time"
						type="datetime"
						format="yyyy-MM-dd HH:mm"
						value-format="yyyy-MM-dd HH:mm"
					>
					</mydatePicker>
					<!-- <mydatePicker :disabled="isDisabled" align="center" v-if="item.type === 'Loading'" size="mini"
						v-model="item.timeOther" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm">
					</mydatePicker> -->
				</div>
				<div class="btns">
					<el-button
						:disabled="isDisabled"
						@click="addPortItem(item.type, index)"
						icon="el-icon-plus"
						circle
						size="small"
					></el-button>
					<el-button
						:disabled="isDisabled"
						@click="removePortItem(index)"
						icon="el-icon-minus"
						circle
						size="small"
					></el-button>
					<!-- <el-button @click="upItem(index)" icon="el-icon-top" circle size="small"></el-button>
					<el-button @click="downItem(index)" icon="el-icon-bottom" circle size="small"></el-button> -->
				</div>
			</div>
		</div>

		<el-divider></el-divider>

		<div class="remarks">
			<div class="title">Voyage Remarks</div>
			<el-input :disabled="isDisabled" type="textarea" :rows="4" v-model="remarks"></el-input>
		</div>
		<el-form :model="termsForm" class="terms-form">
			<el-row class="text-row-class" :gutter="10">
				<el-col :span="8">
					<el-form-item label="Cargo:">
						<el-input size="mini" :disabled="isDisabled" v-model="termsForm.cargo"> </el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="Deck cargo:">
						<el-input size="mini" :disabled="isDisabled" v-model="termsForm.deckCargo"> </el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="GM:">
						<el-input size="mini" :disabled="isDisabled" v-model="termsForm.gm"> </el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="text-row-class" :gutter="10">
				<el-col :span="8">
					<el-form-item label="Roll Period:">
						<el-input size="mini" :disabled="isDisabled" v-model="termsForm.rollPeriod"> </el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="FORE Drift:">
						<el-input size="mini" :disabled="isDisabled" v-model="termsForm.foreDrift"> </el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="AFT Drift:">
						<el-input size="mini" :disabled="isDisabled" v-model="termsForm.aftDrift"> </el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { addBasicInfo, updateBasicInfo, request } from '@/api/basic';
import { getToken } from '@/api/cookies';
import mapobj from '@/utils/mapw.js';

export default {
	directives: {
		'el-select-loadmore': (el, binding) => {
			const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
			if (SELECTWRAP_DOM) {
				SELECTWRAP_DOM.addEventListener('scroll', function () {
					/**
					 * scrollHeight 获取元素内容高度(只读)
					 * scrollTop 获取或者设置元素的偏移值,
					 *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
					 * clientHeight 读取元素的可见高度(只读)     * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
					 * ele.scrollHeight - ele.scrollTop === ele.clientHeight;     */

					const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
					if (condition) binding.value();
				});
			}
		},
	},

	props: {
		customerId: {
			type: String,
			default: '',
		},
		basicData: {
			type: Object,
			default: () => {},
		},
		showEdit: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			portList: [],
			termsForm: {
				cargo: '',
				deckCargo: '',
				gm: '',
				rollPeriod: '',
				foreDrift: '',
				aftDrift: '',
			},
			portNameList: [],
			currentPortNameList: [],
			typeList: [
				{
					name: 'Departure',
				},
				{
					name: 'Loading',
				},
				{
					name: 'Transit',
				},
				{
					name: 'Discharing',
				},
				{
					name: 'Bunker',
				},
				{
					name: 'Redelivery',
				},
			],
			remarks: '',
			rangeNumber: 30,
			tempList1: [],
			tempList2: [],
			editPortNameList: [],
		};
	},

	watch: {
		basicData: {
			handler(val) {
				//console.log(val, 'basic3 val');
				if (val?.id) {
					//this.editPortNameList = [];
					this.remarks = val.voyageRemakes;
					// 编辑时将已选择的港口加入到接口获取到的港口下拉列表中
					if (val.basicPortRemarksSonList?.length) {
						// val.basicPortRemarksSonList.forEach((item) => {
						// 	this.editPortNameList.push({
						// 		name: item.name,
						// 		portName: item.portName,
						// 	});
						// });
						this.portList = val.basicPortRemarksSonList;
						//this.handleSelect();
					}
					this.termsForm = {
						cargo: val.cargo,
						deckCargo: val.deckCargo,
						gm: val.gm,
						rollPeriod: val.rollPeriod,
						foreDrift: val.foreDrift,
						aftDrift: val.aftDrift,
					};
				} else {
					this.initPortList();
					this.remarks = '';
					this.termsForm = {
						cargo: '',
						deckCargo: '',
						gm: '',
						rollPeriod: '',
						foreDrift: '',
						aftDrift: '',
					};
				}
			},
		},
	},

	computed: {
		isDisabled() {
			return Boolean(this.customerId && this.showEdit);
		},
	},

	mounted() {
		this.initPortList();
		//this.getPortNameList();
	},

	methods: {
		handleSelect(val) {
			if (val) {
				this.portList.forEach((item) => {
					if (item.portName == val.portName) {
						item.name = val.portCode;
						item.lon = val.lon;
						item.lat = val.lat;
					}
				});
			}
			mapobj.heightLightByNameList(this.portList);
		},
		getPortNameList(queryString, cb) {
			request({
				url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/port/search',
				method: 'GET',
				body: {
					keyword: queryString,
					pageNum: 1,
					pageSize: 20,
				},
				header: {
					Authorization: 'Bearer ' + getToken(),
				},
			}).then((res) => {
				//console.log(res);
				let array = [];
				res.data.forEach((element) => {
					element.value = element.portName;
					array.push(element);
				});
				cb(array);
			});
		},
		// getPortNameList(query) {
		// 	request({
		// 		url: 'http://gate.fltins.com:18080/gateway/voyage/v1/port/search',
		// 		method: 'GET',
		// 		body: {
		// 			keyword: query,
		// 			pageSize: 20,
		// 			pageNum: 1,
		// 		},
		// 		header: {
		// 			Authorization: getToken(),
		// 		},
		// 	}).then((res) => {
		// 		//console.log('getPortNameList==', res);
		// 		if (res.code === 200) {
		// 			this.portNameList = res.data;
		// 		} else {
		// 			this.portNameList = [];
		// 		}
		// 	});
		// },
		// 新增
		async saveBasicInfo(bool) {
			if (!this.customerId) {
				this.$message.info('请先确认Customer信息已被保存');
				return;
			}

			let basicPortRemarksSonList = this.portList.map((item, index) => {
				return {
					...item,
					sort: index + 1,
				};
			});
			console.log('portList', basicPortRemarksSonList);

			let params = {
				basicPortRemarks: {
					basicPortRemarksSonList,
					voyageRemakes: this.remarks,
					...this.termsForm,
				},
				type: 'basicPortRemarks',
				parentId: this.customerId,
			};

			this.basicData?.id && (params.basicPortRemarks.id = this.basicData?.id);

			const FUNC = this.basicData?.id ? updateBasicInfo : addBasicInfo;
			let data = await FUNC(params);
			if (data.code === 200 && !bool) {
				this.$message.success('Save Success!');
				this.$bus.$emit('refreshList');
			}
		},

		addPortItem(type, index) {
			this.portList.splice(index + 1, 0, {
				type: '',
				name: '',
				portName: '',
				time: '',
				timeOther: '',
				uuid: uuidv4(),
			});
		},

		removePortItem(index) {
			this.portList.splice(index, 1);
		},

		upItem(index) {
			if (index === 1) {
				this.$message.info('禁止移动到第一级');
				return;
			}
			this.swap(this.portList, index, index - 1);
		},

		downItem(index) {
			if (index === this.portList.length - 2) {
				this.$message.info('禁止移动到最后一级');
				return;
			}
			this.swap(this.portList, index, index + 1);
		},

		swap(arr, index1, index2) {
			const ele1 = arr[index1];
			const ele2 = arr[index2];
			this.$set(this.portList, index1, ele2);
			this.$set(this.portList, index2, ele1);
		},

		initPortList() {
			this.portList = [
				{
					type: 'Departure',
					name: '',
					portName: '',
					time: '',
					timeOther: '',
					uuid: uuidv4(),
				},
				{
					type: 'Redelivery',
					name: '',
					time: '',
					timeOther: '',
					uuid: uuidv4(),
				},
			];
		},

		// 给portName赋值，传给后端
		handleSelectPort(portCode, index) {
			//console.log(this.portList);
			//console.log(portCode);
			let portName = this.portNameList.find((item) => item.portCode === portCode)?.portName;
			this.portList[index].portName = portName;
			//console.log('portList===', this.portList);
		},

		remoteMethod(query) {
			this.getPortNameList(query);
		},
	},
};
</script>

<style lang="scss" scoped>
.port-list {
	.port-item {
		display: flex;
		align-items: end;
		margin-bottom: 15px;

		.port-index {
			margin-right: 15px;
			width: 32px;
			height: 32px;
			border-radius: 16px;
			text-align: center;
			line-height: 32px;
			color: rgba(53, 86, 145, 0.9);
			font-size: 16px;

			background: rgba(53, 86, 145, 0.2);
			border: 1px solid rgba(53, 86, 145, 0.2);
			border-radius: 32px;
		}

		.type,
		.port,
		.time {
			margin-right: 15px;

			.port-name,
			.port-time {
				font-size: 14px;
				color: #484848;
				line-height: 22px;
			}

			.el-select {
				width: 100px;
			}

			.port-select {
				width: 200px;
			}

			.el-input {
				width: 180px;
			}
		}

		.port {
			.el-select {
				width: 150px;
			}
		}

		.btns {
			::v-deep .el-button {
				color: #d9d9d9;
			}
		}
	}

	.loading-type {
		.el-input {
			width: 90px !important;
			margin-right: 10px;
		}
	}
}

::v-deep .terms-form {
	.el-form-item:before,
	.el-form-item:after {
		content: none;
	}

	.el-form-item {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;

		label {
			font-size: 12px;
			width: 180px;
			text-align: left;
			color: #484848;
			font-weight: normal;
		}
	}

	.double-input {
		.el-form-item__content {
			display: flex;

			.short-input {
				margin-right: 5px;
			}
		}
	}

	.short-input {
		width: 50px;

		.el-input__inner {
			padding: 0 5px;
		}
	}

	.short-select {
		width: 70px;
	}

	.short-input-number {
		width: 60px;

		.el-input__inner {
			padding: 0 5px;
		}
	}
}

.remarks {
	.title {
		margin-bottom: 20px;
	}
}
</style>

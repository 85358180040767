export const transform2DM = (latlong = 0) => {
    let dm = ''
    if (latlong) {
      latlong = Math.abs(latlong)
      const degrees = Math.trunc(latlong)
      const decimal = latlong - degrees
      const minutes = (decimal * 60).toFixed(3)
      dm = `${degrees}°${minutes}`
      // const minutes = Math.trunc(decimal * 60)
      // const seconds = Math.trunc((decimal * 60 - minutes) * 60)
      // return `${degrees}°${minutes}.${seconds}`
    }
    return dm
  }
<template>
	<div class="top-content" @mouseenter="ishowStatus = true" @mouseleave="ishowStatus = false">
		<div class="icon-show">
			<img :src="require('../../assets/img/left.svg')" class="weather-icon" @click="leftContentClick" alt="" />
		</div>
		<div class="play-btn" v-show="!isPlay" @click="play()">PLAY</div>
		<div class="play-btn" v-show="isPlay" @click="pause()">PAUSE</div>

		<div class="play-btn el-icon-arrow-left" @click="backTime()"></div>
		<div class="play-btn el-icon-arrow-right" @click="nextTime()"></div>
		<div class="now-btn" @click="changeTimeNow">NOW</div>
		<div class="hour-select">
			<el-select size="small" v-model="hoursValue" placeholder="请选择">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
			</el-select>
		</div>
		<!-- <div class="stop-btn">
			<i class="el-icon-video-play"></i>
		</div> -->
		<div class="time-btn">
			<mydatePicker
				size="small"
				align="center"
				v-model="timesValue"
				value-format="yyyy-MM-dd HH:mm"
				format="yyyy-MM-dd HH:mm"
				@change="changeTime"
				type="datetime"
				placeholder="选择日期时间"
				default-time="12:00:00"
			>
			</mydatePicker>
		</div>
		<div class="weather-list">
			<div
				:class="item.check ? 'weather-btn active' : 'weather-btn'"
				v-for="(item, index) in weatherList"
				:key="item.icon"
			>
				<el-tooltip class="item" effect="dark" :content="item.name" placement="bottom">
					<img :src="item.icon" class="weather-icon" @click="weatherBtnClick(index)" alt="" />
				</el-tooltip>
			</div>
		</div>
		<div class="right-icon">
			<img :src="require('../../assets/img/right.svg')" class="weather-icon" @click="rightContentClick" alt="" />
		</div>
		<div class="status-list">
			<div
				class="weather-btn"
				v-for="(item, index) in statusList"
				:key="item.icon"
				:class="item.needBackground && item.check ? 'act-button' : ''"
			>
				<el-tooltip class="item" effect="dark" :content="item.label" placement="bottom">
					<img
						:src="item.check ? item.acticon : item.icon"
						:class="[
							!item.needBackground ? 'status-icon' : 'measure-icon',
							'Nautical Chart' === item.label ? 'big-icon' : '',
						]"
						@click="statusBtnClick(index, item.label)"
						alt=""
					/>
				</el-tooltip>
			</div>
		</div>
	</div>
</template>

<script>
import mapobj from '@/utils/mapw.js';

export default {
	data() {
		return {
			isPlay: false,
			playInterval: null,
			weatherList: [
				//气压
				{
					id: 'pressure',
					name: 'pressure',
					check: false,
					icon: require('../../assets/img/pressure.svg'),
				},
				{
					// 风
					id: 'wind',
					name: 'wind',
					check: false,
					icon: require('../../assets/img/wind.svg'),
				},
				{
					//洋流
					id: 'current',
					name: 'current',
					check: false,
					icon: require('../../assets/img/current.svg'),
				},
				{
					//有效波高
					id: 'wave',
					name: 'wave',
					check: false,
					icon: require('../../assets/img/wave.svg'),
				},
				{
					//台风
					id: 'typhoon',
					name: 'typhoon',
					check: false,
					icon: require('../../assets/img/typhoon.svg'),
				},
				// {
				// 	//冰区
				// 	id: 'weather-6',
				// 	name: '冰川',
				// 	check: false,
				// 	icon: require('../../assets/img/weather-6.png'),
				// },
				{
					//涌浪
					id: 'swell1',
					name: 'swell',
					check: false,
					icon: require('../../assets/img/swell.svg'),
				},
			],
			ishowStatus: false,
			statusList: [
				{
					label: 'Ports',
					check: false,
					icon: require('../../assets/svg/monitoring_ports.svg'),
					acticon: require('../../assets/svg/monitoring_ports_checked.svg'),
					layerIds: ['ports'],
				},
				{
					label: 'Load Line',
					check: false,
					icon: require('../../assets/svg/monitoring_loadline.svg'),
					acticon: require('../../assets/svg/monitoring_loadline_checked.svg'),
					layerIds: ['load-line-point', 'load-line-polygon'],
				},
				{
					label: 'ECA Zones',
					check: false,
					icon: require('../../assets/svg/monitoring_eca.svg'),
					acticon: require('../../assets/svg/monitoring_eca_checked.svg'),
					layerIds: ['eca-zones-point', 'eca-zones-polygon'],
				},
				{
					label: 'WAR Zones',
					check: false,
					icon: require('../../assets/svg/monitoring_war.svg'),
					acticon: require('../../assets/svg/monitoring_war_checked.svg'),
					layerIds: ['war-zones-point', 'war-zones-polygon'],
				},
				{
					label: 'Rhumb Line',
					check: false,
					icon: require('../../assets/svg/monitoring_measure_line.svg'),
					acticon: require('../../assets/svg/monitoring_measure_line_checked.svg'),
					layerIds: ['war-zones-point', 'war-zones-polygon'],
					needBackground: true,
				},
				{
					label: 'Great Circle',
					check: false,
					icon: require('../../assets/svg/monitoring_measure_circle.svg'),
					acticon: require('../../assets/svg/monitoring_measure_circle_checked.svg'),
					layerIds: ['war-zones-point', 'war-zones-polygon'],
					needBackground: true,
				},
				{
					label: 'Nautical Chart',
					check: false,
					icon: require('../../assets/svg/monitoring_chart.svg'),
					acticon: require('../../assets/svg/monitoring_chart_checked.svg'),
					layerIds: ['war-zones-point', 'war-zones-polygon'],
					needBackground: true,
				},
				{
					// ais历史轨迹
					label: 'AIS',
					check: true,
					icon: require('../../assets/svg/ais_checked.svg'),
					acticon: require('../../assets/svg/ais.svg'),
					layerId: 'vesselTrackLayer',
					needBackground: true,
				},
				{
					// 报文
					label: 'Master',
					check: true,
					icon: require('../../assets/svg/master_checked.svg'),
					acticon: require('../../assets/svg/master.svg'),
					layerId: 'reportLayer',
					needBackground: true,
				},
			],
			timesValue: this.getNowTime(),
			hoursValue: 3,
			options: [
				{
					value: 3,
					label: '3 hours',
				},
				{
					value: 6,
					label: '6 hours',
				},
				{
					value: 12,
					label: '12 hours',
				},
				// {
				// 	value: 18,
				// 	label: '18 hours',
				// },
				{
					value: 24,
					label: '24 hours',
				},
			],
			// 测量类型
			measureControlType: null,
		};
	},

	created() {},
	mounted() {},
	methods: {
		// 开始播放
		play() {
			this.isPlay = true;
			const starTime = new Date(this.timesValue);
			// 结束时间为三天后
			const endTime = new Date(starTime.getTime() + 3 * 24 * 60 * 60 * 1000 + 23 * 60 * 60 * 1000);
			if (this.playInterval) {
				clearInterval(this.playInterval);
				this.playInterval = null;
			}
			this.playInterval = setInterval(() => {
				const newTime = new Date(new Date(this.timesValue).getTime() + this.hoursValue * 60 * 60 * 1000);
				// 不限制
				this.changeTime(newTime);
				// 限制
				// if (newTime.getTime() < endTime.getTime()) {
				// 	this.timesValue = newTime;
				// } else {
				// 	this.timesValue = starTime;
				// }
			}, 3000);
		},
		pause() {
			this.isPlay = false;
			if (this.playInterval) {
				clearInterval(this.playInterval);
				this.playInterval = null;
			}
		},
		// 清除
		clearPlayInterval() {
			if (this.playInterval) {
				clearInterval(this.playInterval);
				this.playInterval = null;
			}
		},
		// 获取当前时间
		getNowTime(data) {
			this.clearPlayInterval();
			let now;
			if (data) {
				now = data;
			} else {
				now = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);
				// now = new Date();
				var y = now.getFullYear();
				var m = now.getMonth() + 1;
				m = m < 10 ? '0' + m : m;
				var d = now.getDate();
				d = d < 10 ? '0' + d : d;
				let oneTime = new Date(y + '-' + m + '-' + d + ' ' + '00:00:00').getTime();
				let date1 = now.getTime();
				for (let index = 0; index < 10; index++) {
					const element = oneTime + index * 3 * 60 * 60 * 1000;
					if (element >= date1) {
						now = new Date(element);
						break;
					}
				}
			}
			var year = now.getFullYear(); //得到年份
			var month = now.getMonth(); //得到月份
			var date = now.getDate(); //得到日期
			var hour = now.getHours(); //得到小时
			var minute = now.getMinutes(); //得到分钟
			var second = now.getSeconds(); //得到秒钟
			month = month + 1;
			month = month.toString().padStart(2, '0');
			date = date.toString().padStart(2, '0');
			hour = hour.toString().padStart(2, '0'); // 将小时转为字符串，并用 padStart 方法补全到两位数
			minute = minute.toString().padStart(2, '0'); // 将分钟转为字符串，并用 padStart 方法补全到两位数
			second = second.toString().padStart(2, '0'); // 将秒钟转为字符串，并用 padStart 方法补全到两位数
			var defaultDate = `${year}-${month}-${date} ${hour}:${minute}:${second}`;
			console.log('🚀 ~ getNowTime ~ defaultDate:', defaultDate);
			return defaultDate;
		},
		changeTimeNow() {
			this.isPlay = false;
			this.changeTime(this.getNowTime());
		},
		// 下一时间
		nextTime() {
			this.clearPlayInterval();
			this.isPlay = false;
			const newTime = new Date(new Date(this.timesValue).getTime() + this.hoursValue * 60 * 60 * 1000);
			// 不限制
			this.changeTime(newTime);
		},
		// 上一时间
		backTime() {
			this.clearPlayInterval();
			this.isPlay = false;
			const newTime = new Date(new Date(this.timesValue).getTime() - this.hoursValue * 60 * 60 * 1000);
			// 不限制
			this.changeTime(newTime);
		},
		changeTime(e) {
			console.log('data', e);
			// 小时取整
			const date = new Date(e);
			// this.hoursValue
			let desiredTimesObj = {
				3: [0, 3, 6, 9, 12, 15, 18, 21],
				6: [0, 6, 12, 18],
				12: [0, 12],
				24: [0],
			};
			// 将你想要的时间点放进数组
			let desiredTimes = desiredTimesObj[this.hoursValue];
			let currentHour = date.getHours();

			// 找到大于当前时间的最近的时间点
			let nextHour = desiredTimes.find((hour) => hour >= currentHour);
			// 如果没有找到（也就是当前时间超过了数组中的最大时间），就设定为数组中的第一项
			if (!nextHour) nextHour = desiredTimes[0];

			// 设置新的小时数
			date.setHours(nextHour);
			date.setMinutes(0);
			date.setSeconds(0);
			date.setMilliseconds(0);
			this.timesValue = date;
			const timestamp = new Date(e).getTime();
			this.$parent.$refs.rightContent.changeShipPosition(e);
			//console.log(timestamp);
			this.$parent.core.store.set('timestamp', timestamp);
		},
		statusBtnClick(index, label) {
			// 绘制
			if (['Great Circle', 'Rhumb Line'].includes(label)) {
				if (!this.statusList[index].check) {
					if (this.measureControlType) {
						this.statusList.find((item) => item.label === this.measureControlType).check = false;
					}
					this.statusList[index].check = true;
				} else {
					// 取消编辑
					this.measureControlType = null;
					this.statusList[index].check = false;
				}
				// 开启 || 取消 编辑
				this.measureControlType = label === 'Rhumb Line' ? 'Rhumb Line' : 'Great Circle';
				this.$parent.switchMeasure(label === 'Rhumb Line' ? false : true);
			} else if (label == 'Nautical Chart') {
				this.statusList[index].check = !this.statusList[index].check;
				mapobj.initCharsLayer(this.statusList[index].check);
			} else {
				this.statusList[index].check = !this.statusList[index].check;
				// 气象
				if (this.statusList[index].layerIds) {
					this.$parent.voyageLayerControl(this.statusList[index].layerIds, this.statusList[index].check);
				} else {
					// 隐藏图层
					console.log(this.statusList[index].layerId);
					console.log(mapobj[this.statusList[index].layerId]);
					if (this.statusList[index].check) {
						mapobj[this.statusList[index].layerId].addTo(mapobj.Lmap);
					} else {
						mapobj[this.statusList[index].layerId].remove();
					}
				}
			}
		},
		weatherBtnClick(index) {
			this.weatherList[index].check = !this.weatherList[index].check;
			switch (this.weatherList[index].id) {
				case 'pressure':
					this.$parent.changMapState('pressure', this.weatherList[index].check);
					break;
				case 'wind':
					this.$parent.changMapState('wind', this.weatherList[index].check);
					break;
				case 'current':
					this.$parent.changMapState('current', this.weatherList[index].check);
					break;
				case 'wave':
					this.$parent.changMapState('wave', this.weatherList[index].check);
					this.weatherList.find((e) => e.id === 'swell1').check = false;
					break;
				case 'typhoon':
					this.$parent.changMapState('typhoon', this.weatherList[index].check);
					break;
				case 'weather-6':
					break;
				case 'swell1':
					this.$parent.changMapState('swell1', this.weatherList[index].check);
					this.weatherList.find((e) => e.id === 'wave').check = false;
					break;
			}
		},
		leftContentClick() {
			this.$parent.leftContentShow = !this.$parent.leftContentShow;
		},
		rightContentClick() {
			this.$parent.rightContentShow = !this.$parent.rightContentShow;
		},
	},
};
</script>

<style lang="scss" scoped>
.top-content {
	position: relative;
	width: 805px;
	top: 0;
	left: 379px;
	z-index: 999;
	height: 46px;
	border-width: 0px, 1px, 1px, 0px;
	border-style: solid;
	border-color: #e1e1e1;
	background: #eef1f4;
	display: flex;
	flex-flow: row;
	padding: 5px;
	.weather-icon {
		width: 24px;
		height: 24px;
	}

	.right-icon {
		font-size: 22px;
		color: #355691;
		cursor: pointer;
		width: 32px;
		height: 32px;
		padding: 5px;
	}

	.icon-show {
		font-size: 22px;
		color: #355691;
		cursor: pointer;
		width: 32px;
		height: 32px;
		margin-right: 20px;
		padding: 5px;
	}

	.play-btn,
	.now-btn {
		width: 58px;
		height: 32px;
		padding: 2px, 12px, 2px, 12px;
		border-radius: 6px;
		border: 1px;
		background: white;
		line-height: 32px;
		text-align: center;
		border-width: 0px, 1px, 1px, 0px;
		border-style: solid;
		border-color: #e1e1e1;
		cursor: pointer;
		margin-left: 5px;
		font-size: 12px;
	}

	.hour-select {
		width: 96px;
		margin-left: 5px;
	}

	.stop-btn {
		width: 32px;
		height: 32px;
		font-size: 25px;
		border-radius: 6px;
		border: 1px;
		background: white;
		line-height: 32px;
		text-align: center;
		border-width: 0px, 1px, 1px, 0px;
		border-style: solid;
		border-color: #e1e1e1;
		margin-left: 5px;
		cursor: pointer;
	}

	.time-btn {
		::v-deep .el-date-editor {
			width: 175px;
		}

		margin-left: 5px;
	}

	.weather-list {
		width: 248px;
		margin-left: 10px;
		display: flex;
		flex-flow: row;
		padding: 2px;

		.weather-btn {
			width: 32px;
			height: 32px;
			cursor: pointer;
			border-radius: 6px;
			background: #fff;
			border: 1px solid #d9d9d9;
			margin-right: 5px;
			padding: 5px 0 0 5px;

			.weather-icon {
				width: 20px;
				height: 20px;
			}
		}
	}

	.status-list {
		position: absolute;
		background: #eef1f4;
		top: 2.5rem;
		right: 0;
		display: flex;
		flex-flow: row;
		padding: 0.125rem;
		width: 285px;
		justify-content: space-around;

		.weather-btn {
			width: 32px;
			height: 32px;
			cursor: pointer;
			border-radius: 6px;
			background: #fff;
			border: 1px solid #d9d9d9;
			text-align: center;

			.status-icon {
				width: 32px;
				height: 32px;
			}
			.measure-icon {
				width: 16px;
				height: 32px;
			}
			.big-icon {
				width: 28px;
				height: 32px;
			}
		}

		.act-button {
			background: #355691;
		}
	}

	.active {
		background: cornflowerblue !important;
	}
}
</style>

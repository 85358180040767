import BaseApi from './base';
const baseApi = new BaseApi('/api/pdf');

/**
 * 查询列表
 * 关键字方法，用于搜索列表
 * @param {Object} params 请求参数
 * @return {Object|null} 返回结果，错误则返回null
 */
export function exportpdf(params) {
	return baseApi.exportPost(params, 'export');
}
export function exporExcel(params) {
	return baseApi.exportPost(params, 'excel');
}
export function exportpdfAgain(params) {
	return baseApi.exportPost(params, 'againExport', 'get');
}
export function addCollect(params) {
	return baseApi.post(params, 'addCollect');
}
export function findCollect(params) {
	return baseApi.get(params, 'findCollect');
}
export function findCollectInfo(params) {
	return baseApi.get(params, 'findCollectInfo');
}
export function deleteCollect(params) {
	return baseApi.get(params, 'deleteCollect');
}

import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import ECAData from '@/data/ECA-zones.json';
import spriteJson from '@/assets/sprite/sprite';
import * as turf from '@turf/turf';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Polygon from '@arcgis/core/geometry/Polygon';
import Polyline from '@arcgis/core/geometry/Polyline';
import html2canvas from 'html2canvas';
import { request } from '@/api/basic';
import { getToken } from '@/api/cookies';

// import * as THREE from 'three';
let portsList = []; // 总港口数据
let lineStyle = {
	dashArray: '1, 3', // 虚线的样式，前面的数字表示实线段的长度，后面的数字表示虚线段的长度
	color: 'blue',
	weight: 3,
};
let editlineStyle = {
	color: 'blue',
	weight: 1,
	opacity: 0.5,
};

const eventListen = {
	drawEdit: null, // 绘制修改事件
	layerClick: null, //选中图层
	exportImage: null, //截图
};
export default {
	Lmap: null,
	mapboxMap: null,
	testData: [],
	actLine: 0, // 当前选中线，默认为第一条
	lineNum: 0, // 记录总线数
	aisPointLayer: null, // ais点图层
	aisLineLayer: null, // ais线图层
	editLineLayer: null, // 编辑线图层
	editLineLayerStepList: [], // 记录编辑线图层步骤
	pointLayer: null, // 点图层
	pointData: null, // 点图层数据
	circleLineLayer: null, // 大圆航线
	routePlanTableData: null, // 线列表数据
	areaData: null, // 合并后的范围面
	selectPointType: null, // 地图选点类型
	selectPointList: [],
	starPoint: null, // 起点
	endPoint: null, // 终点
	vesselTrackLayer: null, // 历史轨迹图
	reportLayer: null, // 日报图层
	initSprite() {
		let that = this;
		var img = new Image();
		img.onload = function () {
			Object.keys(spriteJson).forEach((key) => {
				let spriteItem = spriteJson[key];
				let { x, y, width, height } = spriteItem;
				let canvas = that.createCavans(width, height);
				let context = canvas.getContext('2d');
				context.drawImage(img, x, y, width, height, 0, 0, width, height);
				// 单位雪碧图项，转base64字符串
				let base64Url = canvas.toDataURL('image/png');
				that.mapboxMap.loadImage(base64Url, (error, simg) => {
					if (!that.mapboxMap.hasImage(key)) {
						that.mapboxMap.addImage(key, simg);
					}
				});
			});
		};
		img.crossOrigin = 'anonymous';
		img.src = require('../assets/sprite/sprite.png');
		let array = ['aisnoservice'];
		array.forEach((element) => {
			that.mapboxMap.loadImage(require('../assets/img/' + element + '.png'), (error, simg) => {
				if (!that.mapboxMap.hasImage(element)) {
					that.mapboxMap.addImage(element, simg);
				}
			});
		});
	},
	createCavans(width, height) {
		var canvas = document.createElement('canvas');
		canvas.width = width;
		canvas.height = height;
		return canvas;
	},
	formatDateTime(date, isMin, isAtMin) {
		date = new Date(date);
		var y = date.getFullYear();
		var m = date.getMonth() + 1;
		m = m < 10 ? '0' + m : m;
		var d = date.getDate();
		d = d < 10 ? '0' + d : d;
		var h = date.getHours();
		h = h < 10 ? '0' + h : h;
		var minute = date.getMinutes();
		minute = minute < 10 ? '0' + minute : minute;
		var second = date.getSeconds();
		second = second < 10 ? '0' + second : second;
		if (isAtMin) {
			return m + '-' + d + ' ' + h + ':' + minute;
		}
		if (isMin) {
			return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
		}
		return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
	},
	formatDateTimeWeather(data) {
		// 创建一个 Date 对象
		const date = new Date(data);

		// 获取月份的名称缩写
		const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		const monthName = monthNames[date.getMonth()];

		// 获取日期和时间部分
		const day = date.getDate();
		const hours = date.getHours();
		const minutes = date.getMinutes();

		// 将日期、小时和分钟转换为字符串
		const dateString = `${monthName}-${day}/${hours.toString().padStart(2, '0')}${minutes
			.toString()
			.padStart(2, '0')}Z`;
		return dateString;
	},
	//获取转向点信息
	async getWptPointWeatherInfo(wptdata, changeIndex) {
		let wptlist = wptdata.WPTList;
		let Summary = wptdata.Summary;
		let wptPointList = [];
		if (wptlist && wptlist.length) {
			for (let index = 0; index < wptlist.length; index++) {
				const element = wptlist[index];
				wptPointList.push({
					dataTime: new Date(element.ETD).getTime(),
					lat: element.Lat,
					lng: element.Lon,
				});
			}
		}
		wptdata.Summary = Summary;
		request({
			url: process.env.VUE_APP_WEATHER_API + '/api/meteorological/discrete',
			method: 'POST',
			body: wptPointList,
			header: {
				'Content-Type': 'application/json',
			},
		}).then((res) => {
			if (res && res.data && res.data.length) {
				let cfList = [];
				for (let index = 0; index < wptlist.length; index++) {
					const element = wptlist[index];
					let findData = res.data.find((item) => {
						return item.paramTime == new Date(element.ETD).getTime();
					});
					if (findData) {
						element.WindSpeed = findData.windSpeed;
						element.WindDir = findData.windDir;
						element.SigWaveHeight = findData.sigWaveHeight;
						element.WindWaveHeight = findData.windWaveHeight;
						element.CurrentDir = findData.currentDir;
						element.SwellHeight = findData.swellHeight;
						element.SwellDir = findData.swellDir;
						//element.WF = findData.windDir;
						let json = {
							index: index,
							currentSpeed: findData.currentSpeed,
							currentDir: element.CurrentDir,
							windSpeed: element.WindSpeed,
							windDir: element.WindDir,
							sigWaveHeight: element.sigWaveHeight,
							windWaveDir: findData.windWaveDirection,
							swellDir: findData.swellDir,
							windWaveHeight: element.WindWaveHeight,
							swellHeight: findData.swellHeight,
							cog: element.Course,
							vesselSpeed: element.STW,
						};
						cfList.push(json);
					}
				}
				request({
					url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/monitoring/report/cf-wf',
					method: 'POST',
					body: cfList,
					header: {
						Authorization: 'Bearer ' + getToken(),
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					if (res && res.data && res.data.length) {
						for (let index = 0; index < res.data.length; index++) {
							const element = res.data[index];
							cfList[index] = {
								...cfList[index],
								...element,
							};
						}
						for (let index = 0; index < wptlist.length; index++) {
							const element = wptlist[index];
							let findData = cfList.find((item) => {
								return item.index == index;
							});
							if (findData) {
								element.WF = findData.wf;
								element.CF = findData.cf;
							}
						}
						this.clearWPTpoint(wptdata, changeIndex, true);
					}
				});
			}
		});
	},
	async getWeatherInfoByPromise(wptPointList) {
		let array = [];
		let promiseList = [];
		for (let i = 0; i < wptPointList.length; i++) {
			array.push(wptPointList[i]);
			if ((i % 10 == 0 && i != 0) || i == wptPointList.length - 1) {
				let list = JSON.parse(JSON.stringify(array));
				array = [];
				promiseList.push(
					request({
						url: process.env.VUE_APP_WEATHER_API + '/api/meteorological/discrete',
						method: 'POST',
						body: list,
						header: {
							'Content-Type': 'application/json',
						},
					}),
				);
			}
		}
		let res = await Promise.all(promiseList);
		let arrlist = [];
		if (res && res.length) {
			res.forEach((element) => {
				if (element && element.data && element.data.length) {
					element.data.forEach((ele) => {
						arrlist.push(ele);
					});
				}
			});
		}
		return arrlist;
	},
	getWptByAis(wptdata, parameter) {
		let wptlist = wptdata.WPTList;
		let array = [];
		let endPoint = [];
		if (wptlist && wptlist.length) {
			for (let index = 0; index < wptlist.length; index++) {
				const element = wptlist[index];
				array.push([element.Lon, element.Lat]);
				if (index == wptlist.length - 1) {
					endPoint = [element.Lon, element.Lat];
				}
			}
		}
		var line = turf.lineString(array);
		var pt = turf.point([parameter.lon, parameter.lat]);
		var snapped = turf.nearestPointOnLine(line, pt);
		var stop = turf.point(endPoint);
		var sliced = turf.lineSlice(snapped, stop, line);
		let coordinates = sliced.geometry.coordinates;
		if (
			coordinates &&
			coordinates.length &&
			coordinates.length > 2 &&
			coordinates[coordinates.length - 1] == coordinates[coordinates.length - 2]
		) {
			coordinates.splice(coordinates.length - 1, 1);
		}
		let arrayWpt = [];
		coordinates[0] = [parameter.lon, parameter.lat];
		coordinates.forEach((element, i) => {
			let index = wptlist.findIndex((res) => {
				return element[0] == res.Lon && element[1] == res.Lat;
			});
			if (index > -1) {
				arrayWpt.push(wptlist[index].LegType);
			} else {
				arrayWpt.push('RL');
			}
		});
		return {
			coordinates: coordinates,
			arrayWpt: arrayWpt,
		};
	},
	//给转向点赋油耗
	getWptPointInfo(wptdata, parameter) {
		wptdata = JSON.parse(JSON.stringify(wptdata));
		let wptlist = wptdata.WPTList;
		let Summary = wptdata.Summary;
		if (wptlist && wptlist.length) {
			for (let index = 0; index < wptlist.length; index++) {
				const element = wptlist[index];
				if (index == 0 && parameter.etd) {
					element.ETD = parameter.etd;
				}
				element.DayFuel = parameter.DayFuel;
				element.LSFO = parameter.LSFO;
				element.HSFO = parameter.HSFO;
				element.MDO = parameter.MDO;
				element.MGO = parameter.MGO;
				element.STW = parameter.speed;
			}
		}
		Summary.pdfList = [];
		Summary.ETD = parameter.ETD;
		Summary.ATD = parameter.atd;
		Summary.updateTime = '';
		Summary.DayFuel = parameter.DayFuel;
		wptdata.Summary = Summary;
		return wptdata;
	},
	// 推荐点位
	addRecommendPoint(data, time) {
		//先把前面添加推荐点位删除
		if (this.recommendPointList && this.recommendPointList.length) {
			this.recommendPointList.forEach((element) => {
				this.Lmap.removeLayer(element);
			});
		}
		this.recommendPointList = [];
		if (data) {
			data.forEach(async (element) => {
				let latLng = false;
				let cog = 0;
				if (element.WPTList && element.WPTList.length) {
					var lineList = JSON.parse(JSON.stringify(element.WPTList));
					await this.get_wptlist_for_circle(lineList);
					var list = [];
					for (let index = 0; index < lineList.length; index++) {
						const element = lineList[index];
						list.push([element.Lon, element.Lat]);
					}
					let startTime;
					let lastPoint = false;
					let hasDistance = 0;
					for (let index = 0; index < lineList.length; index++) {
						const ele = lineList[index];
						if (index == 0) {
							if (ele.ETD && time) {
								startTime = new Date(ele.ETD).getTime();
								let time1 = new Date(time).getTime();
								//如果ETD时间大于当前时间，取第一个点的位置
								if (startTime > time1) {
									latLng = {
										lat: ele.Lat,
										lng: ele.Lon,
									};
									cog = parseFloat(ele.Course);
									break;
								}
							}
							lastPoint = ele;
							hasDistance = 0;
						} else if (index == lineList.length - 1) {
							if (ele.ETD && time) {
								let startTime1 = new Date(ele.ETD).getTime();
								let time1 = new Date(time).getTime();
								//如果当前时间大于最后一个点的位置，取最后一个点
								if (startTime1 < time1) {
									latLng = {
										lat: ele.Lat,
										lng: ele.Lon,
									};
									cog = parseFloat(ele.Course);
								}
							}
						} else {
							if (ele.ETD && time) {
								let startTime1 = new Date(ele.ETD).getTime();
								let time1 = new Date(time).getTime();
								if (startTime1 > time1) {
									// 如果不是大圆航线
									// if (lineList[index - 1]?.ETD) {
									// 	let stTime = new Date(lineList[index - 1].ETD).getTime();
									// 	let timeRatio = (time1 - stTime) / (startTime1 - stTime)
									// 	latLng = {
									// 		lat: (ele.Lat - lineList[index - 1].Lat) * timeRatio + lineList[index - 1].Lat,
									// 		lng: (ele.Lon - lineList[index - 1].Lon) * timeRatio + lineList[index - 1].Lon,
									// 	};
									// }else{
									let startTime2 = new Date(lastPoint.ETD).getTime();
									let difTime = (time1 - startTime2) / 1000 / 60 / 60;
									let distance = (difTime * parseFloat(lastPoint.STW)) / 0.539957;
									let startDic = hasDistance / 0.539957;
									var array = JSON.parse(JSON.stringify(list));
									var line = turf.lineString(array);
									var sliced = turf.lineSliceAlong(line, startDic + distance, startDic + distance);
									latLng = {
										lat: sliced.geometry.coordinates[1][1],
										lng: sliced.geometry.coordinates[1][0],
									};
									// }
									var from = turf.point([lineList[index - 1].Lon, lineList[index - 1].Lat]);
									var to = turf.point([ele.Lon, ele.Lat]);
									var bearing = turf.bearing(from, to);
									cog = bearing;
									break;
								}
								hasDistance += parseFloat(lastPoint.Distance);
								lastPoint = ele;
							}
						}
					}
				}
				//添加推荐点位
				if (latLng) {
					let icon = L.icon({
						iconUrl: require('../assets/img/goodais.png'), // 图标文件路径
						iconSize: [40, 40], // 图标尺寸
						iconAnchor: [20, 20], // 图标偏移位置
					});
					let aisPointLayer = L.marker(latLng, {
						icon: icon,
						rotationAngle: cog, //需要旋转的角度
						draggable: false,
					}).addTo(this.Lmap);
					this.recommendPointList.push(aisPointLayer);
				}
			});
		}
	},
	// 最后AIS船位点
	addAiSPoint(data, cog) {
		let geojson = {
			type: 'FeatureCollection',
			features: [],
		};
		if (data) {
			geojson.features.push({
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: data,
				},
				properties: {
					cog: cog,
				},
			});
		}
		if (this.mapboxMap.getLayer('aisPointLayer')) {
			this.mapboxMap.getSource('aisPointLayer').setData(geojson);
		} else {
			this.mapboxMap.addLayer({
				id: 'aisPointLayer',
				type: 'symbol',
				source: {
					type: 'geojson',
					data: geojson,
				},
				layout: {
					'icon-image': 'aisnoservice',
					'symbol-spacing': 10,
					'icon-size': 0.28,
					'icon-rotate': ['get', 'cog'],
					'icon-padding': 0,
					'icon-ignore-placement': true,
					'icon-allow-overlap': true,
				},
				paint: {},
			});
		}
		// let latLng = {
		// 	lat: data[1],
		// 	lng: data[0],
		// };
		// let icon = L.icon({
		// 	iconUrl: require('../assets/img/aisnoservice.png'), // 图标文件路径
		// 	iconSize: [40, 40], // 图标尺寸
		// 	iconAnchor: [20, 20], // 图标偏移位置
		// });
		// if (this.aisPointLayer) {
		// 	console.log(1111111111111111);
		// 	this.Lmap.removeLayer(this.aisPointLayer);
		// }
		// this.aisPointLayer = L.marker(latLng, {
		// 	icon: icon,
		// 	rotationAngle: cog, //需要旋转的角度
		// 	draggable: false,
		// }).addTo(this.Lmap);
	},
	// 添加AIS船舶历史轨迹路线
	addAiSLine(data) {
		if (this.aisLineLayer) {
			// 清除当前图层的所有要素
			this.aisLineLayer.clearLayers();
		}
		if (data) {
			let myRenderer = L.canvas({ padding: 0.5 });
			this.aisLineLayer = L.geoJson(data, {
				renderer: myRenderer,
			}).addTo(this.Lmap);
		}
	},
	// 属性处理
	clearWPTpoint(wptdata, changeIndex, isLoadWeather) {
		let wptlist = wptdata.WPTList;
		let Summary = wptdata.Summary;
		var avgWfCf = {
			WfCount: 0,
			CfCount: 0,
			WfValue: 0,
			CfValue: 0,
		};
		if (wptlist && wptlist.length) {
			let list = [];
			for (let index = 0; index < wptlist.length; index++) {
				const element = wptlist[index];
				list.push([element.Lon, element.Lat]);
			}
			var line = turf.lineString(list);
			var totalDistance = turf.length(line) * 0.539957;
			Summary.TotalVoyage = totalDistance;
			var AccuFuel = 0;
			var RemainDistance = 0;
			var Durations = 0;
			var AccuFuel1 = {
				LSFO: 0,
				HSFO: 0,
				MDO: 0,
				MGO: 0,
			};
			for (let index = 0; index < wptlist.length; index++) {
				const element = wptlist[index];
				if (index == 0) {
					element.LegType = 'RL';
				}
				if (index < wptlist.length - 1) {
					element['RemainDistance'] = totalDistance - RemainDistance;

					if (element.DayFuel) {
						element['AccuFuel'] = (element['RemainTime'] / 24) * element.DayFuel;
					}
					const nextWpt = wptlist[index + 1];
					var from = turf.point([element.Lon, element.Lat]);
					var to = turf.point([nextWpt.Lon, nextWpt.Lat]);
					// if (element.LegType == 'GC') {
					// 	var greatCircle = turf.greatCircle(from, to, { name: 'Seattle to DC' });
					// 	var length = turf.length(greatCircle) * 0.539957;
					// }
					var distance = turf.distance(from, to) * 0.539957;
					var bearing = turf.bearing(from, to);
					element['Distance'] = distance;
					if (element['Durations']) {
						Durations += parseFloat(element['Durations']);
					}
					RemainDistance += distance;
					if (changeIndex && changeIndex.index === index + 1 && changeIndex.isChangeType == 'ETD') {
						nextWpt['ETA'] = nextWpt['ETD'];
						element.STW =
							distance /
							((new Date(nextWpt['ETD']).getTime() - new Date(element['ETA']).getTime()) / 3600000).toFixed(2);
						element.IntentionSpeed = element.STW;
					}
					if (element.STW) {
						element.STW = parseFloat(element.STW);
					} else {
						element.STW = parseFloat(element.speed);
					}
					if (!element.IntentionSpeed) {
						if (!element.WF && !element.CF) {
							element.IntentionSpeed = element.STW;
						} else {
							if (element.WF && element.WF != 'NaN' && element.WF < 999) {
								element.IntentionSpeed = element.STW - parseFloat(element.WF);
							} else {
								element.IntentionSpeed = element.STW;
							}
							if (element.CF && element.CF != 'NaN' && element.CF < 999) {
								if (element.IntentionSpeed) {
									element.IntentionSpeed = element.IntentionSpeed - parseFloat(element.CF);
								} else {
									element.IntentionSpeed = element.STW - parseFloat(element.CF);
								}
							} else {
								element.IntentionSpeed = element.STW;
							}
						}
					}
					if (changeIndex && changeIndex.index === index && changeIndex.isChangeType == 'Speed') {
						element.IntentionSpeed = element.STW;
					}
					if (isLoadWeather) {
						var speed = parseFloat(element.IntentionSpeed);
						if (element.WF && element.WF < 999) {
							avgWfCf.WfCount++;
							avgWfCf.WfValue += parseFloat(element.WF);
							speed = speed + parseFloat(element.WF);
						}
						if (element.CF && element.CF < 999) {
							avgWfCf.CfCount++;
							avgWfCf.CfValue += parseFloat(element.CF);
							speed = speed + parseFloat(element.CF);
						}
						element.STW = speed;
					}
					nextWpt['ETA'] = this.formatDateTime(
						new Date(element.ETD).getTime() + (distance / element.STW) * 60 * 60 * 1000,
					);
					if (nextWpt.StayTime) {
						nextWpt['ETD'] = this.formatDateTime(
							new Date(nextWpt.ETA).getTime() + parseFloat(element.StayTime) * 60 * 60 * 1000,
						);
					} else {
						nextWpt['ETD'] = nextWpt['ETA'];
					}
					element['RemainTime'] = element['RemainDistance'] / element.STW;
					element['Course'] = bearing > 0 ? bearing : bearing + 360;
					if (element.LSFO) {
						AccuFuel1.LSFO += (distance / element.STW / 24) * parseFloat(element.LSFO);
					}
					if (element.HSFO) {
						AccuFuel1.HSFO += (distance / element.STW / 24) * parseFloat(element.HSFO);
					}
					if (element.MDO) {
						AccuFuel1.MDO += (distance / element.STW / 24) * parseFloat(element.MDO);
					}
					if (element.MGO) {
						AccuFuel1.MGO += (distance / element.STW / 24) * parseFloat(element.MGO);
					}
					element['Durations'] = distance / element.STW;
					AccuFuel += (distance / element.STW / 24) * element.DayFuel;
				} else {
					element['RemainDistance'] = 0;
					element['RemainTime'] = 0;
					element['AccuFuel'] = 0;
					element['Distance'] = 0;
					element['Course'] = 0;
					element['Durations'] = 0;
				}

				let str = this.ChangeToDFM(element['Lat'], 0) + '/' + this.ChangeToDFM(element['Lon'], 1);
				element['LatLon'] = str;
			}
			Summary.ETA = wptlist[wptlist.length - 1].ETA;
			Summary.BestAccuFuel = AccuFuel;
			Summary.Durations = Durations;
			Summary.FuelConsumption =
				AccuFuel1.HSFO.toFixed(2) +
				'/' +
				AccuFuel1.LSFO.toFixed(2) +
				'/' +
				AccuFuel1.MDO.toFixed(2) +
				'/' +
				AccuFuel1.MGO.toFixed(2);
			let lineList = JSON.parse(JSON.stringify(wptlist));
			this.get_wptlist_for_circle(lineList);
			const paths = [];
			lineList.forEach((e) => {
				paths.push([e.Lon, e.Lat]);
			});
			let polyline = new Polyline({
				paths: paths,
			});
			let lineGeometry = geometryEngine.intersect(polyline, this.areaData);
			if (lineGeometry) {
				let line = turf.lineString(lineGeometry.paths[0]);
				let length = turf.length(line) * 0.539957;
				Summary.ecaDistance = length;
				Summary.ecaFuel = (Summary.ecaDistance / Summary.AvgSTW / 24) * parseFloat(Summary.DayFuel);
			} else {
				Summary.ecaDistance = 0;
				Summary.ecaFuel = 0;
			}
		}
		if (avgWfCf?.WfCount) {
			Summary.WF = avgWfCf.WfValue / avgWfCf.WfCount;
		}
		if (avgWfCf?.CfCount) {
			Summary.CF = avgWfCf.CfValue / avgWfCf.CfCount;
		}
		wptdata.Summary = Summary;
		if (!isLoadWeather) {
			this.getWeatherInfoByPromise(wptdata, false);
		}
		return wptdata;
	},
	// 初始化范围面加载
	addAreaData() {
		let data = {
			type: 'FeatureCollection',
			features: [],
		};
		ECAData.features.forEach((e) => {
			if (e.geometry.type === 'Polygon') {
				let polygon = new Polygon({
					rings: e.geometry.coordinates[0],
				});
				if (this.areaData) {
					this.areaData = geometryEngine.union([this.areaData, polygon]);
				} else {
					this.areaData = polygon;
				}
			}
		});
		data.features = ECAData.features.filter((e) => {
			return e.geometry.type === 'Polygon';
		});

		// let myRenderer = L.canvas({ padding: 0.5 });
		// L.geoJson(data, {
		// 	renderer: myRenderer
		// }).addTo(this.Lmap);
	},
	// 初始数据处理
	getGeojsonData(data) {
		let Features = [],
			edittingFeatures = [];
		this.lineNum = data.length;
		this.clean_route_geojson(data, Features, edittingFeatures);
		let point = Features.filter((e) => {
			return e.geometry.type === 'Point';
		});
		let line = Features.filter((e) => {
			return e.geometry.type !== 'Point';
		});
		this.pointData = {
			type: 'FeatureCollection',
			features: point,
		};
		var SourceGeoData = {
			type: 'FeatureCollection',
			features: line,
		};
		let style = {
			color: 'blue',
			weight: 1,
			opacity: 0.5,
		};
		this.addPointLayer(this.pointData); // 添加点图
		// 添加大圆航线
		this.circleLineData(this.pointData);
		// this.circleLineLayer.bringToFront()
		// 假设 this.pointLayer 已经添加到地图上
		if (this.pointLayer && this.pointLayer.getLayers().length > 0) {
			let isFixed = false;
			this.pointData.features.forEach((element) => {
				if (
					element.geometry.coordinates &&
					element.geometry.coordinates.length &&
					element.geometry.coordinates[0] > 180
				) {
					isFixed = true;
				}
			});
			if (isFixed) {
				let ais = JSON.parse(JSON.stringify(this.aisGeojsonData));
				ais.ais.features.forEach((element) => {
					element.geometry.coordinates[0].forEach((ele) => {
						ele[0] += 360;
					});
				});
				const bound = turf.bbox(ais.ais);
				let latlngBounds = L.latLngBounds([bound[1], bound[0]], [bound[3], bound[2]]);
				this.Lmap.fitBounds(latlngBounds);
			}
			// 确保图层上有要素
			// var bounds = this.pointLayer.getBounds(); // 获取图层的边界
			// this.Lmap.fitBounds(bounds); // 调整地图视图以适应这些边界
		} else {
			console.log('pointLayer is empty or not defined'); // 如果图层为空或未定义，则输出错误信息
		}
	},
	// 编辑图层
	createEditLine(id, pdf, routeId) {
		if (eventListen.layerClick && eventListen.layerClick instanceof Function) {
			eventListen.layerClick({
				linenum: id,
				pdf: pdf,
				routeId: routeId,
			});
		}
		this.actLine = id;
		// 如果已有pdf则不开启编辑
		if (pdf) {
			// console.log('当前选中的id不可编辑');
			return;
		}
		let resultArray = this.pointData.features.filter((item) => {
			return item.properties.linenum === id;
		});
		let lineData = this.pointToLine(resultArray, id);
		let myRenderer = L.canvas({ padding: 0.5 });
		if (this.editLineLayer) {
			// 清除当前图层的所有要素
			this.editLineLayer.clearLayers();
			// this.Lmap.removeLayer(this.editLineLayer);
			this.editLineLayer.addData(lineData);
		} else {
			this.editLineLayer = L.geoJson(lineData, {
				style: editlineStyle,
				renderer: myRenderer,
			}).addTo(this.Lmap);
		}
		this.editLineLayer.pm.enable({
			snappable: false, // 捕捉能力
			// snapDistance: 0, // 发生捕捉时到另一个顶点的距离。
		});
		this.monitorEvent();
	},
	// 通过index属性移除selectPoint
	removeSelectPoint(index) {
		if (index) {
			let data = this.selectPointList.find((e) => e.index === index);
			if (data) {
				this.Lmap.removeLayer(data.marker);
				this.selectPointList = this.selectPointList.filter((e) => e.index !== index);
			}
		} else {
			this.selectPointList.forEach((e) => {
				this.Lmap.removeLayer(e.marker);
			});
			this.selectPointList = [];
		}
	},
	resolvePointData(pointList) {
		var lstLonDiff = [];
		for (var i = 0; i < pointList.length - 1; i++) {
			var detLon = pointList[i + 1].geometry.coordinates[0] - pointList[i].geometry.coordinates[0];
			if (Math.abs(detLon) > 180) {
				if (detLon > 0) {
					detLon -= 360;
				} else {
					detLon += 360;
				}
			}
			lstLonDiff.push(detLon);
		}
		if (pointList[0].geometry.coordinates[0] < 0) pointList[0].geometry.coordinates[0] += 360;
		for (var i = 0; i < pointList.length - 1; i++) {
			pointList[i + 1].geometry.coordinates[0] = pointList[i].geometry.coordinates[0] + lstLonDiff[i];
		}
	},
	// 根据点数据通过linenum字段过滤生成大圆航线数据
	circleLineData(data) {
		// console.log(data);
		var that = this;
		if (this.circleLineLayer) {
			// 清除当前图层的所有要素
			this.circleLineLayer.clearLayers();
			this.Lmap.removeLayer(this.circleLineLayer);
			this.circleLineLayer = null;
		}
		let lineData = {
			type: 'FeatureCollection',
			features: [],
		};
		// 根据线条数过滤点
		for (let index = 0; index < this.lineNum; index++) {
			let feature = {
				type: 'Feature',
				properties: {
					linenum: index,
					pdf: null,
					routeId: null,
				},
				geometry: {
					type: 'LineString',
					coordinates: [],
				},
			};
			let resultArray = data.features.filter((item) => {
				return item.properties.linenum === index;
			});
			if (resultArray && resultArray.length) {
				feature.properties.pdf = resultArray[0].properties?.pdf;
				feature.properties.routeId = resultArray[0].properties?.routeId;
				resultArray = resultArray.map((item) => {
					item.properties.Lat = item.geometry.coordinates[1];
					item.properties.Lon = item.geometry.coordinates[0];
					return item.properties;
				});
				this.get_wptlist_for_circle(resultArray);
				resultArray.forEach((e) => {
					feature.geometry.coordinates.push([e.Lon, e.Lat]);
				});
				lineData.features.push(feature);
			}
		}
		let myRenderer = L.canvas({ padding: 0.5 });
		this.circleLineLayer = L.geoJson(lineData, {
			style: lineStyle,
			renderer: myRenderer,
			onEachFeature: (feature, layer) => {
				var color;
				switch (feature.properties.linenum % 3) {
					case 0:
						color = '#00f'; // 蓝色
						break;
					case 1:
						color = '#00008b'; // 深蓝色
						break;
					case 2:
						color = '#add8e6'; // 浅蓝色
						break;
					default:
						color = '#000'; // 其他颜色为黑色
				}
				layer.setStyle({ color: color });
				layer.on('click', function (e) {
					// console.log('线图层被点击', feature);
					// 开启编辑
					setTimeout(() => {
						that.createEditLine(feature.properties.linenum, feature.properties?.pdf, feature.properties?.routeId);
					}, 50);
				});
			},
		}).addTo(this.Lmap);
		// this.circleLineLayer = L.GridLayer.vectorGrid.slicer(lineData, {
		// 	rendererFactory: L.canvas.tile,
		// 	vectorTileLayerStyles: {
		// 		sliced: function(properties, zoom) {
		// 			var color;
		// 			switch(properties.linenum % 3) {
		// 				case 0: color = '#00f'; break;
		// 				case 1: color = '#00008b'; break;
		// 				case 2: color = '#add8e6'; break;
		// 				default: color = '#000';
		// 			}
		// 			return {
		// 				stroke: true,
		// 				color: color,
		// 				weight: 3,
		// 				opacity: 1,
		// 				fill: false,
		// 				dashArray: '1, 3'
		// 			};
		// 		}
		// 	},
		// 	interactive: true,
		// 	getFeatureId: function(f) {
		// 		return f.properties.linenum;
		// 	},
		// });

		// this.circleLineLayer.on('click', function(e) {
		// 	// console.log('线图层被点击', e.layer.properties);
		// 	// 开启编辑
		// 	setTimeout(() => {
		// 		that.createEditLine(e.layer.properties.linenum, e.layer.properties?.pdf, e.layer.properties?.routeId);
		// 	}, 50);
		// }).addTo(this.Lmap);
		// this.Lmap.invalidateSize()
	},
	addPointLayer(data) {
		var that = this;
		if (this.pointLayer) {
			// 清除当前图层的所有要素
			this.pointLayer.clearLayers();
			this.pointLayer.addData(data);
		} else {
			let myRenderer = L.canvas({ padding: 0.5 });
			let geojsonMarkerOptions = {
				radius: 8,
				fillColor: '#000',
				color: 'blue',
				weight: 2,
				opacity: 1,
				renderer: myRenderer,
			};
			this.pointLayer = L.geoJson(data, {
				pointToLayer: function (feature, latlng) {
					// // 创建圆圈样式
					var circleMarker = L.circleMarker(latlng, geojsonMarkerOptions);
					// // 创建标注
					var label = L.marker(latlng, {
						icon: L.divIcon({
							className: 'leaflet-label' + feature.properties.wptNo,
							html: feature.properties.WPT_Name,
							iconSize: [50, 12],
							iconAnchor: [-10, 0], // 设置标注的偏移位置
						}),
					});
					// 将点和标注添加到同一图层
					// return L.layerGroup([circleMarker, label]);
					return L.circleMarker(latlng, geojsonMarkerOptions);
				},
				onEachFeature: (feature, layer) => {
					layer.on('click', function (e) {
						setTimeout(() => {
							that.createEditLine(feature.properties.linenum, feature.properties?.pdf, feature.properties?.routeId);
						}, 50);
					});
				},
				renderer: myRenderer,
			}).addTo(this.Lmap);
			// 初始化编辑图层
			let resultArray = this.pointData.features.filter((item) => {
				return item.properties.linenum === 0;
			});
			let lineData = this.pointToLine(resultArray, 0);
			// 添加编辑图层并移除
			this.editLineLayer = L.geoJson(lineData, {
				style: editlineStyle,
				renderer: myRenderer,
			}).addTo(this.Lmap);
			this.editLineLayer.clearLayers();
			// 遍历所有点，将指定点应用高亮样式
		}
	},
	// 创建一个大小能正好包含点的矩形框
	createRectangle(Lat, Lon) {
		let latlng = L.latLng(Lat, Lon);
		// 如果矩形已经存在于地图上 && 地图缩放事件
		if ((!Lat || !Lon) && this.rectangleLayer) {
			// var bounds = this.rectangleLayer.getBounds();
			// var swLatlng = bounds.getSouthWest();
			// var neLatlng = bounds.getNorthEast();
			// // 移除现有矩形
			// // 根据现有矩形的边界重新计算中心点
			latlng = this.rectangleLayer.latlng;
		}
		// 如果矩形已经不存在于地图上 && 地图缩放事件
		else if ((!Lat || !Lon) && !this.rectangleLayer) {
			return;
		}

		if (this.rectangleLayer) {
			this.Lmap.removeLayer(this.rectangleLayer.layer);
		}
		// 将中心点的经纬度转换为地图容器上的像素点
		let point = this.Lmap.latLngToContainerPoint(latlng);
		let radius = 8 + 3;
		// 根据半径计算矩形的四个角的像素坐标
		let swPoint = point.subtract([radius, radius]), // 西南角
			nePoint = point.add([radius, radius]); // 东北角

		// 将像素坐标转换回经纬度坐标
		let swLatlng = this.Lmap.containerPointToLatLng(swPoint),
			neLatlng = this.Lmap.containerPointToLatLng(nePoint);

		// 创建LatLngBounds
		let latlngBounds = L.latLngBounds(swLatlng, neLatlng);

		this.rectangleLayer = {
			layer: null,
			latlng: latlng,
		};
		// 创建虚线矩形
		this.rectangleLayer.layer = L.rectangle(latlngBounds, {
			color: 'red',
			weight: 2,
			dashArray: '3, 3',
			fill: false,
		});
		// 将矩形添加到地图
		this.rectangleLayer.layer.addTo(this.Lmap);
	},
	// 移除外部矩形
	removeRectangle() {
		if (this.rectangleLayer) {
			this.Lmap.removeLayer(this.rectangleLayer.layer);
		}
		this.rectangleLayer = null;
	},
	// 撤销此次编辑
	backEdit() {
		if (mapobj.editLineLayer && mapobj.editLineLayerStepList && mapobj.editLineLayerStepList.length) {
			let lastData = mapobj.editLineLayerStepList.pop(); // 移除最后一个元素
			console.log('🚀 ~ file: mapw.js:691 ~ backEdit ~ lastData:', lastData);
			this.addPointLayer(lastData);
			this.circleLineData(lastData);
		}
	},
	// 编辑事件监听
	monitorEvent() {
		// 线图层编辑监听
		this.editLineLayer.off('pm:edit');
		this.editLineLayer.on('pm:edit', (e) => {
			let data = e.target.toGeoJSON();
			let linenum = data.features[0].properties.linenum;
			let resultArray = { features: null };
			resultArray.features = this.pointData.features.filter((item) => {
				return item.properties.linenum === linenum;
			});
			// console.log(resultArray);
			if (data.features[0].geometry.coordinates.length > resultArray.features.length) {
				// 加点
				for (let index = 0; index < data.features[0].geometry.coordinates.length; index++) {
					const lData = data.features[0].geometry.coordinates[index];
					let isHave = false;
					for (let x = 0; x < resultArray.features.length; x++) {
						const pData = resultArray.features[x];
						if (
							pData.geometry.coordinates[0].toFixed(3) === lData[0].toFixed(3) &&
							pData.geometry.coordinates[1].toFixed(3) === lData[1].toFixed(3)
						) {
							isHave = true;
						}
					}
					if (!isHave) {
						let newData = {
							type: 'Feature',
							properties: {
								...resultArray.features[0].properties,
								id: index + 1,
								WPT_Name: 'W' + (index + 1).toString().padStart(3, '0'),
								LegType: 'RL',
							},
							geometry: {
								type: 'Point',
								coordinates: lData,
							},
						};
						resultArray.features.splice(index, 0, newData);
					}
					resultArray.features[index].properties.linenum = linenum;
					resultArray.features[index].properties.id = index + 1;
					resultArray.features[index].properties.WPT_Name = 'W' + (index + 1).toString().padStart(3, '0');
				}
				// console.log(resultArray);
			} else if (data.features[0].geometry.coordinates.length < resultArray.features.length) {
				// 创建一个新的数组用于存储不重复的值
				let result = [];
				// 遍历 a 数组中的每个元素
				for (let item of resultArray.features) {
					// 判断当前元素是否包含在 b 数组中
					let pointData = item.geometry.coordinates;
					let found = false;
					for (let subItem of data.features[0].geometry.coordinates) {
						// 不知道什么原因线精度和点精度有问题所以值判断小数点后三位
						if (
							subItem[0].toFixed(3) === pointData[0].toFixed(3) &&
							subItem[1].toFixed(3) === pointData[1].toFixed(3)
						) {
							found = true;
							break;
						}
					}
					if (found) {
						result.push(item);
					}
				}
				result.map((e, index) => {
					e.properties.id = index + 1;
					e.WPT_Name = 'W' + (index + 1).toString().padStart(3, '0');
					return e;
				});
				resultArray.features = result;
			} else {
				resultArray.features.map((e, index) => {
					e.geometry.coordinates = data.features[0].geometry.coordinates[index];
					return e;
				});
			}
			this.pointData.features = this.pointData.features.filter((item) => {
				return item.properties.linenum !== linenum;
			});
			resultArray.features.forEach((e) => {
				this.pointData.features.push(e);
			});
			this.editLineLayerStepList.push(this.pointData);
			// 根据线数据顺序生成id
			this.addPointLayer(this.pointData);
			this.circleLineData(this.pointData);
			if (eventListen.drawEdit && eventListen.drawEdit instanceof Function) {
				eventListen.drawEdit({
					linenum: linenum,
					routeId: resultArray.features[0].properties?.routeId,
				});
			}
		});
	},
	// 添加mapw各类事件监听
	addEventListern(type, fun) {
		eventListen[type] = fun;
	},
	// 点数据生成编辑线图层
	pointToLine(data, id) {
		let line = [];
		data.forEach((e) => {
			line.push(e.geometry.coordinates);
		});
		let lineData = {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					properties: {
						linenum: id,
					},
					geometry: {
						type: 'LineString',
						coordinates: line,
					},
				},
			],
		};
		return lineData;
	},
	// 生成大圆航线
	get_wptlist_for_circle(wptlist) {
		for (var i = 0; i < wptlist.length; i++) {
			wptlist[i]['isfixedeta'] = wptlist[i].isfixedeta ? wptlist[i].isfixedeta : false;
			//if (wptlist[i].LegType == 'GC' && i != wptlist.length - 1) {
			if (wptlist[i].LegType == 'GC' && i != 0) {
				this.getDivideSubLeglines(wptlist[i - 1].Lat, wptlist[i - 1].Lon, wptlist[i].Lat, wptlist[i].Lon, wptlist);
			}
		}
	},
	getDivideSubLeglines(dLat1, dLon1, dLat2, dLon2, lstInsertWps) {
		if (Math.abs(dLon2 - dLon1) < 2) {
			return;
		}
		if (Math.abs(dLon2 - dLon1) > 180) {
			return;
		}
		var lstInsertWps;
		var idInsert = 0;
		var newPoint = this.GreatCircleMidPoint(dLat1, dLon1, dLat2, dLon2);
		for (var i = 0; i < lstInsertWps.length; i++) {
			if (lstInsertWps[i].Lat == dLat1 && lstInsertWps[i].Lon == dLon1) {
				idInsert = i;
				break;
			}
		}
		lstInsertWps.splice(idInsert + 1, 0, newPoint);
		this.getDivideSubLeglines(dLat1, dLon1, newPoint.Lat, newPoint.Lon, lstInsertWps);
		this.getDivideSubLeglines(newPoint.Lat, newPoint.Lon, dLat2, dLon2, lstInsertWps);
	},
	GreatCircleMidPoint(lat1, lon1, lat2, lon2) {
		var RAD = Math.PI / 180;
		var dLon = lon2 - lon1;
		var Bx = Math.cos(lat2 * RAD) * Math.cos(dLon * RAD);
		var By = Math.cos(lat2 * RAD) * Math.sin(dLon * RAD);
		var latM =
			(180 / 3.14159) *
			Math.atan2(
				Math.sin(lat1 * RAD) + Math.sin(lat2 * RAD),
				Math.sqrt((Math.cos(lat1 * RAD) + Bx) * (Math.cos(lat1 * RAD) + Bx) + By * By),
			);
		var lonM = lon1 + (180 * Math.atan2(By, Math.cos(lat1 * RAD) + Bx)) / 3.14159;
		return {
			Lat: latM,
			Lon: lonM,
		};
	},
	// 后台返回数据转为geojson
	clean_route_geojson(routes, Features, edittingFeatures, bool) {
		if (routes && routes.length) {
			for (var i = 0; i < routes.length; i++) {
				var routeItem = routes[i];
				if (routeItem && routeItem.WPTList) {
					var coordinates = [];
					var coordinatesWptlist = [];
					var wptNo = 0;
					routeItem.WPTList.forEach((wpt) => {
						coordinatesWptlist.push([wpt.Lon, wpt.Lat]);
						if (wpt.WPT_Name) {
							var wptFeature = {
								type: 'Feature',
								properties: {
									routeId: routeItem.Summary?.RouteId, //以route中的为准
									// 'wptId': wpt.WPT_No,
									WPT_Name: wpt.WPT_Name,
									linenum: i,
									wptNo: wpt.WPT_No,
									active: 0,
									select: 0,
									lat: wpt.Lat,
									lon: wpt.Lon,
									isFixedETA: wpt.isFixedETA,
									...wpt,
									pdf: routeItem.Summary?.pdf,
								},
								geometry: {
									type: 'Point',
									coordinates: [wpt.Lon, wpt.Lat],
								},
							};
							wptNo++;
							if (routeItem.Summary?.isactive) {
								wptFeature.properties.active = 1;
							}
							if (routeItem.Summary?.isselect) {
								wptFeature.properties.select = 1;
							}
							edittingFeatures.push(wptFeature);
							Features.push(wptFeature);
						}
					});
					if (routeItem.lstep) {
						var index = _.findIndex(routeItem.lstep, function (obj) {
							return obj.wptype == 'now';
						});
						if (index > 0) {
							routeItem.lstep.splice(index, 1);
						}
						var nowDate = new Date(iView.map_timeline_bar_now().label);
						var dateNow = new Date(iView.map_timeline_bar_now().label).getTime();
						if (iView.map_timeline_bar_now().now) {
							for (var k = 0; k < routeItem.lstep.length; k++) {
								if (k >= routeItem.lstep.length - 1) {
									break;
								}
								if (
									dateNow >= new Date(routeItem.lstep[k].etd).getTime() &&
									dateNow < new Date(routeItem.lstep[k + 1].eta).getTime()
								) {
									var distance =
										routeItem.lstep[k].sog *
										((dateNow - new Date(routeItem.lstep[k].etd).getTime()) / (1000 * 60 * 60));
									var course = routeItem.lstep[k].course;
									var lat1 = routeItem.lstep[k].lat;
									var lon1 = routeItem.lstep[k].lon;
									var lonlat = CalculateNextWPByDisAndCourse(lat1, lon1, distance, course);
									var json = {};
									json.eta = iView.map_timeline_bar_now().label;
									json.etd = iView.map_timeline_bar_now().label;
									var compareLon = routeItem.lstep[k].lon;
									while (lonlat.lon - compareLon > 180) {
										lonlat.lon -= 360;
									}
									while (lonlat.lon - compareLon < -180) {
										lonlat.lon += 360;
									}
									json.lon = lonlat.lon;
									json.lat = lonlat.lat;
									json.course = routeItem.lstep[k].course;
									json.wptype = 'now';
									json.windspeed = routeItem.lstep[k].windspeed;
									json.winddir = routeItem.lstep[k].winddir;
									json.sigwaveheight = routeItem.lstep[k].sigwaveheight;
									routeItem.lstep.splice(k + 1, 0, json);
									break;
								}
							}
						}
						for (var j = 0; j < routeItem.lstep.length; j++) {
							var lst = routeItem.lstep[j];
							if (nowDate.getTime() >= new Date(lst.etd).getTime()) {
								coordinates.push([lst.lon, lst.lat]);
							}
							if (nowDate.getTime() <= new Date(lst.etd).getTime()) {
								if (j != routeItem.lstep.length - 1) {
									var coordinatesNew = [];
									coordinatesNew.push([lst.lon, lst.lat]);
									coordinatesNew.push([routeItem.lstep[j + 1].lon, routeItem.lstep[j + 1].lat]);

									var lineFeatureNew = {
										type: 'Feature',
										properties: {
											color: get_line_color_by_sigwaveheight(parseInt(lst.sigwaveheight)),
											'line-opacity': 0.4,
											routeId: routeItem && routeItem.route ? routeItem.route.id : 0,
											'line-width': 3,
											linenum: i,
											active: 0,
											'line-dasharray': 0,
										},
										geometry: {
											type: 'LineString',
											coordinates: coordinatesNew,
										},
									};
									if (routeItem.summary.isactive) {
										lineFeatureNew.properties['line-opacity'] = 1;
										lineFeatureNew.properties['line-width'] = !bool ? 10 : 6;
										lineFeatureNew.properties['active'] = 1;
									}
									if (routeItem.summary.isselect || bool) {
										lineFeatureNew.properties['line-opacity'] = 1;
										lineFeatureNew.properties['line-width'] = !bool ? 10 : 6;
									}
									Features.push(lineFeatureNew);
								}
							}
							if (nowDate.getTime() === new Date(lst.etd).getTime() || lst.wptype == 'now') {
								var icon = 'evownship';
								if (i == 1) {
									icon = 'goodais';
								} else if (i == 2) {
									icon = 'badais';
								}
								var iconFeature = {
									type: 'Feature',
									properties: {
										shipicon: 'aisserviced',
										course: lst.course,
									},
									geometry: {
										type: 'Point',
										coordinates: [lst.lon, lst.lat],
									},
								};
								Features.push(iconFeature);
							}
							var index = _.findIndex(routeItem.wptlist, function (obj) {
								return obj.lon == lst.lon && obj.lat == lst.lat;
							});
							if (index < 0 && lst.wptype != 'now') {
								var anchor = 'top-left';
								var anchor2 = 'bottom-right';
								var offsetDate = [2, 0];
								if ((lst.course <= 360 && lst.course >= 270) || (lst.course <= 180 && lst.course >= 90)) {
									anchor = 'top-right';
									anchor2 = 'bottom-left';
									offsetDate = [-2, 0];
								}
								var date = false;
								if (new Date(lst.eta).getHours() == 12) {
									date = new Date(lst.eta).getDate();
								}
								var wptFeature = {
									type: 'Feature',
									properties: {
										wind: date ? wind_direction_img(lst.windspeed) : -1,
										winddir: lst.winddir,
										windspeed: lst.windspeed ? lst.windspeed.toFixed(1) : 0,
										sigwaveheight: lst.sigwaveheight ? lst.sigwaveheight.toFixed(1) : 0,
										anchor: anchor,
										anchor2: anchor2,
										offsetDate: offsetDate,
										opacity: 0.4,
										date: date ? date : '',
									},
									geometry: {
										type: 'Point',
										coordinates: [lst.lon, lst.lat],
									},
								};
								if (routeItem.summary.isactive || routeItem.summary.isselect || bool) {
									wptFeature.properties['opacity'] = 1;
								}
								Features.push(wptFeature);
							}
						}
						// routeItem.lstep.forEach(lst => {

						// });
						var lineFeature = {
							type: 'Feature',
							properties: {
								color: '#4A4A4A',
								'line-opacity': 0.4,
								routeId: routeItem && routeItem.route ? routeItem.route.id : 0,
								'line-width': 3,
								linenum: i,
								active: 0,
								'line-dasharray': 0,
								pdf: routeItem?.pdf,
							},
							geometry: {
								type: 'LineString',
								coordinates: coordinates,
							},
						};

						if (routeItem.summary.isactive) {
							lineFeature.properties['line-opacity'] = 1;
							lineFeature.properties['line-width'] = 6;
							lineFeature.properties['active'] = 1;
						}
						if (routeItem.summary.isselect || bool) {
							lineFeature.properties['line-opacity'] = 0.5;
							lineFeature.properties['line-width'] = 6;
						}
						Features.push(lineFeature);
					} else {
						var lineFeature = {
							type: 'Feature',
							properties: {
								color: 'blue',
								'line-opacity': 0.5,
								routeId: routeItem && routeItem.route ? routeItem.route.id : 0,
								'line-width': 1.5,
								linenum: i,
								active: 0,
								'line-dasharray': 1,
								pdf: routeItem?.pdf,
							},
							geometry: {
								type: 'LineString',
								coordinates: coordinatesWptlist,
							},
						};
						if (routeItem.summary?.isactive || routeItem.summary?.isselect || bool) {
							lineFeature.properties['line-opacity'] = 1;
							lineFeature.properties['line-width'] = 2.5;
							if (routeItem.summary.isactive) {
								lineFeature.properties['active'] = 1;
							}
						}
						Features.push(lineFeature);
					}
				}
			}
		}
	},
	getPortInfo(name) {
		let depPort = portsList.find((val) => {
			return val.properties.port_code === name;
		});
		return depPort;
	},
	// 初始化高亮港口图层
	initPortsList(data) {
		portsList = data;
		// 高亮港口图层
		this.mapboxMap.addLayer({
			id: 'heightLightPorts',
			type: 'symbol',
			source: {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [],
				},
			},
			layout: {
				'text-size': 16,
				'icon-image': 'mainProts',
				'symbol-spacing': 10,
				'text-padding': 50,
				'text-offset': [1.5, 0],
				'icon-size': 1,
				'text-anchor': 'left',
				'text-field': ['get', 'port_name'],
				'icon-padding': 0,
				'text-max-width': 15,
				'icon-ignore-placement': true,
				'icon-allow-overlap': true,
				'text-ignore-placement': true,
				'text-allow-overlap': true,
			},
			paint: {
				'text-halo-color': 'rgb(103,192,58)',
				'text-halo-width': 5,
				'text-color': 'white',
			},
		});
	},
	// 清空高亮港口图层
	clearHeightLightPorts() {
		if (this.mapboxMap.getSource('heightLightPorts')) {
			this.mapboxMap.getSource('heightLightPorts').setData({
				type: 'FeatureCollection',
				features: [],
			});
		}
	},
	// 通过港口名称添加突出港口点（）
	heightLightByNameList(nameList) {
		if (this.mapboxMap.getLayer('heightLightPorts')) {
			let data = {
				type: 'FeatureCollection',
				features: [],
			};
			nameList.map((e, index) => {
				if (e.lon && e.lat) {
					let json = {
						type: 'Feature',
						properties: {
							port_name: index + 1 + '、' + e.portName,
						},
						geometry: {
							type: 'Point',
							coordinates: [e.lon, e.lat],
						},
					};
					data.features.push(json);
				} else {
					let port = portsList.find((val) => {
						return val.properties.port_code === e.name;
						//&& val.properties.port_name == e.portName
					});
					if (port) {
						let json = JSON.parse(JSON.stringify(port));
						json.properties.port_name = index + 1 + '、' + json.properties.port_name;
						data.features.push(json);
					}
				}
			});
			this.mapboxMap.getSource('heightLightPorts').setData(data);
		}
	},
	// 突出显示指定港口
	heightLightByList(nameList) {
		this.clearLayerPaintProperty();
		let haloColor = 'blue',
			haloWidth = 1,
			fieldColor = 'white',
			fieldSize = 16;
		let fieldColorExpression = ['match', ['get', 'port_code']];
		let fieldSizeExpression = ['match', ['get', 'port_code']];
		let haloWidthExpression = ['match', ['get', 'port_code']];
		let haloColorExpression = ['match', ['get', 'port_code']];
		nameList.forEach((e) => {
			// 字体颜色
			fieldColorExpression.push(e);
			fieldColorExpression.push(fieldColor);
			// 字体大小
			fieldSizeExpression.push(e);
			fieldSizeExpression.push(fieldSize);
			// 外阴影宽度
			haloWidthExpression.push(e);
			haloWidthExpression.push(haloWidth);
			// 外阴影颜色
			haloColorExpression.push(e);
			haloColorExpression.push(haloColor);
		});
		fieldColorExpression.push('black');
		haloColorExpression.push('black');
		haloWidthExpression.push(0);
		fieldSizeExpression.push(8);
		this.changeLayerPaintProperty('ports', { paint: 'text-color', expression: fieldColorExpression });
		this.changeLayerPaintProperty('ports', { paint: 'text-halo-width', expression: haloWidthExpression });
		this.changeLayerPaintProperty('ports', { paint: 'text-halo-color', expression: haloColorExpression });
	},
	// 清空
	clearLayerPaintProperty() {
		this.mapboxMap.setPaintProperty('ports', 'text-color', null);
		this.mapboxMap.setPaintProperty('ports', 'text-halo-width', null);
		this.mapboxMap.setPaintProperty('ports', 'text-halo-color', null);
	},
	// 定制化 修改mapbox图层样式
	changeLayerPaintProperty(layerID, { paint, expression }) {
		this.mapboxMap.setPaintProperty(layerID, paint, expression);
	},
	// // 定位
	// setView() {
	// 	if (this.pointData) {
	// 		const bound = turf.bbox(this.pointData);
	// 		this.Lmap.fitBounds([
	// 			[bound[1], bound[0]],
	// 			[bound[3], bound[2]]
	// 		]);
	// 		console.log(bound);
	// 	}
	// },
	// 定位
	setView() {
		if (this.pointData) {
			const bound = turf.bbox(this.pointData);
			const square = turf.square(bound);
			var poly = turf.bboxPolygon(square);
			let data = generateRectangleGeoJSON(poly);
			function generateRectangleGeoJSON(geojson) {
				// 获取边界框左下角和右上角的坐标
				const bbox = geojson.bbox;
				const bottomLeft = { longitude: bbox[0], latitude: bbox[1] };
				const topRight = { longitude: bbox[2], latitude: bbox[3] };

				// 计算正方形的中心点坐标
				const centerLongitude = (bottomLeft.longitude + topRight.longitude) / 2;
				const centerLatitude = (bottomLeft.latitude + topRight.latitude) / 2;

				// 计算正方形的边长
				const width = topRight.longitude - bottomLeft.longitude;
				const height = topRight.latitude - bottomLeft.latitude;
				const sideLength = Math.max(width, height);

				// 计算矩形的宽度和高度
				const targetAspectRatio = 9 / 16;
				let rectangleWidth = sideLength / targetAspectRatio;
				let rectangleHeight = sideLength;

				// 计算矩形的经纬度范围
				const rectBottomLeftLongitude = centerLongitude - rectangleWidth / 2;
				const rectBottomLeftLatitude = centerLatitude - rectangleHeight / 2;
				const rectTopRightLongitude = centerLongitude + rectangleWidth / 2;
				const rectTopRightLatitude = centerLatitude + rectangleHeight / 2;

				// 生成矩形的 GeoJSON 数据
				const rectangleGeoJSON = {
					type: 'Feature',
					geometry: {
						type: 'Polygon',
						coordinates: [
							[
								[rectBottomLeftLongitude, rectBottomLeftLatitude],
								[rectTopRightLongitude, rectBottomLeftLatitude],
								[rectTopRightLongitude, rectTopRightLatitude],
								[rectBottomLeftLongitude, rectTopRightLatitude],
								[rectBottomLeftLongitude, rectBottomLeftLatitude],
							],
						],
					},
				};

				return rectangleGeoJSON;
			}
			let buffered = turf.buffer(data, 300, { units: 'miles' });
			const bufferBound = turf.bbox(buffered);
			this.Lmap.fitBounds([
				[bufferBound[1], bufferBound[0]],
				[bufferBound[3], bufferBound[2]],
			]);
			// 创建一个矩形图层
			setTimeout((e) => {
				this.createMapImage(bufferBound);
			}, 4000);
		}
	},
	createMapImage(bufferBound) {
		let bounds = this.Lmap.getBounds(),
			zero = [bounds._northEast.lat, bounds._southWest.lng],
			// 计算当前 视窗内的 原点经纬度 ==> 对应的屏幕坐标 （地图位移及缩放时计算 startPoint的偏移量）必须！！！
			zeroPoint = this.Lmap.latLngToLayerPoint(zero);
		let startPoint = this.Lmap.latLngToLayerPoint({ lat: bufferBound[3], lng: bufferBound[0] }), // latlng 转 屏幕坐标 计算 起点及宽高
			endPoint = this.Lmap.latLngToLayerPoint({ lat: bufferBound[1], lng: bufferBound[2] }),
			width = Math.abs(startPoint.x - endPoint.x),
			height = Math.abs(startPoint.y - endPoint.y);
		const mapid = document.getElementById('windy');
		const _width = mapid['offsetWidth'];
		const _height = mapid['offsetHeight'];
		var options = { width: _width, height: _height };
		// html2canvas配置项
		const ops = {
			scale: 1,
			useCORS: true,
			allowTaint: false,
		};
		html2canvas(document.getElementById('windy'), ops).then(async (canvas) => {
			this.downloadIamge(canvas, startPoint.x - zeroPoint.x, startPoint.y - zeroPoint.y, width, height);
		});
	},
	downloadIamge(canvas, capture_x, capture_y, capture_width, capture_height) {
		// 创建一个用于截取的canvas
		var clipCanvas = document.createElement('canvas');
		clipCanvas.width = capture_width;
		clipCanvas.height = capture_height;
		// 截取图片
		clipCanvas
			.getContext('2d')
			.drawImage(canvas, capture_x, capture_y, capture_width, capture_height, 0, 0, capture_width, capture_height);
		let pdfImage = clipCanvas.toDataURL('image/png');
		if (eventListen.exportImage && eventListen.exportImage instanceof Function) {
			eventListen.exportImage({
				image: pdfImage,
			});
			this.initCharsLayer();
		}
	},
	// 截图转为base64
	exportImage() {
		this.initCharsLayer(false);

		this.setView();
		// 截图
		// setTimeout(() => {
		// 	const mapid = document.getElementById('windy');
		// 	const _width = mapid["offsetWidth"];
		// 	const _height = mapid["offsetHeight"];
		// 	var options = { width: _width, height: _height };
		// 	// html2canvas配置项
		// 	const ops = {
		// 		scale: 1,
		// 		width: _width,
		// 		height: _height,
		// 		useCORS: true,
		// 		allowTaint: false,
		// 		...options,
		// 	};
		// 	html2canvas(mapid, ops).then(async (canvas) => {
		// 		// 导出jpg
		// 		//转换base64
		// 		let pdfImage = canvas.toDataURL('image/png');
		// 		if (eventListen.exportImage && eventListen.exportImage instanceof Function) {
		// 			eventListen.exportImage({
		// 				image: pdfImage
		// 			});
		// 			this.initCharsLayer();
		// 		}
		// 	})
		// }, 4000);
		// html2canvas(document.getElementById('windy'),{allowTaint: true,useCORS: true}).then(canvas => {
		//     let dataUrl = this.getImageWithText(canvas, "SYWater-2020©");
		//     this.downloadImage("sywater-gis",dataUrl)
		// });
	},
	// 日报经纬度上图
	addPointByTable(data) {
		data.forEach((e) => {
			let lat = this.ChangeToDu(e.lat.replace('′', ''));
			let lon = this.ChangeToDu(e.lon.replace('′', ''));
			if (lon && lat) {
				let latlng = L.latLng(lat, lon);
				// 创建自定义图标
				let customIcon = L.divIcon({
					className: 'custom-icon',
					iconAnchor: [12, 12],
					iconSize: [16, 16],
					background: 'red',
					html: `<div class='custom-content'>
							<img src="${require('@/assets/img/report.png')}" class="custom-icon">
							<div class="custom-text">${e.typeSon[0] + '   '}${e.dateUtc}</div></div>`,
				});
				L.marker(latlng, { icon: customIcon }).addTo(this.reportLayer);
			}
		});
		data.forEach((e) => {
			let lat = this.ChangeToDu(e.lat.replace('′', ''));
			let lon = this.ChangeToDu(e.lon.replace('′', ''));
			lon = parseFloat(lon) + 360;
			if (lon && lat) {
				let latlng = L.latLng(lat, lon);
				// 创建自定义图标
				let customIcon = L.divIcon({
					className: 'custom-icon',
					iconAnchor: [12, 12],
					iconSize: [16, 16],
					background: 'red',
					html: `<div class='custom-content'>
							<img src="${require('@/assets/img/report.png')}" class="custom-icon">
							<div class="custom-text">${e.typeSon[0] + '   '}${e.dateUtc}</div></div>`,
				});
				L.marker(latlng, { icon: customIcon }).addTo(this.reportLayer);
			}
		});
	},
	/**
	 * @description 度分秒转换经纬度
	 *
	 * @param {String} _DFM
	 */
	ChangeToDu(_DFM) {
		try {
			_DFM = _DFM.replace('′', '');
			var t = _DFM.split('°');
			var d = t[0];
			var f = t[1].substring(0, t[1].length - 1);
			var m = _DFM[_DFM.length - 1];
			var du = parseFloat(f / 60) + parseFloat(d);
			if (m == 'W' || m == 'S') {
				du = du * -1;
			}
		} catch (error) {
			return false;
		}
		//var f = parseFloat(f) + parseFloat(m / 60);
		return Number(du);
	},
	/**
	 * @description 经纬度转换度分秒
	 *
	 * @param {string} value // 数值
	 * @param {string} isXY // 经纬度lon 1，lat 0
	 * @returns
	 */
	ChangeToDFM(value, isXY) {
		value = this.convertTo180(value);
		var absvalue = Math.abs(value);
		var v1 = Math.floor(absvalue); //度
		var v2 = (parseInt((absvalue - v1) * 60 * 10000, 10) / 10000).toFixed(3); //分
		// var v3 = parseInt((value - v1) * 3600 % 60 * 10000, 10) / 10000;//秒
		if (v2 < 10) {
			v2 = '0' + v2;
		}
		var txt = '';
		if (!isXY) {
			isXY = 0;
		}
		if (isXY == 1 || isXY.toString().toLowerCase() == 'x') {
			if (value < 0) {
				txt = 'W';
			} else {
				txt = 'E';
			}
			if (v1 < 10) {
				v1 = '00' + v1;
			} else if (v1 < 100) {
				v1 = '0' + v1;
			}
		} else {
			if (value > 0) {
				txt = 'N';
			} else {
				txt = 'S';
			}
		}
		v2 = v2 < 0 ? -v2 : v2;
		return v1 + '°' + v2 /* + v3 + '" ' */ + txt;
	},
	convertTo360(alfa) {
		var beta = alfa % 360;
		if (beta < 0) {
			beta += 360;
		}
		return beta;
	},
	convertTo180(alfa) {
		if (alfa == 0) {
			return 0;
		}
		var beta = alfa / 180;
		if (beta % 2 == 0) {
			return 0;
		}
		beta = parseInt(beta, 10);
		if (beta < 1 && beta > -1) {
			return alfa;
		}
		if ((beta % 2 >= 1 && beta % 2 < 2) || (beta % 2 <= -1 && beta % 2 > -2)) {
			beta = 180 * (alfa < 0 ? 1 : -1) + (alfa - 180 * beta);
		} else {
			beta = alfa - 180 * beta;
		}
		return beta;
	},
	async init(type) {
		mapboxgl.accessToken =
			'pk.eyJ1Ijoid3VqaW5odWkwIiwiYSI6ImNrcmVjYnp0ejIwa20ybmxxaWVzd3B5Z3oifQ.sRrLnZMlTjphnTedmphKLg'; // 这里请换成自己的token
		// eslint-disable-next-line no-unused-vars
		this.map = new mapboxgl.Map({
			container: 'map', // container id 绑定的组件的id
			style: 'mapbox://styles/mapbox/light-v11', // 地图样式，可以使用官网预定义的样式,也可以自定义
			center: [0, 10], // 初始地图中心点位置
			zoom: 10, // starting zoom 地图初始的层级
			pitch: 0, // 地图的角度，不写默认是0，取值是0-60度，一般在3D中使用
			bearing: -17.6, // 地图的初始方向，值是北的逆时针度数，默认是0，即是正北
			//antialias: true, // 抗锯齿，通过false关闭提升性能
		});
	},
	initWindw() {
		let Windy = function Windy(params) {
			let MIN_VELOCITY_INTENSITY = params.minVelocity || 0; // velocity at which particle intensity is minimum (m/s)

			let MAX_VELOCITY_INTENSITY = params.maxVelocity || 10; // velocity at which particle intensity is maximum (m/s)

			let VELOCITY_SCALE = (params.velocityScale || 0.005) * (Math.pow(window.devicePixelRatio, 1 / 3) || 1); // scale for wind velocity (completely arbitrary--this value looks nice)

			let MAX_PARTICLE_AGE = params.particleAge || 90; // max number of frames a particle is drawn before regeneration

			let PARTICLE_LINE_WIDTH = params.lineWidth || 1; // line width of a drawn particle

			let PARTICLE_MULTIPLIER = params.particleMultiplier || 1 / 300; // particle count scalar (completely arbitrary--this values looks nice)

			let PARTICLE_REDUCTION = Math.pow(window.devicePixelRatio, 1 / 3) || 1.6; // multiply particle count for mobiles by this amount

			let FRAME_RATE = params.frameRate || 15;
			let FRAME_TIME = 1000 / FRAME_RATE; // desired frames per second

			let OPACITY = 0.97;
			let defaulColorScale = [
				'rgb(36,104, 180)',
				'rgb(60,157, 194)',
				'rgb(128,205,193 )',
				'rgb(151,218,168 )',
				'rgb(198,231,181)',
				'rgb(238,247,217)',
				'rgb(255,238,159)',
				'rgb(252,217,125)',
				'rgb(255,182,100)',
				'rgb(252,150,75)',
				'rgb(250,112,52)',
				'rgb(245,64,32)',
				'rgb(237,45,28)',
				'rgb(220,24,32)',
				'rgb(180,0,35)',
			];
			let colorScale = params.colorScale || defaulColorScale;
			let NULL_WIND_VECTOR = [NaN, NaN, null]; // singleton for no wind in the form: [u, v, magnitude]

			let builder;
			let grid;
			let gridData = params.data;
			let date;
			let λ0;
			let φ0;
			let Δλ;
			let Δφ;
			let ni;
			let nj;

			let setData = function setData(data) {
				gridData = data;
			};

			let setOptions = function setOptions(options) {
				if (options.hasOwnProperty('minVelocity')) MIN_VELOCITY_INTENSITY = options.minVelocity;
				if (options.hasOwnProperty('maxVelocity')) MAX_VELOCITY_INTENSITY = options.maxVelocity;
				if (options.hasOwnProperty('velocityScale'))
					VELOCITY_SCALE = (options.velocityScale || 0.005) * (Math.pow(window.devicePixelRatio, 1 / 3) || 1);
				if (options.hasOwnProperty('particleAge')) MAX_PARTICLE_AGE = options.particleAge;
				if (options.hasOwnProperty('lineWidth')) PARTICLE_LINE_WIDTH = options.lineWidth;
				if (options.hasOwnProperty('particleMultiplier')) PARTICLE_MULTIPLIER = options.particleMultiplier;
				// eslint-disable-next-line no-implicit-coercion
				if (options.hasOwnProperty('opacity')) OPACITY = +options.opacity;
				if (options.hasOwnProperty('frameRate')) FRAME_RATE = options.frameRate;
				FRAME_TIME = 1000 / FRAME_RATE;
			}; // interpolation for vectors like wind (u,v,m)

			// eslint-disable-next-line max-params
			let bilinearInterpolateVector = function bilinearInterpolateVector(x, y, g00, g10, g01, g11) {
				let rx = 1 - x;
				let ry = 1 - y;
				let a = rx * ry;
				let b = x * ry;
				let c = rx * y;
				let d = x * y;
				let u = g00[0] * a + g10[0] * b + g01[0] * c + g11[0] * d;
				let v = g00[1] * a + g10[1] * b + g01[1] * c + g11[1] * d;
				return [u, v, Math.sqrt(u * u + v * v)];
			};

			let createWindBuilder = function createWindBuilder(uComp, vComp) {
				let uData = uComp.data;
				let vData = vComp.data;
				return {
					header: uComp.header,
					// recipe: recipeFor("wind-" + uComp.header.surface1Value),
					data: function data(i) {
						return [uData[i], vData[i]];
					},
					interpolate: bilinearInterpolateVector,
				};
			};

			let createBuilder = function createBuilder(data) {
				let uComp = null;
				let vComp = null;
				let scalar = null;
				data.forEach(function (record) {
					switch (record.header.parameterCategory + ',' + record.header.parameterNumber) {
						case '1,2':
						case '2,2':
							uComp = record;
							break;

						case '1,3':
						case '2,3':
							vComp = record;
							break;

						default:
							scalar = record;
					}
				});
				return createWindBuilder(uComp, vComp);
			};

			let buildGrid = function buildGrid(data, callback) {
				let supported = true;
				if (data.length < 2) supported = false;
				if (!supported) console.log('Windy Error: data must have at least two components (u,v)');
				builder = createBuilder(data);
				let header = builder.header;
				if (header.hasOwnProperty('gridDefinitionTemplate') && header.gridDefinitionTemplate != 0) supported = false;

				if (!supported) {
					console.log('Windy Error: Only data with Latitude_Longitude coordinates is supported');
				}

				supported = true; // reset for futher checks

				λ0 = header.lo1;
				φ0 = header.la1; // the grid's origin (e.g., 0.0E, 90.0N)

				Δλ = header.dx;
				Δφ = header.dy; // distance between grid points (e.g., 2.5 deg lon, 2.5 deg lat)

				ni = header.nx;
				nj = header.ny; // number of grid points W-E and N-S (e.g., 144 x 73)

				if (header.hasOwnProperty('scanMode')) {
					let scanModeMask = header.scanMode.toString(2);
					scanModeMask = ('0' + scanModeMask).slice(-8);
					let scanModeMaskArray = scanModeMask.split('').map(Number).map(Boolean);
					if (scanModeMaskArray[0]) Δλ = -Δλ;
					if (scanModeMaskArray[1]) Δφ = -Δφ;
					if (scanModeMaskArray[2]) supported = false;
					if (scanModeMaskArray[3]) supported = false;
					if (scanModeMaskArray[4]) supported = false;
					if (scanModeMaskArray[5]) supported = false;
					if (scanModeMaskArray[6]) supported = false;
					if (scanModeMaskArray[7]) supported = false;
					if (!supported) console.log('Windy Error: Data with scanMode: ' + header.scanMode + ' is not supported.');
				}

				date = new Date(header.refTime);
				date.setHours(date.getHours() + header.forecastTime); // Scan modes 0, 64 allowed.
				// http://www.nco.ncep.noaa.gov/pmb/docs/grib2/grib2_table3-4.shtml

				grid = [];
				let p = 0;
				let isContinuous = Math.floor(ni * Δλ) >= 360;

				for (let j = 0; j < nj; j++) {
					let row = [];

					for (let i = 0; i < ni; i++, p++) {
						row[i] = builder.data(p);
					}

					if (isContinuous) {
						// For wrapped grids, duplicate first column as last column to simplify interpolation logic
						row.push(row[0]);
					}

					grid[j] = row;
				}

				callback({
					date: date,
					interpolate: interpolate,
				});
			};
			/**
			 * Get interpolated grid value from Lon/Lat position
			 * @param λ {Float} Longitude
			 * @param φ {Float} Latitude
			 * @returns {Object}
			 */

			// eslint-disable-next-line no-var
			let interpolate = function interpolate(λ, φ) {
				if (!grid) return null;
				let i = floorMod(λ - λ0, 360) / Δλ; // calculate longitude index in wrapped range [0, 360)

				let j = (φ0 - φ) / Δφ; // calculate latitude index in direction +90 to -90

				let fi = Math.floor(i);
				let ci = fi + 1;
				let fj = Math.floor(j);
				let cj = fj + 1;
				let row;

				if ((row = grid[fj])) {
					let g00 = row[fi];
					let g10 = row[ci];

					if (isValue(g00) && isValue(g10) && (row = grid[cj])) {
						let g01 = row[fi];
						let g11 = row[ci];

						if (isValue(g01) && isValue(g11)) {
							// All four points found, so interpolate the value.
							return builder.interpolate(i - fi, j - fj, g00, g10, g01, g11);
						}
					}
				}

				return null;
			};
			/**
			 * @returns {Boolean} true if the specified value is not null and not undefined.
			 */

			let isValue = function isValue(x) {
				return x !== null && x !== undefined;
			};
			/**
			 * @returns {Number} returns remainder of floored division, i.e., floor(a / n). Useful for consistent modulo
			 *          of negative numbers. See http://en.wikipedia.org/wiki/Modulo_operation.
			 */

			let floorMod = function floorMod(a, n) {
				return a - n * Math.floor(a / n);
			};
			/**
			 * @returns {Number} the value x clamped to the range [low, high].
			 */

			let clamp = function clamp(x, range) {
				return Math.max(range[0], Math.min(x, range[1]));
			};
			/**
			 * @returns {Boolean} true if agent is probably a mobile device. Don't really care if this is accurate.
			 */

			let isMobile = function isMobile() {
				return /android|blackberry|iemobile|ipad|iphone|ipod|opera mini|webos/i.test(navigator.userAgent);
			};
			/**
			 * Calculate distortion of the wind vector caused by the shape of the projection at point (x, y). The wind
			 * vector is modified in place and returned by this function.
			 */

			let distort = function distort(projection, λ, φ, x, y, scale, wind) {
				let u = wind[0] * scale;
				let v = wind[1] * scale;
				let d = distortion(projection, λ, φ, x, y); // Scale distortion vectors by u and v, then add.

				wind[0] = d[0] * u + d[2] * v;
				wind[1] = d[1] * u + d[3] * v;
				return wind;
			};

			var distortion = function distortion(projection, λ, φ, x, y) {
				let τ = 2 * Math.PI; //    var H = Math.pow(10, -5.2); // 0.00000630957344480193
				//    var H = 0.0000360;          // 0.0000360°φ ~= 4m  (from https://github.com/cambecc/earth/blob/master/public/libs/earth/1.0.0/micro.js#L13)

				let H = 5; // ToDo:   Why does this work?

				let hλ = λ < 0 ? H : -H;
				let hφ = φ < 0 ? H : -H;
				let pλ = project(φ, λ + hλ);
				let pφ = project(φ + hφ, λ); // Meridian scale factor (see Snyder, equation 4-3), where R = 1. This handles issue where length of 1º λ
				// changes depending on φ. Without this, there is a pinching effect at the poles.

				let k = Math.cos((φ / 360) * τ);
				return [(pλ[0] - x) / hλ / k, (pλ[1] - y) / hλ / k, (pφ[0] - x) / hφ, (pφ[1] - y) / hφ];
			};

			let createField = function createField(columns, bounds, callback) {
				/**
				 * @returns {Array} wind vector [u, v, magnitude] at the point (x, y), or [NaN, NaN, null] if wind
				 *          is undefined at that point.
				 */
				function field(x, y) {
					let column = columns[Math.round(x)];
					return (column && column[Math.round(y)]) || NULL_WIND_VECTOR;
				} // Frees the massive "columns" array for GC. Without this, the array is leaked (in Chrome) each time a new
				// field is interpolated because the field closure's context is leaked, for reasons that defy explanation.

				field.release = () => {
					columns = [];
				};

				field.randomize = function (o) {
					// UNDONE: this method is terrible
					let x;
					let y;
					let safetyNet = 0;

					do {
						x = Math.round(Math.floor(Math.random() * bounds.width) + bounds.x);
						y = Math.round(Math.floor(Math.random() * bounds.height) + bounds.y);
					} while (field(x, y)[2] === null && safetyNet++ < 30);

					o.x = x;
					o.y = y;
					return o;
				};

				callback(bounds, field);
			};

			let buildBounds = function buildBounds(bounds, width, height) {
				let upperLeft = bounds[0];
				let lowerRight = bounds[1];
				let x = Math.round(upperLeft[0]); //Math.max(Math.floor(upperLeft[0], 0), 0);

				let y = Math.max(Math.floor(upperLeft[1], 0), 0);
				let xMax = Math.min(Math.ceil(lowerRight[0], width), width - 1);
				let yMax = Math.min(Math.ceil(lowerRight[1], height), height - 1);
				return {
					x: x,
					y: y,
					xMax: width,
					yMax: yMax,
					width: width,
					height: height,
				};
			};

			let deg2rad = function deg2rad(deg) {
				return (deg / 180) * Math.PI;
			};

			let invert = function invert(x, y, windy) {
				let latlon = params.map.unproject([x, y]);
				return [latlon.lng, latlon.lat];
			};

			var project = function project(lat, lon, windy) {
				let xy = params.map.project([lon, lat]);
				return [xy.x, xy.y];
			};

			let interpolateField = function interpolateField(grid, bounds, extent, callback) {
				let projection = {}; // map.crs used instead

				let mapArea = (extent.south - extent.north) * (extent.west - extent.east);
				let velocityScale = VELOCITY_SCALE * Math.pow(mapArea, 0.4);
				let columns = [];
				let x = bounds.x;

				function interpolateColumn(x) {
					let column = [];

					for (let y = bounds.y; y <= bounds.yMax; y += 2) {
						let coord = invert(x, y);

						if (coord) {
							let λ = coord[0];
							let φ = coord[1];

							if (isFinite(λ)) {
								let wind = grid.interpolate(λ, φ);

								if (wind) {
									wind = distort(projection, λ, φ, x, y, velocityScale, wind);
									column[y + 1] = column[y] = wind;
								}
							}
						}
					}

					columns[x + 1] = columns[x] = column;
				}

				(function batchInterpolate() {
					let start = Date.now();

					while (x < bounds.width) {
						interpolateColumn(x);
						x += 2;

						if (Date.now() - start > 1000) {
							//MAX_TASK_TIME) {
							setTimeout(batchInterpolate, 25);
							return;
						}
					}

					createField(columns, bounds, callback);
				})();
			};

			let animationLoop;

			let animate = function animate(bounds, field) {
				function windIntensityColorScale(min, max) {
					colorScale.indexFor = function (m) {
						// map velocity speed to a style
						return Math.max(
							0,
							Math.min(colorScale.length - 1, Math.round(((m - min) / (max - min)) * (colorScale.length - 1))),
						);
					};

					return colorScale;
				}

				let colorStyles = windIntensityColorScale(MIN_VELOCITY_INTENSITY, MAX_VELOCITY_INTENSITY);
				let buckets = colorStyles.map(function () {
					return [];
				});
				let particleCount = Math.round(bounds.width * bounds.height * PARTICLE_MULTIPLIER);

				if (isMobile()) {
					particleCount *= PARTICLE_REDUCTION;
				}

				let fadeFillStyle = 'rgba(0, 0, 0, '.concat(OPACITY, ')');
				let particles = [];

				for (let i = 0; i < particleCount; i++) {
					particles.push(
						field.randomize({
							age: Math.floor(Math.random() * MAX_PARTICLE_AGE) + 0,
						}),
					);
				}

				function evolve() {
					buckets.forEach(function (bucket) {
						bucket.length = 0;
					});
					particles.forEach(function (particle) {
						if (particle.age > MAX_PARTICLE_AGE) {
							field.randomize(particle).age = 0;
						}

						let x = particle.x;
						let y = particle.y;
						let v = field(x, y); // vector at current position

						let m = v[2];

						if (m === null) {
							particle.age = MAX_PARTICLE_AGE; // particle has escaped the grid, never to return...
						} else {
							let xt = x + v[0];
							let yt = y + v[1];

							if (field(xt, yt)[2] !== null) {
								// Path from (x,y) to (xt,yt) is visible, so add this particle to the appropriate draw bucket.
								particle.xt = xt;
								particle.yt = yt;
								buckets[colorStyles.indexFor(m)].push(particle);
							} else {
								// Particle isn't visible, but it still moves through the field.
								particle.x = xt;
								particle.y = yt;
							}
						}

						particle.age += 1;
					});
				}

				let g = params.canvas.getContext('2d');
				g.lineWidth = PARTICLE_LINE_WIDTH;
				g.fillStyle = fadeFillStyle;
				g.globalAlpha = 0.6;

				function draw() {
					// Fade existing particle trails.
					let prev = 'lighter';
					g.globalCompositeOperation = 'destination-in';
					g.fillRect(bounds.x, bounds.y, bounds.width, bounds.height);
					g.globalCompositeOperation = prev;
					g.globalAlpha = OPACITY === 0 ? 0 : OPACITY * 0.9; // Draw new particle trails.

					buckets.forEach(function (bucket, i) {
						if (bucket.length > 0) {
							g.beginPath();
							g.strokeStyle = colorStyles[i];
							bucket.forEach(function (particle) {
								g.moveTo(particle.x, particle.y);
								g.lineTo(particle.xt, particle.yt);
								particle.x = particle.xt;
								particle.y = particle.yt;
							});
							g.stroke();
						}
					});
				}

				let then = Date.now();

				(function frame() {
					animationLoop = requestAnimationFrame(frame);
					let now = Date.now();
					let delta = now - then;

					if (delta > FRAME_TIME) {
						then = now - (delta % FRAME_TIME);
						evolve();
						draw();
					}
				})();
			};

			let start = function start(bounds, width, height, extent) {
				let mapBounds = {
					south: deg2rad(extent[0][1]),
					north: deg2rad(extent[1][1]),
					east: deg2rad(extent[1][0]),
					west: deg2rad(extent[0][0]),
					width: width,
					height: height,
				};
				stop(); // build grid

				buildGrid(gridData, function (grid) {
					// interpolateField
					interpolateField(grid, buildBounds(bounds, width, height), mapBounds, function (bounds, field) {
						// animate the canvas with random points
						windy.field = field;
						animate(bounds, field);
					});
				});
			};

			var stop = function stop() {
				if (windy.field) windy.field.release();
				if (animationLoop) cancelAnimationFrame(animationLoop);
			};

			var windy = {
				params: params,
				start: start,
				stop: stop,
				createField: createField,
				interpolatePoint: interpolate,
				setData: setData,
				setOptions: setOptions,
			};
			return windy;
		};

		if (!window.cancelAnimationFrame) {
			window.cancelAnimationFrame = function (id) {
				clearTimeout(id);
			};
		}
		let windyMap = {
			windy: null,
			map: null,
			visible: true,
			context: null,
			timer: 0,
			initWindy(data, map) {
				const self = this;
				self.visible = true;
				self.map = map;

				// 删除dom
				self.hideWind();

				let canvas = document.createElement('canvas');
				canvas.id = 'windCanvas';
				canvas.width = map.getCanvas().width;
				canvas.height = map.getCanvas().height;
				canvas.style.position = 'absolute';
				canvas.style.top = 0;
				canvas.style.left = 0;
				map.getCanvasContainer().appendChild(canvas);
				this.context = canvas.getContext('2d');

				self.windy = new Windy({
					canvas: canvas,
					data: data,
					map: map,
				});

				if (self.timer) clearTimeout(self.timer);
				this.timer = setTimeout(function () {
					self._refreshWindy();
				}, 750);
				map.on('rotate', function (event) {
					if (self.context) self.context.clearRect(0, 0, 3000, 3000);
					self.windy.stop();
				});
				map.on('dragstart', function () {
					console.log('拖拽.........');
					if (self.context) self.context.clearRect(0, 0, 3000, 3000);
					self.windy.stop();
				});
				map.on('contextmenu', function (e) {
					self._refreshWindy();
				});
				map.on('dragend', function () {
					self._refreshWindy();
				});

				map.on('zoomstart', function () {
					if (self.context) self.context.clearRect(0, 0, 3000, 3000);
					self.windy.stop();
				});

				map.on('zoomend', function () {
					self._refreshWindy();
				});

				map.on('resize', function () {
					self.clearWind();
				});
			},
			_refreshWindy: function () {
				const self = this;
				const _canvas = self.windy.params.canvas;
				if (!self.windy) return;
				let bounds = self.map.getBounds();
				let extent = [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat];

				_canvas.width = self.map.getCanvas().width;
				_canvas.height = self.map.getCanvas().height;

				self.windy.start(
					[
						[0, 0],
						[_canvas.width, _canvas.height],
					],
					_canvas.width,
					_canvas.height,
					[
						[extent[0], extent[1]],
						[extent[2], extent[3]],
					],
				);
			},

			hideWind: function () {
				if (this.context) this.context.clearRect(0, 0, 3000, 3000);
				let dom = document.getElementById('windCanvas');
				if (dom) dom.parentNode.removeChild(dom);
			},

			clearWind: function () {
				if (this.windy) this.windy.stop();
				if (this.context) this.context.clearRect(0, 0, 3000, 3000);
			},

			setVisible: function (flag) {
				const self = this;
				self.visible = flag;
				let dom = document.getElementById('windCanvas');
				if (!dom) return;
				if (flag) {
					dom.style.display = 'block';
					self._refreshWindy();
				} else {
					if (self.windy) self.windy.stop();
					dom.style.display = 'none';
				}
			},
		};
		axios.get(`https://datacenter.istrongcloud.com/data/gfs/time_fc.json`).then((res) => {
			axios.get(`https://datacenter.istrongcloud.com/data/gfs/fcdata/${res.data.data['024'].url}`).then((windRes) => {
				console.log('windRes: ', Object.values(windRes.data)[0]);
				windyMap.initWindy(Object.values(windRes.data)[0], this.map);
			});
		});
		//  => {
		//     console.log(res);
		//     console.log(`https://datacenter.istrongcloud.com/data/gfs/fcdata/${res.data.data['024'].url}`);
		//     axios.get(`https://datacenter.istrongcloud.com/data/gfs/fcdata/${res.data.data['024'].url}`).then(windRes => {
		//         console.log('windRes: ', windRes.data);
		//         windyMap.initWindy(Object.values(windRes.data)[0], this.map);
		//     });
		// })
	},
	initCharsLayer(bool = true) {
		var bgTiles;
		bgTiles = ['W']; //默认全部
		var bgZooms = [
			{ zoom: 0, scale: [0] },
			{ zoom: 3, scale: [1e7] },
			{ zoom: 4, scale: [4860700] },
			{ zoom: 5, scale: [35e5, 3121170, 3e6] },
			{ zoom: 6, scale: [216e4, 2e6, 15e5, 1444e3, 12e5] },
			{ zoom: 7, scale: [1e6, 75e4, 7e5, 675e3, 6e5] },
			{ zoom: 8, scale: [5e5, 35e4, 33e4, 3e5] },
			{ zoom: 9, scale: [25e4, 22e4, 2e5, 18e4, 16e4, 15e4] },
			{
				zoom: 10,
				scale: [13e4, 12e4, 1e5, 95e3, 9e4, 85e3, 8e4, 75e3],
			},
			{ zoom: 11, scale: [6e4, 55e3, 52150, 5e4, 45e3, 4e4] },
			{ zoom: 12, scale: [35e3, 3e4, 25e3, 22e3, 2e4] },
			{ zoom: 13, scale: [17500, 16e3, 15e3, 12500, 12e3, 11e3, 1e4] },
			{ zoom: 14, scale: [8e3, 7500, 7e3, 6e3, 5e3] },
			{ zoom: 15, scale: [4e3] },
		];
		for (var j = 0; j < bgZooms.length; j++) {
			for (var k = 0; k < bgZooms[j].scale.length; k++) {
				for (var i = 0; i < bgTiles.length; i++) {
					var tileName = bgTiles[i];
					var scale = bgZooms[j].scale[k];
					if (tileName == 'WV' && scale != 0) {
						continue; //没有该scale
					}
					if (tileName == 'AU' && 0 > new Array(1500000, 350000, 180000, 90000, 45000, 22000).indexOf(scale)) {
						continue; //没有该scale
					}
					if (
						tileName == 'ACN' &&
						0 >
							new Array(
								5000,
								6000,
								7000,
								7500,
								10000,
								11000,
								12000,
								12500,
								15000,
								16000,
								17500,
								20000,
								22000,
								25000,
								30000,
								35000,
								45000,
								50000,
								55000,
								60000,
								75000,
								80000,
								85000,
								90000,
								95000,
								100000,
								120000,
								130000,
								150000,
								250000,
								500000,
								750000,
								1000000,
							).indexOf(scale)
					) {
						continue; //没有该scale
					}
					if (tileName == 'JP' && 0 > new Array(22000, 45000, 90000, 180000, 350000, 700000).indexOf(scale)) {
						continue; //没有该scale
					}
					if (
						tileName == 'US' &&
						0 >
							new Array(
								10000000,
								4860700,
								3500000,
								3121170,
								1200000,
								700000,
								600000,
								350000,
								300000,
								250000,
								220000,
								200000,
								180000,
								160000,
								120000,
								100000,
								80000,
								75000,
								60000,
								52150,
							).indexOf(scale)
					) {
						continue; //没有该scale
					}
					var minzoom = bgZooms[j].zoom;
					var sourcetile = tileName + bgZooms[j].zoom;
					if (bgZooms[j].zoom == 0) sourcetile = tileName;
					//
					this.render_map_background_chartlayer(sourcetile, minzoom, scale, bool);
				}
			}
		}
	},
	addBaseLayer() {
		this.mapboxMap.addLayer({
			id: 'baseLayer',
			type: 'symbol',
			source: {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [],
				},
			},
			layout: {},
			paint: {},
		});
		this.mapboxMap.addLayer({
			id: 'baseBottomLayer',
			type: 'symbol',
			source: {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [],
				},
			},
			layout: {},
			paint: {},
		});
		[
			'ports',
			'load-line-point',
			'load-line-polygon',
			'eca-zones-point',
			'eca-zones-polygon',
			'war-zones-point',
			'war-zones-polygon',
		].forEach((e) => {
			this.mapboxMap.moveLayer(e, 'baseBottomLayer');
		});
	},
	//private 创建电子海图layer
	render_map_background_chartlayer(sourcetile, minzoom, scale, visible = true) {
		//var bottomLayerId = (renderingOptions && renderingOptions.bottomLayerId) ? renderingOptions.bottomLayerId : undefined;
		//var visible = renderingOptions && renderingOptions.visible == true;

		//语言，默认英文
		//var lang = (renderingOptions && renderingOptions.lang && renderingOptions.lang == 'zh-cn') ? 'zh-cn' : 'en';
		var lang = 'en';
		var sourceId = 'bg_s_' + sourcetile;
		var lPrefix = 'bg_l_' + sourcetile + '_' + scale + '_';

		var layerPolygonId = lPrefix + 'Polygon';
		var layerLineStringId_Solid = lPrefix + 'LineString_Solid';
		var layerLineStringId_Dash = lPrefix + 'LineString_Dash';
		var layerLineStringId_Dot = lPrefix + 'LineString_Dot';
		var layerPointId = lPrefix + 'Point';
		var layerTextId = lPrefix + 'Text';

		if (!visible) {
			//20200608改善删除layer，不删除会导致和land.mbtiles层级错乱
			if (this.mapboxMap.getLayer(layerPolygonId)) this.mapboxMap.removeLayer(layerPolygonId);
			if (this.mapboxMap.getLayer(layerLineStringId_Solid)) this.mapboxMap.removeLayer(layerLineStringId_Solid);
			if (this.mapboxMap.getLayer(layerLineStringId_Dash)) this.mapboxMap.removeLayer(layerLineStringId_Dash);
			if (this.mapboxMap.getLayer(layerLineStringId_Dot)) this.mapboxMap.removeLayer(layerLineStringId_Dot);
			if (this.mapboxMap.getLayer(layerPointId)) this.mapboxMap.removeLayer(layerPointId);
			if (this.mapboxMap.getLayer(layerTextId)) this.mapboxMap.removeLayer(layerTextId);
			return;
		}

		var source_layer_Polygon = 'layer_Polygon';
		var source_layer_LineString = 'layer_LineString';
		var source_layer_Point = 'layer_Point';

		if (!!!this.mapboxMap.getSource(sourceId)) {
			this.mapboxMap.addSource(sourceId, {
				type: 'vector',
				tiles: ['http://47.99.114.136:9002' + '/' + sourcetile + '/{z}/{x}/{y}'],
			});
		}

		var filterScale = !!scale ? ['==', 'OrignalScale', scale] : null;

		var layerConfig;

		var filter = filterScale ? ['all', ['==', '$type', 'Polygon'], filterScale] : ['==', '$type', 'Polygon'];
		if (this.mapboxMap.getLayer(layerPolygonId)) {
			this.mapboxMap.setFilter(layerPolygonId, filter);
		} else {
			layerConfig = {
				id: layerPolygonId,
				type: 'fill',
				source: sourceId,
				'source-layer': source_layer_Polygon,
				filter: filter,
				layout: {},
				paint: {
					'fill-color': ['get', 'fill'],
					'fill-opacity': ['get', 'fill-opacity'],
				},
				minzoom: minzoom,
			};
			this.mapboxMap.addLayer(layerConfig, 'baseLayer');
		}

		//line
		filter = ['all', ['==', '$type', 'LineString'], ['==', 'lineStyle', 0]];
		if (filterScale) filter.push(filterScale);
		if (this.mapboxMap.getLayer(layerLineStringId_Solid)) {
			this.mapboxMap.setFilter(layerLineStringId_Solid, filter);
		} else {
			layerConfig = {
				id: layerLineStringId_Solid,
				type: 'line',
				source: sourceId,
				'source-layer': source_layer_LineString,
				filter: filter,
				layout: {
					'line-cap': 'round',
					'line-join': 'round',
				},
				paint: {
					'line-color': ['get', 'stroke'],
					'line-width': ['/', ['get', 'stroke-width'], 2],
					'line-opacity': ['get', 'stroke-opacity'],
					//"line-dasharray": ["case", ["==", ['get', 'lineStyle'], 0], [-1, -1], [3, 3]],
				},
				minzoom: minzoom,
			};
			//!!bottomLayerId ? this.mapboxMap.addLayer(layerConfig, bottomLayerId) : this.mapboxMap.addLayer(layerConfig);
			this.mapboxMap.addLayer(layerConfig, 'baseLayer');
		}

		//长虚线
		filter = ['all', ['==', '$type', 'LineString'], ['==', 'lineStyle', 1]];
		if (filterScale) filter.push(filterScale);
		if (this.mapboxMap.getLayer(layerLineStringId_Dash)) {
			this.mapboxMap.setFilter(layerLineStringId_Dash, filter);
		} else {
			layerConfig.id = layerLineStringId_Dash;
			layerConfig.filter = filter;
			layerConfig.paint['line-dasharray'] = [3, 1.5];
			//!!bottomLayerId ? this.mapboxMap.addLayer(layerConfig, bottomLayerId) : this.mapboxMap.addLayer(layerConfig);
			this.mapboxMap.addLayer(layerConfig, 'baseLayer');
		}

		//短虚线
		filter = ['all', ['==', '$type', 'LineString'], ['==', 'lineStyle', 2]];
		if (filterScale) filter.push(filterScale);
		if (this.mapboxMap.getLayer(layerLineStringId_Dot)) {
			this.mapboxMap.setFilter(layerLineStringId_Dot, filter);
		} else {
			layerConfig.id = layerLineStringId_Dot;
			layerConfig.filter = filter;
			layerConfig.paint['line-dasharray'] = [2, 2];
			//!!bottomLayerId ? this.mapboxMap.addLayer(layerConfig, bottomLayerId) : this.mapboxMap.addLayer(layerConfig);
			this.mapboxMap.addLayer(layerConfig, 'baseLayer');
		}

		//隐藏重要文本
		var isHidenImpText = false;
		//隐藏费重要文本
		var isHidenNonImpText = false;
		//隐藏灯质
		var isHidenLight = false;
		//隐藏水深
		var isHidenDepth = false;

		//isHidenImpText = true;
		//isHidenNonImpText = true;
		//isHidenLight = true;
		//isHidenDepth = true;

		//Point
		filter = ['all', ['==', '$type', 'Point'], ['has', 'deg']];
		if (filterScale) filter.push(filterScale);
		if (isHidenLight) {
			filter.push(['!=', 'sourceType', 2]); //不显示灯质的符号
		}
		if (isHidenDepth) {
			filter.push(['!=', 'sourceType', 4]); //不显示水深的符号部分
		}

		if (this.mapboxMap.getLayer(layerPointId)) {
			this.mapboxMap.setFilter(layerPointId, filter);
		} else {
			layerConfig = {
				id: layerPointId,
				type: 'symbol',
				source: sourceId,
				'source-layer': source_layer_Point,
				filter: filter,
				layout: {
					'icon-image': ['get', 'symbolId'],
					'icon-size': {
						stops: [
							[8, 0.6],
							[9, 0.7],
							[11, 0.8],
							[12, 1],
						],
					},
					'icon-rotate': ['get', 'deg'],
					'icon-ignore-placement': true,
					'icon-allow-overlap': true,
					'symbol-z-order': 'source',
					'icon-rotation-alignment': 'map',
				},
				minzoom: minzoom,
			};
			//!!bottomLayerId ? this.mapboxMap.addLayer(layerConfig, bottomLayerId) : this.mapboxMap.addLayer(layerConfig);
			this.mapboxMap.addLayer(layerConfig, 'baseLayer');
		}

		//文字
		filter = ['all', ['==', '$type', 'Point'], ['has', 'Text_en']];
		if (filterScale) filter.push(filterScale);
		if (isHidenImpText) {
			filter.push(['!=', 'Text_level', 3]); //不显示灯质的文本部分
		}
		if (isHidenNonImpText) {
			filter.push(['!=', 'Text_level', 2]); //不显示灯质的文本部分
		}
		if (isHidenLight) {
			filter.push(['!=', 'Text_level', 1]); //不显示灯质的文本部分
		}
		if (isHidenDepth) {
			filter.push(['!=', 'IsSoundingText', true]); //不显示水深的文本部分
		}
		var textField = lang == 'zh-cn' ? 'Text_zh_cn' : 'Text_en';
		if (this.mapboxMap.getLayer(layerTextId)) {
			this.mapboxMap.setFilter(layerTextId, filter);
			this.mapboxMap.setLayoutProperty(layerTextId, 'text-field', '{' + textField + '}');
		} else {
			layerConfig = {
				id: layerTextId,
				type: 'symbol',
				source: sourceId,
				'source-layer': source_layer_Point,
				filter: filter,
				layout: {
					'text-field': '{' + textField + '}',
					//"text-font": ["Microsoft YaHei Regular"],
					//"text-font": ["SimSun Regular"],
					//"text-font": ["Arial Regular"],
					'text-offset': [
						'case',
						['==', ['get', 'text_anchor'], 'left'],
						['literal', [0.3, 0]],
						['==', ['get', 'text_anchor'], 'right'],
						['literal', [-0.3, 0]],
						['literal', [0, 0]],
					],
					'text-size': ['case', ['==', ['get', 'IsSoundingText'], true], 10, 12],
					//'text-justify': "left",
					//"text-anchor": "right",
					'text-anchor': ['get', 'text_anchor'],
				},
				paint: {
					'text-color': ['case', ['==', ['get', 'IsSoundingText'], true], '#999', ['get', 'text-color']],
					'text-halo-color': 'rgba(255,255,255,0.8)',
					'text-halo-width': 0.5,
				},
				minzoom: minzoom,
			};
			//!!bottomLayerId ? this.mapboxMap.addLayer(layerConfig, bottomLayerId) : this.mapboxMap.addLayer(layerConfig);
			this.mapboxMap.addLayer(layerConfig, 'baseLayer');
		}

		//layerPolygonId作为返回
		return layerPolygonId;
	},
	clearWptDataToPgData(routePlanTableData, data) {
		var array = [];
		routePlanTableData.forEach((element) => {
			let json = {
				atd: element.Summary.ATD,
				type: '',
				distance: '',
				duration: '',
				ecaDistance: element.Summary.ecaDistance,
				ecaFuel: element.Summary.ecaFuel,
				eta: '',
				etd: element.Summary.ETD,
				parentId: data.customerId,
				pdf: '',
				remarks: '',
				routePlan: element.Summary.routePlan,
				type: data.type,
			};
			json.fuel = element.Summary.FuelConsumption;
			if (element.Summary.RouteId) {
				json.id = element.Summary.RouteId;
			}
			json.distance = element.Summary.TotalVoyage;
			json.duration = element.Summary.Durations;
			json.eta = element.Summary.ETA;
			json.remarks = data.remarks;
			json.simulationBallastSonList = [];
			element.WPTList.forEach((ele, index) => {
				let jsonPpt = {
					accudistance: ele.AccuDistance,
					accufuel: ele.AccuFuel,
					accutime: ele.AccuTime,
					airpressure: ele.AirPressure,
					anchtime: ele.AnchTime,
					cf: ele.CF,
					course: ele.Course,
					currentdir: ele.CurrentDir,
					currentfactor: ele.CurrentFactor,
					currentspeed: ele.CurrentSpeed,
					dayfuel: ele.DayFuel,
					distance: ele.Distance,
					durations: ele.Durations,
					estfuel: ele.EstFuel,
					estpower: ele.EstPower,
					estrpm: ele.EstRPM,
					eta: ele.ETA,
					etd: ele.ETD,
					id: '',
					intermediateport: ele.IntermediatePort,
					intermediatetotaltime: ele.IntermediateTotalTime,
					isfixedeta: '',
					isfixedstw: '',
					lat: ele.Lat,
					legtype: ele.LegType,
					lon: ele.Lon,
					parentId: '',
					power: ele.Power,
					remaindistance: ele.RemainDistance,
					remaintime: ele.RemainTime,
					rpm: ele.RPM,
					sigwaveheight: ele.SigWaveHeight,
					sog: ele.SOG,
					staytime: ele.StayTime,
					stw: ele.STW,
					swelldir: ele.SwellDir,
					swellheight: ele.SwellHeight,
					swellperiod: ele.SwellPeriod,
					turnradius: ele.TurnRadius,
					weatherfactor: ele.WeatherFactor,
					wf: ele.WF,
					winddir: ele.WindDir,
					windspeed: ele.WindSpeed,
					windwaveheight: ele.WindWaveHeight,
					worktime: ele.WorkTime,
					wptName: ele.WPT_Name,
					wptNo: ele.wptNo,
					wptype: ele.WPType,
				};
				json.simulationBallastSonList.push(jsonPpt);
			});
			array.push(json);
		});
		return array;
	},
	clearPgWptToWptData(routePlanTableData, parameter) {
		var array = [];
		routePlanTableData.forEach((element) => {
			let json = {
				routePlan: element.routePlan,
				WPT_No: element.accudistance,
				WPT_Name: element.wptName,
				LegType: element.legtype,
				WPType: element.wptype,
				Lat: parseFloat(element.lat),
				CF: element.cf,
				WF: element.wf,
				Lon: parseFloat(element.lon),
				ETD: element.etd,
				STW: element.stw,
				Course: element.course,
				Distance: element.distance,
				Durations: element.durations,
				ETA: element.eta,
				AccuDistance: element.accudistance,
				AccuTime: element.accutime,
				RemainDistance: element.remaindistance,
				RemainTime: element.remaintime,
				IntermediatePort: element.intermediateport,
				AnchTime: element.anchtime,
				WorkTime: element.worktime,
				StayTime: element.staytime,
				IntermediateTotalTime: element.intermediatetotaltime,
				SOG: element.sog,
				TurnRadius: element.turnradius,
				RPM: element.rpm,
				Power: element.power,
				DayFuel: element.dayfuel,
				CurrentFactor: element.currentfactor,
				WeatherFactor: element.currentspeed,
				EstRPM: element.estrpm,
				EstPower: element.estpower,
				EstFuel: element.estfuel,
				AccuFuel: element.accufuel,
				WindDir: element.winddir,
				WindSpeed: element.windspeed,
				WindWaveHeight: element.windwaveheight,
				SigWaveHeight: element.sigwaveheight,
				SwellDir: element.swelldir,
				SwellHeight: element.swellheight,
				SwellPeriod: element.swellperiod,
				CurrentDir: element.currentdir,
				CurrentSpeed: element.currentspeed,
				AirPressure: element.airpressure,
				...parameter,
			};
			array.push(json);
		});
		return array;
	},
	clearPgDataToWptData(routePlanTableData, data) {
		var array = [];
		routePlanTableData.forEach((element) => {
			let pdfList;
			if (element.pdfList) {
				if (element.pdfList.indexOf(',') > -1) {
					pdfList = element.pdfList.split(',');
				} else {
					pdfList = [element.pdfList];
				}
			}
			let json = {
				parentId: element.parentId,
				RouteId: element.id,
				FuelConsumption: element.fuel,
				EcaDistance: element.ecaDistance,
				EcaFuel: element.ecaFuel,
				ETD: element.etd,
				ATD: element.atd,
				Durations: element.duration,
				TotalVoyage: element.distance,
				ETA: element.eta,
				pdf: element.pdf,
				pdfList: pdfList,
				routePlan: element.routePlan,
				updateTime: element.updateTime,
				createTime: element.createTime,
				...data,
			};
			if (element.simulationBallastSonList) {
			}
			// element.WPTList.forEach(ele => {
			// 	let jsonPpt = {
			// 		"accudistance": ele.AccuDistance,
			// 		"accufuel": ele.AccuFuel,
			// 		"accutime": ele.AccuTime,
			// 		"airpressure": ele.AirPressure,
			// 		"anchtime": ele.AnchTime,
			// 		"cf": ele.CF,
			// 		"course": ele.Course,
			// 		"currentdir": ele.CurrentDir,
			// 		"currentfactor": ele.CurrentFactor,
			// 		"currentspeed": ele.CurrentSpeed,
			// 		"dayfuel": ele.DayFuel,
			// 		"distance": ele.Distance,
			// 		"durations": ele.Durations,
			// 		"estfuel": ele.EstFuel,
			// 		"estpower": ele.EstPower,
			// 		"estrpm": ele.EstRPM,
			// 		"eta": ele.ETA,
			// 		"etd": ele.ETD,
			// 		"id": "",
			// 		"intermediateport": ele.IntermediatePort,
			// 		"intermediatetotaltime": ele.IntermediateTotalTime,
			// 		"isfixedeta": '',
			// 		"isfixedstw": '',
			// 		"lat": ele.Lat,
			// 		"legtype": ele.LegType,
			// 		"lon": ele.Lon,
			// 		"parentId": '',
			// 		"power": ele.Power,
			// 		"remaindistance": ele.RemainDistance,
			// 		"remaintime": ele.RemainTime,
			// 		"rpm": ele.RPM,
			// 		"sigwaveheight": ele.SigWaveHeight,
			// 		"sog": ele.SOG,
			// 		"staytime": ele.StayTime,
			// 		"stw": ele.STW,
			// 		"swelldir": ele.SwellDir,
			// 		"swellheight": ele.SwellHeight,
			// 		"swellperiod": ele.SwellPeriod,
			// 		"turnradius": ele.TurnRadius,
			// 		"weatherfactor": ele.WeatherFactor,
			// 		"wf": ele.WF,
			// 		"winddir": ele.WindDir,
			// 		"windspeed": ele.WindSpeed,
			// 		"windwaveheight": ele.WindWaveHeight,
			// 		"worktime": ele.WorkTime,
			// 		"wptName": ele.WPT_Name,
			// 		"wptNo": ele.wptNo,
			// 		"wptype": ele.WPType
			// 	}
			// 	json.simulationBallastSonList.push(jsonPpt);
			// });
			array.push({
				Summary: json,
				WPTList: [],
			});
		});
		return array;
	},
	getWeatherPointInfo(WPTList, speed) {
		let pointArray = [];
		let firstdate = 0;
		let firstDistance = 0;
		let sixHourDistance = (3 * speed) / 0.539957;
		let lastDate = 0;
		WPTList.forEach((item, index) => {
			if (index == 0) {
				firstdate = this.getNextPointTime(item.ETD);
				firstDistance = (((firstdate - new Date(item.ETD).getTime()) / 1000 / 60 / 60) * speed) / 0.539957;
				//firstDistance = 0
			}
			if (index == WPTList.length - 1) {
				lastDate = item.ETD;
			}
			pointArray.push([item.Lon, item.Lat]);
		});
		let pointList = [];
		this.getNextWeatherPoint(
			pointArray,
			pointList,
			{
				date: firstdate,
				Distance: firstDistance,
			},
			sixHourDistance,
			lastDate,
		);
		return pointList;
	},
	getNextPointTime(date) {
		date = new Date(date);
		var y = date.getFullYear();
		var m = date.getMonth() + 1;
		m = m < 10 ? '0' + m : m;
		var d = date.getDate();
		d = d < 10 ? '0' + d : d;
		let oneTime = new Date(y + '-' + m + '-' + d + ' ' + '00:00:00').getTime();
		let date1 = date.getTime();
		let date2 = date1;
		for (let index = 0; index < 10; index++) {
			const element = oneTime + index * 3 * 60 * 60 * 1000;
			if (element >= date1) {
				date2 = new Date(element).getTime();
				break;
			}
		}
		return date2;
	},
	getNextWeatherPoint(pointArray, pointList, first, sixHourDistance, lastDate) {
		var line = turf.lineString(pointArray);
		let length = turf.length(line);
		if (pointList.length == 0) {
			var start = 0;
			var stop = first.Distance;
			if (stop == 0) {
				pointList.push({
					dataTime: new Date(first.date).getTime(),
					lat: pointArray[0][1],
					lng: pointArray[0][0],
				});
			} else {
				var sliced = turf.lineSliceAlong(line, start, stop);
				pointList.push({
					dataTime: new Date(first.date).getTime(),
					lat: sliced.geometry.coordinates[1][1],
					lng: sliced.geometry.coordinates[1][0],
				});
			}
			this.getNextWeatherPoint(
				pointArray,
				pointList,
				{
					startDistance: first.Distance,
					date: first.date + 1000 * 60 * 60 * 3,
					Distance: sixHourDistance + first.Distance,
				},
				sixHourDistance,
				lastDate,
			);
		} else if (first.Distance < length && new Date(first.date).getTime() < new Date(lastDate).getTime()) {
			var start = first.startDistance;
			var stop = first.Distance;
			try {
				var sliced = turf.lineSliceAlong(line, start, stop);
				if (sliced) {
					pointList.push({
						dataTime: new Date(first.date).getTime(),
						lat: sliced.geometry.coordinates[1][1],
						lng: sliced.geometry.coordinates[1][0],
					});
					this.getNextWeatherPoint(
						pointArray,
						pointList,
						{
							startDistance: first.Distance,
							date: first.date + 1000 * 60 * 60 * 3,
							Distance: sixHourDistance + first.Distance,
						},
						sixHourDistance,
						lastDate,
					);
				}
			} catch (err) {}
		} else {
			if (new Date(first.date).getTime() < new Date(lastDate).getTime()) {
				var start = first.startDistance;
				var stop = first.Distance;
				let remainLength = length - first.startDistance;
				let remainTime = parseInt((remainLength / sixHourDistance - 1) * 3 * 60 * 60 * 1000);
				pointList.push({
					dataTime: new Date(first.date).getTime() + remainTime,
					lat: pointArray[pointArray.length - 1][1],
					lng: pointArray[pointArray.length - 1][0],
				});
			} else {
				pointList.push({
					dataTime: new Date(lastDate).getTime(),
					lat: pointArray[pointArray.length - 1][1],
					lng: pointArray[pointArray.length - 1][0],
				});
			}
		}
	},
	get_compass_pos(angle, is32) {
		if (!angle || angle == 99999) {
			return '';
		}
		var compass_pos = [
			'N',
			'NNE',
			'NE',
			'ENE',
			'E',
			'ESE',
			'SE',
			'SSE',
			'S',
			'SSW',
			'SW',
			'WSW',
			'W',
			'WNW',
			'NW',
			'NNW',
			'N',
		];
		var compass_32pos = [
			'N',
			'N/E',
			'NNE',
			'NE/N',
			'NE',
			'NE/E',
			'ENE',
			'E/N',
			'E',
			'E/S',
			'ESE',
			'SE/E',
			'SE',
			'SE/S',
			'SSE',
			'S/E',
			'S',
			'S/W',
			'SSW',
			'SW/S',
			'SW',
			'SW/W',
			'WSW',
			'W/S',
			'W',
			'W/N',
			'WNW',
			'NW/W',
			'NW',
			'NW/N',
			'NNW',
			'N/W',
			'N',
		];
		var compass = '';
		angle = this.convertTo360(angle);
		if (is32) {
			if (compass_32pos) {
				//共32个.
				compass = compass_32pos[Math.round(angle / 11.25)];
			} else {
				throw new Error('compass_32pos is required');
			}
		} else {
			if (compass_pos) {
				//共16个.
				compass = compass_pos[Math.round(angle / 22.5)];
			} else {
				throw new Error('compass_pos is required');
			}
		}
		return compass;
	},
	convertTo360(alfa) {
		var beta = alfa % 360;
		if (beta < 0) {
			beta += 360;
		}
		return beta;
	},
};

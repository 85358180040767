<template>
	<div class="tabpane2">
		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label-bold">· Vessel Info.</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="text-row-class">
			<el-col :span="12">
				<el-autocomplete
					class="inline-input"
					v-model="vesselShip"
					:disabled="isDisabled"
					:fetch-suggestions="getVessel"
					placeholder="请选择船舶"
					@select="handleSelect"
				></el-autocomplete>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="text-row-class" v-show="vesselShip">
			<el-col :span="8">
				<div class="text-label" @click="submitForm">Vessel Name</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">IMO</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">MMSI</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="elrow-class" v-show="vesselShip">
			<el-col :span="8">
				<el-input v-model="vesselForm.vesselName" placeholder="" disabled size="small" class="el-search-input">
				</el-input>
			</el-col>
			<el-col :span="8">
				<el-input v-model="vesselForm.imo" placeholder="" disabled size="small" class="el-search-input"> </el-input>
			</el-col>
			<el-col :span="8">
				<el-input v-model="vesselForm.mmsi" placeholder="" :disabled="isDisabled" size="small" class="el-search-input">
				</el-input>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="text-row-class" v-show="vesselShip">
			<el-col :span="8">
				<div class="text-label">Vessel Type</div>
			</el-col>
			<el-col :span="8">
				<div class="text-label">DWT</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="elrow-class" v-show="vesselShip">
			<el-col :span="8">
				<el-input v-model="vesselForm.vesselType" disabled size="small" class="el-search-input"> </el-input>
			</el-col>
			<el-col :span="8">
				<el-input v-model="vesselForm.dwt" disabled size="small" class="el-search-input"> </el-input>
			</el-col>
		</el-row>

		<el-divider></el-divider>

		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label-bold">· CP Performance</div>
			</el-col>
		</el-row>

		<el-row :gutter="20" class="text-row-class">
			<el-form :model="tableForm" ref="form" size="small" class="table-form">
				<el-table :data="tableForm.tableData" border size="small" header-row-class-name="table-header">
					<el-table-column label="L/D" width="90" prop="ld" align="center"></el-table-column>
					<el-table-column label="Speed" align="center">
						<template v-slot="scope">
							<el-form-item :prop="'tableData.' + scope.$index + '.' + 'speed'">
								<el-input :disabled="isDisabled" v-model="scope.row.speed" placeholder="knot"> </el-input>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="HSFO" align="center">
						<template v-slot="scope">
							<el-form-item :prop="'tableData.' + scope.$index + '.' + 'hsfo'">
								<el-input :disabled="isDisabled" v-model="scope.row.hsfo" placeholder="MT/Day"> </el-input>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="LSFO" align="center">
						<template v-slot="scope">
							<el-form-item :prop="'tableData.' + scope.$index + '.' + 'lsfo'">
								<el-input :disabled="isDisabled" v-model="scope.row.lsfo" placeholder="MT/Day"> </el-input>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="MDO" align="center">
						<template v-slot="scope">
							<el-form-item :prop="'tableData.' + scope.$index + '.' + 'mdo'">
								<el-input :disabled="isDisabled" v-model="scope.row.mdo" placeholder="MT/Day"> </el-input>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="MGO" align="center">
						<template v-slot="scope">
							<el-form-item :prop="'tableData.' + scope.$index + '.' + 'mgo'">
								<el-input :disabled="isDisabled" v-model="scope.row.mgo" placeholder="MT/Day"> </el-input>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="" align="center" width="150">
						<template v-slot="scope">
							<el-button
								:disabled="isDisabled"
								@click="addCpPerformance(scope.row)"
								icon="el-icon-plus"
								circle
								size="small"
							></el-button>
							<el-button
								:disabled="isDisabled"
								@click="removeCpPerformance(scope.row)"
								icon="el-icon-minus"
								circle
								size="small"
							></el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-form>
		</el-row>

		<!-- <el-row :gutter="20" class="text-row-class text-row-cp">
			<div class="cp-text">
				AT SEA NORMAL SPEED ------------------- LADEN: 13.00 KNOTS ON 27.50 MT 380 VLSFO + 0.2 MT LSMGO BALLAST:
				13.50
				KNOTS ON 27.50 MT 380 VLSFO + 0.2 MT LSMGO <br />
				AT SEA ECO SPEED ----------------- LADEN: 11.50 KNOTS ON 20.50 MT 380 VLSFO + 0.2 MT LSMGO BALLAST: 12.00
				KNOTS
				ON 20.50 MT 380 VLSFO + 0.2 MT LSMGO
			</div>
		</el-row> -->

		<el-divider></el-divider>

		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label-bold">· CP Terms</div>
			</el-col>
		</el-row>

		<el-form :model="termsForm" class="terms-form">
			<div class="terms-form-left">
				<el-row :gutter="10" class="text-row-class">
					<el-col :span="24">
						<el-form-item label="Beaufort Wind Scale:">
							<div class="text-row-bf">BF</div>
							<el-select :disabled="isDisabled" v-model="termsForm.bf" size="small" class="short-select" placeholder="">
								<el-option v-for="item in windLevelList" :label="item" :value="item" :key="item"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="text-row-class" :gutter="10">
					<el-col :span="24">
						<el-form-item label="Significant Wave Height: ">
							<el-input
								:disabled="isDisabled"
								size="small"
								style="margin-right: 20px"
								v-model="termsForm.sigWave"
								class="short-input"
							>
							</el-input>
							<span class="text-row-sig">M</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="text-row-class" :gutter="10">
					<el-col :span="24">
						<el-form-item label="No Adverse Current:">
							<el-select
								:disabled="isDisabled"
								v-model="termsForm.noAdverseCurrent"
								size="small"
								class="short-select-yes"
								placeholder=""
							>
								<el-option v-for="item in yesOrNoOption" :label="item" :value="item" :key="item"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="text-row-class" :gutter="10">
					<el-col :span="24">
						<el-form-item label="Favorable Current:">
							<el-select
								:disabled="isDisabled"
								v-model="termsForm.favorableCurrent"
								size="small"
								class="short-select-yes"
								placeholder=""
							>
								<el-option v-for="item in yesOrNoOption" :label="item" :value="item" :key="item"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="text-row-class" :gutter="10">
					<el-col :span="24">
						<el-form-item label="Extrapolation:">
							<el-select
								:disabled="isDisabled"
								v-model="termsForm.extrapolation"
								size="small"
								class="short-select-yes"
								placeholder=""
							>
								<el-option v-for="item in yesOrNoOption" :label="item" :value="item" :key="item"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</div>
			<div class="terms-form-right">
				<el-row class="text-row-class" :gutter="10">
					<el-col :span="24">
						<el-form-item label="Consecutive Hours:">
							<el-input
								:disabled="isDisabled"
								size="small"
								style="margin-right: 20px"
								v-model="termsForm.consecutiveHours"
								class="short-input"
							>
							</el-input>
							<span class="text-row-sig">hrs</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="text-row-class" :gutter="10">
					<el-col :span="24">
						<el-form-item label="Day Time : ">
							<el-input
								:disabled="isDisabled"
								size="small"
								style="margin-right: 20px"
								v-model="termsForm.wog"
								class="short-input"
							>
							</el-input>
							<span class="text-row-sig">%</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="text-row-class" :gutter="10">
					<el-col :span="24">
						<el-form-item label="Spd About :" class="double-input">
							<el-input
								:disabled="isDisabled"
								size="small"
								class="short-input"
								v-model="termsForm.aboutSpdStart"
							></el-input>
							<el-input
								:disabled="isDisabled"
								size="small"
								class="short-input"
								v-model="termsForm.aboutSpdEnd"
							></el-input>
							<el-select
								:disabled="isDisabled"
								style="margin-right: -12px"
								v-model="termsForm.spdAboutUnit"
								size="small"
								class="short-input"
								placeholder=""
							>
								<el-option v-for="item in unitSelect" :label="item" :value="item" :key="item"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="text-row-class" :gutter="10">
					<el-col :span="24">
						<el-form-item label="Fuel About :" class="double-input">
							<el-input
								:disabled="isDisabled"
								size="small"
								class="short-input"
								v-model="termsForm.aboutIfoStart"
							></el-input>
							<el-input
								:disabled="isDisabled"
								size="small"
								class="short-input"
								v-model="termsForm.aboutIfoEnd"
							></el-input>
							<span class="text-row-sig">%</span>
						</el-form-item>
					</el-col>
				</el-row>
			</div>
		</el-form>
	</div>
</template>


<script>
import { v4 as uuidv4 } from 'uuid';
import { addBasicInfo, updateBasicInfo } from '@/api/basic';
import { request } from '@/api/basic';
import { getToken } from '@/api/cookies';
const initVesselForm = {
	vesselName: '',
	imo: '',
	mmsi: '',
	vesselType: '',
	dwt: '',
};

const initTableData = [
	{
		ld: 'Ballast',
		uuid: uuidv4(),
		speed: '',
		hsfo: '',
		lsfo: '',
		mdo: '',
		mgo: '',
	},
	{
		ld: 'Laden',
		uuid: uuidv4(),
		speed: '',
		hsfo: '',
		lsfo: '',
		mdo: '',
		mgo: '',
	},
];

export default {
	props: {
		customerId: {
			type: String,
			default: '',
		},
		basicData: {
			type: Object,
			default: () => {},
		},
		showEdit: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			rules: {
				speed: [{ required: true, message: '请输入Speed', trigger: 'blur' }],
			},

			vesselForm: initVesselForm,

			tableForm: {
				tableData: initTableData,
			},

			termsForm: {
				bf: 4,
				wog: 50,
				sigWave: 1.25,
				noAdverseCurrent: 'Applied',
				consecutiveHours: 1,
				favorableCurrent: 'Not Applied',
				extrapolation: 'Not Applied',
				aboutIfoEnd: 5,
				aboutIfoStart: -5,
				aboutMdoEnd: '',
				aboutMdoStart: '',
				aboutSpdEnd: 0.5,
				aboutSpdStart: -0.5,
				spdAboutUnit: 'kts',
			},
			vesselShip: '',
			selectVessel: {},
			unitSelect: ['%', 'kts'],
			yesOrNoOption: ['Not Applied', 'Applied'],
			windLevelList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		};
	},

	watch: {
		basicData: {
			handler(val) {
				//console.log(val, 'basic2 val');
				if (val) {
					Object.keys(this.termsForm).map((key) => {
						if (val[key]) {
							this.termsForm[key] = val[key];
						}
					});
					Object.keys(this.vesselForm).map((key) => {
						if (val[key]) {
							this.vesselForm[key] = val[key];
						}
					});
					if (!val.basicCpTermsCpPerformances) {
						val.basicCpTermsCpPerformances = [];
					} else {
						let Ballast = val.basicCpTermsCpPerformances.find((ele) => {
							return ele.ld == 'Ballast';
						});
						if (!Ballast) {
							val.basicCpTermsCpPerformances.unshift({
								ld: 'Ballast',
								uuid: uuidv4(),
								speed: '',
								hsfo: '',
								lsfo: '',
								mdo: '',
								mgo: '',
							});
						}
						let laden = val.basicCpTermsCpPerformances.find((ele) => {
							return ele.ld == 'Laden';
						});
						if (!laden) {
							val.basicCpTermsCpPerformances.push({
								ld: 'Laden',
								uuid: uuidv4(),
								speed: '',
								hsfo: '',
								lsfo: '',
								mdo: '',
								mgo: '',
							});
						}
					}
					this.vesselShip = val.vesselName;
					this.tableForm.tableData = val.basicCpTermsCpPerformances || [];
				} else {
					this.resetForm();
				}
			},
		},
	},

	computed: {
		isDisabled() {
			return Boolean(this.customerId && this.showEdit);
		},
	},

	mounted() {},
	methods: {
		async saveBasicInfo() {
			if (!this.customerId) {
				this.$message.info('请先确认Customer信息已被保存');
				return;
			}
			let params = {
				basicCpTerms: {
					...this.vesselForm,
					...this.termsForm,
					basicCpTermsCpPerformances: this.tableForm.tableData,
				},
				parentId: this.customerId,
				type: 'basicCpTerms',
			};

			this.basicData?.id && (params.basicCpTerms.id = this.basicData?.id);
			//console.log(params, 'params====');

			const FUNC = this.basicData ? updateBasicInfo : addBasicInfo;
			let data = await FUNC(params);
			if (data.code === 200) {
				// this.$message.success('Save Success!');
				// this.$bus.$emit('refreshList');
			} else {
				this.$message.info(data.message);
			}
		},
		handleSelect(val) {
			this.selectVessel = val;
			Object.keys(this.vesselForm).map((key) => {
				if (this.selectVessel[key]) {
					this.vesselForm[key] = this.selectVessel[key];
				}
			});
			let vesselTypeKey = {
				0: 'TANKER',
				1: 'LNG',
				2: 'BULK',
				3: 'PCC',
				4: 'CNTR',
			}
			this.vesselForm.vesselName = this.selectVessel.vesselName;
			this.vesselForm.vesselType = vesselTypeKey[this.selectVessel.vesselType];
			console.log(this.vesselForm);
		},
		submitForm() {
			//console.log('form', this.tableForm);
		},

		// CP Performance Table 增减项
		addCpPerformance(row) {
			this.tableForm.tableData.push({
				uuid: uuidv4(),
				ld: row.ld,
				speed: '',
				hsfo: '',
				lsfo: '',
				mdo: '',
				mgo: '',
			});
			// 排序Ballast新增的条目，全都集中在上面，不要和Laden混在一起。
			// Laden新增的条目，全都集中在下面，不要和Ballast混在一起。
			this.tableForm.tableData.sort((a, b) => {
				// If index is 0 or 1, keep original order
				const indexA = this.tableForm.tableData.indexOf(a);
				const indexB = this.tableForm.tableData.indexOf(b);
				if (indexA > 0 && indexB > 0) {
					// If 'ld' is 'Ballast', it should come first
					if (a.ld === 'Ballast' && b.ld === 'Laden') {
						return -1;
					}
					if (a.ld === 'Laden' && b.ld === 'Ballast') {
						return 1;
					}
				}
				// If 'ld' values are the same or not 'Laden'/'Ballast', keep original order
				return indexA - indexB;
			});
			console.log(this.tableForm.tableData);
		},
		removeCpPerformance(row) {
			let one = this.tableForm.tableData.filter((a) => a.ld === 'Ballast');
			let two = this.tableForm.tableData.filter((a) => a.ld === 'Laden');
			if (one.length <= 1 && row.ld == 'Ballast') {
				this.$message.warning('至少保留一条Ballast');
				return;
			}
			if (two.length <= 1 && row.ld == 'Laden') {
				this.$message.warning('至少保留一条Laden');
				return;
			}
			this.tableForm.tableData = this.tableForm.tableData.filter(
				(item) => item.uuid !== row.uuid || item.id !== row.id,
			);
		},

		resetForm() {
			console.log(initTableData);
			this.vesselForm = JSON.parse(JSON.stringify(initVesselForm));
			this.tableForm = {
				tableData: JSON.parse(JSON.stringify(initTableData)),
			};
			this.termsForm = {
				bf: 4,
				wog: 50,
				sigWave: 1.25,
				noAdverseCurrent: 'Applied',
				consecutiveHours: 1,
				favorableCurrent: 'Not Applied',
				extrapolation: 'Not Applied',
				aboutIfoEnd: 5,
				aboutIfoStart: -5,
				aboutMdoEnd: '',
				aboutMdoStart: '',
				aboutSpdEnd: 0.5,
				aboutSpdStart: -0.5,
				spdAboutUnit: 'kts',
			};
			this.vesselShip = '';
		},

		// 表格第一列的样式
		cellStyle(data) {
			if (data.columnIndex === 0) {
				return `color: rgba(0, 0, 0, 0.85)`;
			}
		},
		getVessel(queryString, cb) {
			request({
				// url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/vessel/list',
				url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/vessel/base/search',
				method: 'GET',
				body: {
					keyword: queryString.replace(' ', '+'),
					pageNum: 1,
					pageSize: 10,
				},
				header: {
					Authorization:'Bearer '+ getToken(),
				},
			}).then((res) => {
				let array = [];
				// res.data.items.forEach((element) => {
				res.data.forEach((element) => {
					//console.log();
					element.value = element.vesselName + `(${element.imo})`;
					// element.label = element.vesselNameEn
					array.push(element);
				});
				cb(array);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.vessel-select {
	width: 100%;
}
.inline-input {
	width: 300px;
}
::v-deep .table-form {
	.el-table {
		width: 97%;
		margin: 0px 1.5%;
		border-radius: 6px;

		.el-table__header {
			.table-header {
				color: $defaultFontColor;
				font-weight: bold;

				th {
					background: #eff1f5;
				}
			}
		}

		.el-table__row {
			.el-form-item {
				margin-bottom: 0;
				color: $defaultFontColor;
			}
		}
	}
}

.text-row-cp {
	margin-top: 15px !important;

	.cp-text {
		width: 97%;
		margin: 0px 1.5%;
		border-radius: 6px;
		border: 1px solid #d9d9d9;
		background: rgba(53, 86, 145, 0.04);
		color: rgba(0, 0, 0, 0.25);
		font-size: 14px;
		padding: 5px 12px;
		line-height: 22px;
	}
}

::v-deep .terms-form {
	display: flex;
	flex-flow: row;
	margin-top: 10px;

	.terms-form-left,
	.terms-form-right {
		margin-left: 10px;
		background: #f6f7fc;
		width: 45%;
		height: 300px;
		padding: 3px 20px;
		border: 1px #d2dae4 solid;
		max-width: 45%;
		overflow: auto;

		.el-form-item:before,
		.el-form-item:after {
			content: none;
		}

		.text-row-bf {
			position: absolute;
			left: -25px;
		}

		.text-row-sig {
			position: absolute;
			right: -7px;
		}

		.el-form-item {
			display: flex;
			justify-content: space-between;
			margin-bottom: 0;

			label {
				font-size: 14px;
				width: 180px;
				text-align: left;
				color: #707070;
				font-weight: 400;
			}
		}

		.double-input {
			.el-form-item__content {
				display: flex;

				.short-input {
					margin-right: 5px;
				}
			}
		}

		.short-input {
			width: 50px;

			.el-input__inner {
				padding: 0 5px;
			}
		}

		.short-select {
			width: 70px;
		}

		.short-select-yes {
			width: 120px;
		}

		.short-input-number {
			width: 60px;

			.el-input__inner {
				padding: 0 5px;
			}
		}
	}
}
</style>
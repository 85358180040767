import mapobj from '@/utils/mapw.js';
import MonitoringTrackPointIcon from '@/assets/svg/monitoring_track_point.svg';
export let loadVesselTrack = (tracks, loadVesselTrack, that) => {
	const geometrys = {
		type: 'FeatureCollection',
		features: [],
	};
	const trackLineFeature = tracks?.ais?.features[0];
	if (trackLineFeature) {
		trackLineFeature.properties.type = 'trackLine';
		const coors = trackLineFeature.geometry.coordinates[0];
		const trackPoints = getTrackPoints(coors);
		geometrys.features.push(...trackPoints);
		geometrys.features.push(trackLineFeature);
	}
	// ais点时间为北京时间转为世界时间
	if (tracks?.trackPoints) {
		tracks.trackPoints = tracks.trackPoints.map((res) => {
			res.time = new Date(new Date(res.time).getTime() + new Date().getTimezoneOffset() * 60000).getTime();
			return res;
		});
	}
	mapobj.vesselTrackData = {
		trackPoints: tracks?.trackPoints,
		vesselTrackGeometrys: geometrys,
	};
	mapobj.vesselTrackLayer.clearLayers();
	if (geometrys.features.length > 0) {
		L.GridLayer.vectorGrid
			.slicer(geometrys, {
				rendererFactory: L.svg.tile,
				pane: 'overlayPane',
				style: {
					overflow: 'visible',
					pointerEvents: 'none',
				},
				vectorTileLayerStyles: {
					sliced: function (properties, zoom) {
						switch (properties.type) {
							case 'trackLine':
								return {
									weight: 1.5,
									color: 'rgba(53,86,145,0.75)',
								};
							case 'trackPoint':
								return {
									icon: L.icon({
										iconUrl: MonitoringTrackPointIcon,
										iconSize: [15, 33],
										rotationAngle: properties.cog,
										className: 'vmTrackPoint',
									}),
								};
						}
					},
				},
				interactive: true,
				getFeatureId: function (f) {
					return f.properties.id;
				},
			})
			.addTo(mapobj.vesselTrackLayer);
		const vesselProperties = tracks.properties;
		// if (vesselProperties) {
		//     vmVectorLayer.checkedVessel && vmVectorLayer.resetFeatureStyle(vmVectorLayer.checkedVessel)
		//     vmVectorLayer.checkedVessel = vesselProperties.mmsi
		//     vmVectorLayer.setFeatureStyle(vesselProperties.mmsi, {
		//         icon: L.icon({
		//             iconUrl: MonitoringVesselChecked,
		//             iconSize: [40, 40],
		//             rotationAngle: vesselProperties.cog,
		//             className: 'vmVesselIcon'
		//         })
		//     });
		// }

		// if (loadVesselTrack) {
		//     const bounds = L.geoJson(geometrys).getBounds();
		//     mapobj.Lmap.fitBounds(bounds);
		// }
		if (loadVesselTrack) {
			const bounds = L.geoJson(geometrys).getBounds();
			const center = bounds.getCenter();
			const zoomLevel = 5; // 你希望设定的固定缩放层级
			mapobj.Lmap.setView(center, zoomLevel);
		}
	} else {
		that.$message.warning('No track data!');
		// reminder('warning', 'No track data!')
	}
};
const getTrackPoints = (coors) => {
	console.log('🚀 ~ getTrackPoints ~ coors:', coors);
	const tracks = [];
	const pointCount = Math.min(Math.max(10, coors.length / 10), 100);
	const pointIndexSpan = Math.floor(coors.length / pointCount);
	for (let i = pointIndexSpan; i < coors.length - 2; i += pointIndexSpan) {
		const pointJson = {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: coors[i],
			},
			properties: {
				type: 'trackPoint',
				cog: calculateBearing(coors[i][1], coors[i][0], coors[i - 1][1], coors[i - 1][0]),
			},
		};
		tracks.push(pointJson);
	}

	return tracks;
};
export function calculateBearing(lat1, lon1, lat2, lon2) {
	const dLon = ((lon2 - lon1) * Math.PI) / 180;

	const y = Math.sin(dLon) * Math.cos((lat2 * Math.PI) / 180);
	const x =
		Math.cos((lat1 * Math.PI) / 180) * Math.sin((lat2 * Math.PI) / 180) -
		Math.sin((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.cos(dLon);

	let angle = (Math.atan2(y, x) * 180) / Math.PI;
	if (angle < 0) {
		angle += 360;
	}

	return angle;
}

<template>
	<!-- <VueDragResize :isDraggable="isDragActive" axis="y" :parentLimitation="true" :x="0" :y="top" :h="height"> -->
	<div
		ref="rWrap"
		:class="[
			'bottom-content',
			'drag-bottom',
			!modelType.left ? 'left' : '',
			!modelType.right ? 'right' : '',
			!modelType.left && !modelType.right ? 'left-right' : '',
		]"
		:style="`width: calc(${100}% - ${379}px - ${rightWidth}px);`"
	>
		<typhoon-chart v-if="typhoonChart" :typhoonDataList="typhoonDataList" @close="typhoonChart = false" />
		<el-dialog
			v-dialogDrag
			:modal="true"
			append-to-body
			width="1080"
			title="Weather Info"
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
		>
			<div class="dialog-content">
				<el-table
					size="small"
					ref="multipleLineTable"
					height="450"
					stripe
					border
					class="route-plan-table"
					:data="selecLineData"
					header-row-class-name="table-header"
				>
					<el-table-column prop="dateTime" label="DateTime" min-width="150" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="position" label="Position" min-width="180" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="wf" label="WF(knts)" align="center" show-overflow-tooltip> </el-table-column>
					<el-table-column prop="cf" label="CF(knts)" align="center" show-overflow-tooltip> </el-table-column>
					<el-table-column prop="windSpeed" label="Wind Speed(knts)" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="windDir" label="Wind direction" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="sigHgt" label="Sig.Wave Height(m)" align="center" show-overflow-tooltip>
					</el-table-column>
					<!-- <el-table-column prop="WindWaveHeight" label="Wave Height(m)" align="center" show-overflow-tooltip>
					</el-table-column> -->
					<el-table-column prop="currentSpeed" label="Current Speed" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="currentDir" label="Current Direction" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="swellHt" label="Swell Height" align="center" min-width="180" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="swellDir" label="Swell Direction" align="center" min-width="180" show-overflow-tooltip>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
		<weather-master
			v-if="weatherMaster"
			@close="weatherMaster = false"
			:weatherMasterData="weatherMasterData"
			:masterReportData="masterReportData"
			:masterTimeList="masterTimeList"
		/>
		<div class="title">
			<div ref="rResizeLine" id="resize" class="resize-line" @mousedown="handleMouseDown"></div>
			<div class="field-list">
				<div class="field" v-for="item in fieldList" :key="item.label">
					<span class="label">{{ item.label }}: </span>
					<span class="value">{{ item.value }}</span>
				</div>
			</div>

			<div class="btn-list">
				<img @click="showTyphoonWeather" src="@/assets/img/typhoon.svg" width="24px" height="24px" alt="" title="CPA" />
				<el-divider direction="vertical"></el-divider>
				<img @click="showWeather" src="@/assets/img/weather-6.png" width="16px" alt="" title="Show Weather" />
				<el-divider direction="vertical"></el-divider>
				<img
					@click="showWeatherMaster"
					src="@/assets/img/weatherMaster.png"
					width="24px"
					alt=""
					title="Show Weather Master"
				/>
				<el-divider direction="vertical"></el-divider>
				<img @click="exitEdit" src="@/assets/img/exitEdit.png" width="16px" alt="" title="End Editing" />
				<el-divider direction="vertical"></el-divider>
				<img @click="exporExcelData" src="@/assets/img/icon-download.png" alt="" />
				<el-divider direction="vertical"></el-divider>
				<img @click="closeBottom" src="@/assets/img/icon-close.png" alt="" />
			</div>
		</div>
		<el-table
			class="bottom-table"
			:data="tableData"
			@selection-change="handleSelectionChange"
			style="width: 100%"
			:row-class-name="tableRowClassName"
			ref="bottomTable"
			@row-click="selectRow"
			size="small"
			header-row-class-name="table-header"
			:max-height="getMaxHeight"
			:height="tableHeight"
		>
			<el-table-column type="selection" width="55" align="center"> </el-table-column>
			<el-table-column fixed prop="WPT_Name" label="Waypoint Name" width="120" align="center" show-overflow-tooltip>
			</el-table-column>
			<el-table-column prop="LegType" label="Nav. Type" width="120" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<div
						@dblclick="changeEnddate(scope.$index, 'editLegType', scope.row)"
						style="height: 40px; line-height: 40px"
					>
						<span v-show="!scope.row.editLegType">{{ scope.row.LegType }}</span>
						<el-select
							v-focus
							@change="changeTableData('LegType')"
							:ref="'enddateinput' + 'editLegType' + '&' + scope.$index"
							@blur="switchShow(scope.$index, 'editLegType')"
							v-model="scope.row.LegType"
							v-if="scope.row.editLegType"
							placeholder="请选择"
						>
							<el-option v-for="item in legTypeOptions" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="LatLon" label="Lat/Lon" align="center" width="240" show-overflow-tooltip>
				<template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editLatLon', scope.row)" style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editLatLon">{{ scope.row.LatLon }}</span>
						<el-input
							class="lng-lat-input"
							:ref="'enddateinput' + 'editLatLon' + '&' + scope.$index"
							@change="changeTableData('LatLon')"
							@blur="switchShow(scope.$index, 'editLatLon')"
							@keyup.enter.native="$event.target.blur"
							v-if="scope.row.editLatLon"
							size="mini"
							v-model="scope.row.LatLon"
							v-focus
						></el-input>
					</div>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="Lat" label="Lat" align="center" width="120" show-overflow-tooltip>
				<template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editLat', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editLat">{{ scope.row.Lat }}</span>
						<el-input :ref="'enddateinput' + 'editLat' + '&' + scope.$index"
							@change="changeTableData('Lat', scope.$index)" @blur="switchShow(scope.$index, 'editLat')"
							@keyup.enter.native="$event.target.blur" v-show="scope.row.editLat" size="mini"
							v-model="scope.row.Lat"></el-input>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="Lon" label="Lon" align="center" width="150" show-overflow-tooltip>
				<template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editLon', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editLon">{{ scope.row.Lon }}</span>
						<el-input :ref="'enddateinput' + 'editLon' + '&' + scope.$index"
							@change="changeTableData('Lon', scope.$index)" @blur="switchShow(scope.$index, 'editLon')"
							@keyup.enter.native="$event.target.blur" v-show="scope.row.editLon" size="mini"
							v-model="scope.row.Lon"></el-input>
					</div>
				</template>
			</el-table-column> -->
			<el-table-column prop="ETD" label="Date/Time" min-width="180" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editETD', scope.row)" style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editETD">{{ scope.row.ETD }}</span>
						<mydatePicker
							v-focus
							align="center"
							:ref="'enddateinput' + 'editETD' + '&' + scope.$index"
							@change="changeTableData('ETD', scope.$index)"
							@blur="switchShow(scope.$index, 'editETD')"
							@keyup.enter.native="$event.target.blur"
							v-if="scope.row.editETD"
							size="mini"
							type="datetime"
							format="yyyy-MM-dd HH:mm"
							value-format="yyyy-MM-dd HH:mm"
							v-model="scope.row.ETD"
							autofocus
						></mydatePicker>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="StayTime" label="Stoppage(hrs)" min-width="120" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<div
						@dblclick="changeEnddate(scope.$index, 'editStayTime', scope.row)"
						style="height: 40px; line-height: 40px"
					>
						<span v-if="!scope.row.editStayTime">{{ scope.row.StayTime | rounding }}</span>
						<el-input
							:ref="'enddateinput' + 'editStayTime' + '&' + scope.$index"
							@change="changeTableData"
							@blur="switchShow(scope.$index, 'editStayTime')"
							@keyup.enter.native="$event.target.blur"
							v-if="scope.row.editStayTime"
							size="mini"
							v-model="scope.row.StayTime"
							v-focus
						></el-input>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="STW" label="Speed" min-width="150" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editSTW', scope.row)" style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editSTW">{{ scope.row.STW | rounding }}</span>
						<el-input
							:ref="'enddateinput' + 'editSTW' + '&' + scope.$index"
							@change="changeTableData('Speed', scope.$index)"
							@blur="switchShow(scope.$index, 'editSTW')"
							@keyup.enter.native="$event.target.blur"
							v-if="scope.row.editSTW"
							size="mini"
							v-model="scope.row.STW"
							v-focus
						></el-input>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="Distance" label="Distance" min-width="100" align="center" show-overflow-tooltip>
				<!-- <template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editDistance', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editDistance">{{ scope.row.Distance }}</span>
						<el-input :ref="'enddateinput' + 'editDistance' + '&' + scope.$index" @change="changeTableData"
							@blur="switchShow(scope.$index, 'editDistance')" @keyup.enter.native="$event.target.blur"
							v-show="scope.row.editDistance" size="mini" v-model="scope.row.Distance"></el-input>
					</div>
				</template> -->
				<template slot-scope="scope">
					{{ scope.row.Distance | rounding }}
				</template>
			</el-table-column>
			<el-table-column prop="DayFuel" label="Daily Fuel(mt)" min-width="120" align="center" show-overflow-tooltip>
				<!-- <template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editDayFuel', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editDayFuel">{{ scope.row.DayFuel }}</span>
						<el-input :ref="'enddateinput' + 'editDayFuel' + '&' + scope.$index" @change="changeTableData"
							@blur="switchShow(scope.$index, 'editDayFuel')" @keyup.enter.native="$event.target.blur"
							v-show="scope.row.editDayFuel" size="mini" v-model="scope.row.DayFuel"></el-input>
					</div>
				</template> -->
				<template slot-scope="scope">
					{{ scope.row.DayFuel | rounding }}
				</template>
			</el-table-column>
			<el-table-column prop="AccuFuel" label="Remaining BOB(mt)" min-width="180" align="center" show-overflow-tooltip>
				<!-- <template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editAccuFuel', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editAccuFuel">{{ scope.row.AccuFuel }}</span>
						<el-input :ref="'enddateinput' + 'editAccuFuel' + '&' + scope.$index" @change="changeTableData"
							@blur="switchShow(scope.$index, 'editAccuFuel')" @keyup.enter.native="$event.target.blur"
							v-show="scope.row.editAccuFuel" size="mini" v-model="scope.row.AccuFuel"></el-input>
					</div>
				</template> -->
				<template slot-scope="scope">
					{{ scope.row.AccuFuel | rounding }}
				</template>
			</el-table-column>
			<el-table-column prop="Course" label="Heading" align="center" show-overflow-tooltip>
				<!-- <template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editCourse', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editCourse">{{ scope.row.Course }}</span>
						<el-input :ref="'enddateinput' + 'editCourse' + '&' + scope.$index" @change="changeTableData"
							@blur="switchShow(scope.$index, 'editCourse')" @keyup.enter.native="$event.target.blur"
							v-show="scope.row.editCourse" size="mini" v-model="scope.row.Course"></el-input>
					</div>
				</template> -->
				<template slot-scope="scope">
					{{ scope.row.Course | rounding }}
				</template>
			</el-table-column>
			<el-table-column
				prop="RemainDistance"
				label="Remaining Distance"
				min-width="180"
				align="center"
				show-overflow-tooltip
			>
				<!-- <template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editRemainDistance', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editRemainDistance">{{ scope.row.RemainDistance }}</span>
						<el-input :ref="'enddateinput' + 'editRemainDistance' + '&' + scope.$index"
							@change="changeTableData" @blur="switchShow(scope.$index, 'editRemainDistance')"
							@keyup.enter.native="$event.target.blur" v-show="scope.row.editRemainDistance" size="mini"
							v-model="scope.row.RemainDistance"></el-input>
					</div>
				</template> -->
				<template slot-scope="scope">
					{{ scope.row.RemainDistance | rounding }}
				</template>
			</el-table-column>
			<el-table-column prop="Durations" label="Duration(hrs)" min-width="120" align="center" show-overflow-tooltip>
				<!-- <template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editDurations', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editDurations">{{ scope.row.Durations }}</span>
						<el-input :ref="'enddateinput' + 'editDurations' + '&' + scope.$index" @change="changeTableData"
							@blur="switchShow(scope.$index, 'editDurations')" @keyup.enter.native="$event.target.blur"
							v-show="scope.row.editDurations" size="mini" v-model="scope.row.Durations"></el-input>
					</div>
				</template> -->
				<template slot-scope="scope">
					{{ scope.row.Durations | rounding }}
				</template>
			</el-table-column>
			<el-table-column
				prop="RemainTime"
				label="Remaining Duration(hrs)"
				min-width="200"
				align="center"
				show-overflow-tooltip
			>
				<!-- <template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editRemainTime', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editRemainTime">{{ scope.row.RemainTime }}</span>
						<el-input :ref="'enddateinput' + 'editRemainTime' + '&' + scope.$index" @change="changeTableData"
							@blur="switchShow(scope.$index, 'editRemainTime')" @keyup.enter.native="$event.target.blur"
							v-show="scope.row.editRemainTime" size="mini" v-model="scope.row.RemainTime"></el-input>
					</div>
				</template> -->
				<template slot-scope="scope">
					{{ scope.row.RemainTime | rounding }}
				</template>
			</el-table-column>
			<el-table-column prop="WF" label="WF(knts)" min-width="100" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					{{ scope.row.WF | rounding }}
				</template>
			</el-table-column>
			<el-table-column prop="CF" label="CF(knts)" min-width="100" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					{{ scope.row.CF | rounding }}
				</template>
			</el-table-column>
			<el-table-column prop="WindSpeed" label="Wind Speed(knts)" min-width="180" align="center" show-overflow-tooltip>
				<!-- <template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editWindSpeed', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editWindSpeed">{{ scope.row.WindSpeed }}</span>
						<el-input :ref="'enddateinput' + 'editWindSpeed' + '&' + scope.$index" @change="changeTableData"
							@blur="switchShow(scope.$index, 'editWindSpeed')" @keyup.enter.native="$event.target.blur"
							v-show="scope.row.editWindSpeed" size="mini" v-model="scope.row.WindSpeed"></el-input>
					</div>
				</template> -->
				<template slot-scope="scope">
					{{ scope.row.WindSpeed | rounding }}
				</template>
			</el-table-column>
			<el-table-column prop="WindDir" label="Wind direction" min-width="180" align="center" show-overflow-tooltip>
				<!-- <template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editWindDir', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editWindDir">{{ scope.row.WindDir }}</span>
						<el-input :ref="'enddateinput' + 'editWindDir' + '&' + scope.$index" @change="changeTableData"
							@blur="switchShow(scope.$index, 'editWindDir')" @keyup.enter.native="$event.target.blur"
							v-show="scope.row.editWindDir" size="mini" v-model="scope.row.WindDir"></el-input>
					</div>
				</template> -->
				<template slot-scope="scope">
					{{ scope.row.WindDir | rounding }}
				</template>
			</el-table-column>
			<el-table-column
				prop="SigWaveHeight"
				label="Sig.Wave Height(m)"
				min-width="180"
				align="center"
				show-overflow-tooltip
			>
				<!-- <template slot-scope="scope">
					<div @dblclick="changeEnddate(scope.$index, 'editSigWaveHeight', scope.row)"
						style="height: 40px; line-height: 40px">
						<span v-if="!scope.row.editSigWaveHeight">{{ scope.row.SigWaveHeight }}</span>
						<el-input :ref="'enddateinput' + 'editSigWaveHeight' + '&' + scope.$index" @change="changeTableData"
							@blur="switchShow(scope.$index, 'editSigWaveHeight')" @keyup.enter.native="$event.target.blur"
							v-show="scope.row.editSigWaveHeight" size="mini" v-model="scope.row.SigWaveHeight"></el-input>
					</div>
				</template> -->
				<template slot-scope="scope">
					{{ scope.row.SigWaveHeight | rounding }}
				</template>
			</el-table-column>
			<!-- <el-table-column prop="WindWaveHeight" label="Wave Height(m)" min-width="180" align="center"
				show-overflow-tooltip>
				<template slot-scope="scope">
					{{ scope.row.WindWaveHeight | rounding }}
				</template>
			</el-table-column> -->
			<el-table-column prop="SwellHeight" label="Swell Height" align="center" min-width="180" show-overflow-tooltip>
				<template slot-scope="scope">
					{{ scope.row.SwellHeight | rounding }}
				</template>
			</el-table-column>
			<el-table-column prop="SwellDir" label="Swell Direction" align="center" min-width="180" show-overflow-tooltip>
				<template slot-scope="scope">
					{{ scope.row.SwellDir | direction }}
				</template>
			</el-table-column>
			<el-table-column prop="CurrentDir" label="Current Direction" min-width="180" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					{{ scope.row.CurrentDir | direction }}
				</template>
			</el-table-column>
		</el-table>
	</div>
	<!-- </VueDragResize> -->
</template>

<script>
import VueDragResize from 'vue-drag-resize';
import mapobj from '@/utils/mapw.js';
import { request, getMasterReport } from '@/api/basic';
import { getToken } from '@/api/cookies';
import * as turf from '@turf/turf';
import { exporExcel } from '@/api/pdf';
import typhoonData from '@/data/typhoon.json';
export default {
	components: {
		VueDragResize,
	},
	directives: {
		focus: {
			inserted: function (el) {
				el.querySelector('input').focus();
			},
		},
	},
	filters: {
		rounding(value) {
			if (value || value === 0) {
				//这个判断是为了防止数据为空，页面渲染为NAN
				return Number(value).toFixed(2);
			}
		},
		direction(value) {
			if (value || value === 0) {
				return mapobj.get_compass_pos(value);
			}
		},
	},
	props: {
		rightWidth: {
			type: Number,
			default: 982.25,
		},
		modelType: {
			type: Object,
			default: () => {
				return {
					left: true,
					right: true,
				};
			},
		},
		selectList: {
			type: Array,
			default: () => [],
		},
		simulationData: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		weatherMaster: () => import('./components/weather-master.vue'),
		typhoonChart: () => import('@/components/BottomContent/components/typhoon-chart.vue'),
	},
	data() {
		return {
			typhoonDataList: [],
			typhoonChart: false,
			weatherMaster: false,
			masterReportData: [],
			weatherMasterData: [],
			editIndex: 1,
			selecLineData: [],
			dialogVisible: false,
			height: 0,
			top: 0,
			isDragActive: false,
			legTypeOptions: [
				{
					label: 'GC',
					value: 'GC',
				},
				{
					label: 'RL',
					value: 'RL',
				},
			],
			fieldList: [
				{
					label: 'Name',
					key: 'routePlan',
					value: '',
				},
				{
					label: 'DTG',
					key: 'TotalVoyage', // 对应字段名 总距离
					value: '',
				},
				{
					label: 'TTG',
					key: 'Durations', // 对应字段名 总时间
					value: '',
				},
				{
					label: 'STW/SOG',
					key: 'AvgSTW',
					value: '',
				},
				{
					label: 'HSFO/LSFO/MDO/MGO',
					key: 'FuelConsumption',
					value: '',
				},
				{
					label: 'WF/CF', // wf * 上个经纬度点到这个经纬度的时间（每一个算出来加起来除以总时间）
					key: 'WFCF',
					value: '',
				},
				{
					label: 'ETD',
					key: 'ETD',
					value: '',
				},
				{
					label: 'ETA',
					key: 'ETA',
					value: '',
				},
			],
			tableData: [],
			atcLinenum: '',
			changeIndex: false,
			isChangeType: false,
			actRouteId: null, // 选择图层id
			editAble: false,
			tableHeight: 260,
			masterTimeList: [],
		};
	},

	watch: {
		tableData: {
			handler(val) {
				console.log(val, 'tableData');
				if (val) {
					setTimeout(() => {
						this.$bus.$emit('changeBottonTable', {
							routeId: this.actRouteId,
							atcLinenum: this.atcLinenum,
							value: val,
							index: this.changeIndex,
							isChangeType: this.isChangeType,
						});
						this.setfieldList();
					}, 50);
				}
			},
		},
	},

	computed: {
		getMaxHeight() {
			return document.body.clientHeight - 190;
		},
	},

	mounted() {
		console.log(typhoonData);
		// 编辑图层事件
		mapobj.addEventListern('drawEdit', (e) => {
			this.getTableData(e);
		});
		// 点击图层事件
		mapobj.addEventListern('layerClick', (e) => {
			this.getTableData(e);
		});
		// 修改路线名称
		this.$bus.$off('changeRoutePlan');
		this.$bus.$on('changeRoutePlan', (data) => {
			this.setfieldList();
		});
		// 右侧表格点击详情

		this.$bus.$off('infoClick');
		this.$bus.$on('infoClick', (data) => {
			console.log('🚀 ~ this.$bus.$on ~ data:', data);
			this.getTableData(data);
		});
	},
	methods: {
		exporExcelData() {
			let data = mapobj.routePlanTableData[this.selectList[this.atcLinenum]].Summary;
			let portList = this.$parent.$refs.rightContent.$refs.tabpaneBasic3Ref.portList;
			let name = data.routePlan;
			if (portList && portList.length) {
				portList.forEach((element, index) => {
					if (index == 0) {
						name += '  ';
						name += element.portName;
					} else if (index == portList.length - 1) {
						name += '  ';
						name += element.portName;
					}
				});
			}
			let array = [];
			this.tableData.forEach((element) => {
				array.push({
					LON: mapobj.ChangeToDFM(element.Lon, 1),
					LAT: mapobj.ChangeToDFM(element.Lat, 0),
					NAV: element.LegType,
				});
			});
			exporExcel({
				name: name,
				data: array,
			}).then((res) => {
				if (res) {
					this.$message.success('导出成功');
					let url = window.URL.createObjectURL(res);
					let a = document.createElement('a');
					document.body.appendChild(a);
					a.href = url;
					a.download = name;
					a.click();
				}
			});
		},
		/**
		 * 处理鼠标按下事件
		 *
		 * @param {MouseEvent} 鼠标事件
		 */
		handleMouseDown(event) {
			// 禁止用户选择网页中文字
			document.onselectstart = () => false;
			// 禁止用户拖动元素
			document.ondragstart = () => false;

			// 保存鼠标最后移动的位置（Y轴）
			this.dragState = {
				// 鼠标开始移动的位置（Y轴）
				startMouseTop: event.clientY,
				// 鼠标最后移动的位置（Y轴）
				endMouseTop: event.clientY,
			};

			// 绑定鼠标移动事件
			document.addEventListener('mousemove', this.handleMouseMove);
			// 绑定鼠标放开事件
			document.addEventListener('mouseup', this.handleMouseUp);
		},

		/**
		 * 处理鼠标移动事件
		 *
		 * @param {MouseEvent} 鼠标事件
		 */
		handleMouseMove(event) {
			const TextAreaWrap = {
				MaxHeight: document.body.clientHeight - 100,
				MinHeight: 150,
			};
			const { rWrap, rResizeLine } = this.$refs;
			// 获取鼠标最后移动的位置（Y轴）
			const { endMouseTop } = this.dragState;
			// 获取当前的文本框高度
			const oldTextAreaHeight = rWrap.getBoundingClientRect().height;
			// 新的文本框高度
			let newTextAreaHeight = 0;

			// 计算鼠标移动的距离
			const distance = Math.abs(parseInt(((endMouseTop - event.clientY) * 100).toString(), 10) / 100);

			// 若鼠标向上移动
			if (endMouseTop > event.clientY) {
				// 发送框高度达到最大
				if (oldTextAreaHeight >= TextAreaWrap.MaxHeight) {
					// 修改光标为可被向下移动
					rResizeLine.style.cursor = 's-resize';
					return false;
				}

				// 计算新的发送框高度
				newTextAreaHeight = oldTextAreaHeight + distance;
			}
			// 若鼠标向下移动
			else {
				// 发送框高度达到最小
				if (oldTextAreaHeight <= TextAreaWrap.MinHeight) {
					// 修改光标为可被向上移动
					rResizeLine.style.cursor = 'n-resize';
					return false;
				}

				// 计算新的发送框高度
				newTextAreaHeight = oldTextAreaHeight - distance;
			}

			// 兼容鼠标快速拖动的情况：新的发送框高度不能超过最大值
			if (newTextAreaHeight > TextAreaWrap.MaxHeight) {
				newTextAreaHeight = TextAreaWrap.MaxHeight;
			}

			// 兼容鼠标快速拖动的情况：新的发送框高度不能小于最小值
			if (newTextAreaHeight < TextAreaWrap.MinHeight) {
				newTextAreaHeight = TextAreaWrap.MinHeight;
			}

			// 修改发送框高度
			rWrap.style.height = newTextAreaHeight + 'px';
			// 修改光标为可移动
			rResizeLine.style.cursor = 'move';

			// 更新鼠标最后移动的位置（Y轴）
			this.dragState.endMouseTop = event.clientY;

			this.tableHeight = document.getElementsByClassName('drag-bottom')[0].clientHeight - 100;
		},

		/**
		 * 处理鼠标放开事件
		 */
		handleMouseUp() {
			// 移除鼠标移动事件
			document.removeEventListener('mousemove', this.handleMouseMove);
			// 移除鼠标放开事件
			document.removeEventListener('mouseup', this.handleMouseUp);
			// 允许用户选择网页中文字
			document.onselectstart = null;
			// 允许用户拖动元素
			document.ondragstart = null;
		},

		tableRowClassName({ row, rowIndex }) {
			if (row) {
				row.index = rowIndex;
			}
			if (rowIndex === this.editIndex) {
				return 'edit-row';
			}
			return '';
		},
		selectRow(row) {
			// 给操作行 高亮
			this.editIndex = row.index;
			if (row?.isScrollTop) {
				const theTableRows = this.$refs.bottomTable.bodyWrapper.querySelectorAll(
					'.el-table__body tbody .el-table__row',
				);
				let scrollTop = 0;
				for (let i = 0; i < theTableRows.length; i++) {
					if (i === row.index) {
						break;
					}
					scrollTop += theTableRows[i].offsetHeight;
				}
				this.$refs.bottomTable.bodyWrapper.scrollTop = scrollTop;
			} else {
				const targetLatLng = L.latLng(row.Lat, row.Lon); // 给定的经纬度
				mapobj.Lmap.setView(targetLatLng, 10); // 设置目标经纬度和缩放级别
			}
			mapobj.createRectangle(row.Lat, row.Lon);
		},
		// 修改头部数据
		setfieldList() {
			if (!mapobj.routePlanTableData || !mapobj.routePlanTableData.length) {
				return;
			}
			//console.log("atcLinenum",this.atcLinenum);
			// let lineNum = this.selectList.indexOf(this.atcLinenum);
			let data = mapobj.routePlanTableData[this.selectList[this.atcLinenum]].Summary;
			this.fieldList.map((e) => {
				if (data[e.key]) {
					e.value = data[e.key];
				}
				if (e.key == 'TotalVoyage' || e.key == 'Durations') {
					if (data[e.key]) {
						e.value = parseFloat(data[e.key]).toFixed(2);
					}
				}
				if (e.key == 'WFCF') {
					let value = '';
					if (data.WF) {
						value += data.WF.toFixed(2) + '/';
					} else {
						value += '-/';
					}
					if (data.CF) {
						value += data.CF.toFixed(2);
					} else {
						value += '-';
					}
					e.value = value;
				}
				if (e.key === 'AllTime') {
					const date1 = new Date(data.ETA);
					const date2 = new Date(data.ETD);
					// 计算时间差（毫秒）
					const timeDiff = Math.abs(date1 - date2);
					// 将时间差转换为小时
					e.value = Math.floor(timeDiff / (1000 * 60 * 60));
				}
				return e;
			});
		},
		// 更改表格数据
		changeTableData(isChangeType = false, index) {
			if ((isChangeType && isChangeType == 'ETD') || isChangeType == 'Speed') {
				this.changeIndex = index;
				this.isChangeType = isChangeType;
			}
			mapobj.pointData.features = mapobj.pointData.features.map((obj) => {
				if (obj.properties.linenum === this.atcLinenum) {
					let pro = this.tableData.find((e) => {
						return e.WPT_Name == obj.properties.WPT_Name;
					});
					let isTrue = false;
					if (pro.LatLon.includes('/')) {
						let splitArr = pro.LatLon?.split('/');
						// //console.log('=========>', mapobj.ChangeToDu(splitArr[0]));
						// //console.log('=========>', mapobj.ChangeToDu(splitArr[1]));
						let lat = mapobj.ChangeToDu(splitArr[0]);
						let lon = mapobj.ChangeToDu(splitArr[1]);
						//lon = lon < 0 ? lon + 360 : lon;
						if (lon && lat) {
							obj.geometry.coordinates[0] = lon;
							obj.geometry.coordinates[1] = lat;
							pro.Lon = lon;
							pro.Lat = lat;
							isTrue = true;
						}
					}
					// pro.Lon = Number(pro.Lon);
					// pro.Lat = Number(pro.Lat);
					// // 更新经纬度
					// obj.geometry.coordinates[0] = pro.Lon;
					// obj.geometry.coordinates[1] = pro.Lat;
					obj.properties = {
						...obj.properties,
						...pro,
					};
				}
				return obj;
			});
			// return
			// 修改大圆航线
			//if (isChangeType && ['LegType', 'Lat', 'Lon', 'LatLon',"ETD"].includes(isChangeType)) {
			mapobj.resolvePointData(mapobj.pointData.features);
			mapobj.addPointLayer(mapobj.pointData);
			mapobj.circleLineData(mapobj.pointData);
			mapobj.createEditLine(this.atcLinenum);
			//}
		},
		// 切换input框的显示状态
		switchShow(index, tag) {
			// 为避免el-select blur事件和change事件冲突，设定blur事件延迟执行
			setTimeout(() => {
				// 切换
				this.$set(this.tableData[index], tag, false);
			}, 100);
			// this.tableData[index][tag] = !this.tableData[index][tag];
			// let data = JSON.parse(JSON.stringify(this.tableData));
			// this.tableData = [];
			// data.forEach((e) => {
			// 	this.tableData.push(e);
			// });
		},
		// 显示input框, 使光标焦点当前input框
		changeEnddate(index, tag, rowdata) {
			if (!this.editAble) {
				return;
			}
			setTimeout(() => {
				// 切换
				this.$set(this.tableData[index], tag, true);
			}, 100);
			// 关闭之前所有输入框
			// this.tableData.forEach((e) => {
			// 	for (let key in e) {
			// 		if (key.includes('edit')) {
			// 			e[key] = false;
			// 		}
			// 	}
			// });
			//this.switchShow(index, tag);
			// this.$nextTick(() => {
			// 	let ref = this.$refs['enddateinput' + tag + '&' + index];
			// 	//console.log('ref', ref, ref.focus);
			// 	ref.focus();
			// });
			// setTimeout(() => {
			// 	//定时器是为了避免没有获取到dom的情况报错，所以象征性的给1毫秒让他缓冲
			// 	let ref = this.$refs['enddateinput' + tag + '&' + index];
			// 	//console.log('ref', ref, ref.focus);
			// 	ref.focus();
			// }, 2000);
		},
		getTableData(item) {
			this.actRouteId = item.routeId;
			// 如果有pdf则不能编辑
			this.editAble = item.pdf ? false : true;
			this.$emit('show');
			this.atcLinenum = item.linenum;
			this.tableData = [];
			let linenum = item.linenum;
			let resultArray = mapobj.pointData.features.filter((item) => {
				return item.properties.linenum === linenum;
			});
			let arr = [];
			resultArray.forEach((item) => {
				item.properties.Lat = item.geometry.coordinates[1];
				item.properties.Lon = item.geometry.coordinates[0];
				item.properties.editLegType = false;
				item.properties.editLatLon = false;
				item.properties.editETD = false;
				item.properties.editStayTime = false;
				item.properties.editSTW = false;
				arr.push(item.properties);
			});
			this.tableData = JSON.parse(JSON.stringify(arr));
			this.$nextTick(() => {
				this.getTop();
			});
			this.$bus.$emit('changeBottonTable', {
				routeId: this.actRouteId,
				atcLinenum: this.atcLinenum,
				value: this.tableData,
				index: this.changeIndex,
				isChangeType: this.isChangeType,
			});
			setTimeout(() => {
				this.setfieldList();
			}, 100);
		},
		async showWeatherMaster() {
			// return;
			let speed = this.fieldList.find((item) => {
				return item.key == 'AvgSTW';
			});
			let WPTList = JSON.parse(JSON.stringify(this.tableData));
			let weatherPoint = mapobj.getWeatherPointInfo(WPTList, speed.value);
			// 天气数据
      let item1 = await mapobj.getWeatherInfoByPromise(weatherPoint);
			if (item1 && item1.length) {
				let speed = this.fieldList.find((item) => {
					return item.key == 'AvgSTW';
				});
				if (speed) {
					speed = parseFloat(speed.value);
				}
				let routeForecast = [];
				let cfList = [];
				for (let index = 0; index < item1.length; index++) {
					const element = item1[index];
					let json = {
						currentDir: element.currentDir,
						currentFactor: '',
						currentSpeed: parseFloat(element.currentSpeed)?.toFixed(2),
						dateTime: mapobj.formatDateTime(element.paramTime),
						position: mapobj.ChangeToDFM(element['lat'], 0) + '/' + mapobj.ChangeToDFM(element['lng'], 1),
						seaHt: '',
						sigDir: '',
						sigHgt: parseFloat(element.sigWaveHeight)?.toFixed(2),
						sigPrd: '',
						swellDir: element.swellDir,
						swellHt: parseFloat(element.swellHeight)?.toFixed(2),
						swellPrd: '',
						windDir: element.windDir,
						windSpeed: parseFloat(element.windSpeed)?.toFixed(2),
					};
					let course = 0;
					if (index < item1.length - 1) {
						const nextWpt = item1[index + 1];
						var from = turf.point([element.lng, element.lat]);
						var to = turf.point([nextWpt.lng, nextWpt.lat]);
						course = turf.bearing(from, to);
					}
					let json1 = {
						index: index,
						currentSpeed: element.currentSpeed,
						currentDir: element.currentDir,
						windSpeed: element.windSpeed,
						windDir: element.windDir,
						windWaveDir: element.windWaveDirection,
						sigWaveHeight: element.sigWaveHeight,
						swellDir: element.swellDir,
						windWaveHeight: element.windWaveHeight,
						swellHeight: element.swellHeight,
						cog: course,
						vesselSpeed: speed,
					};
					cfList.push(json1);
					routeForecast.push(json);
				}
				// 求cf
				let res = await request({
					url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/monitoring/report/cf-wf',
					method: 'POST',
					body: cfList,
					header: {
						Authorization: 'Bearer ' + getToken(),
					},
				});
				if (res && res.data && res.data.length) {
					for (let index = 0; index < res.data.length; index++) {
						const element = res.data[index];
						routeForecast[index] = {
							...routeForecast[index],
							cf: element.cf.toFixed(2),
							wf: element.wf.toFixed(2),
						};
					}
					this.weatherMasterData = routeForecast;
					// 报文
					this.masterReportData = [];
					if (this.simulationData) {
						let data = this.simulationData.cpData.filter((item) => item.ld === 'Laden'); // TabpaneSimulation2需要修改为Laden
						let res = await getMasterReport({
							id: data[0].parentId,
							type: 'laden',
						});
						if (res) {
							this.masterReportData = res;
						}
					}
					let data = mapobj.routePlanTableData[this.selectList[this.atcLinenum]].Summary;
					let starTime = data.ATD || data.ETD;
					let endTime = data.ETA;
					this.masterTimeList = return3Hours(starTime, endTime);
					function return3Hours(starTime, endTime) {
						const timeArray = [starTime];
						const start = new Date(starTime);
						const end = new Date(endTime);

						while (start < end) {
							timeArray.push(mapobj.formatDateTime(new Date(start)));
							start.setHours(start.getHours() + 3);
						}
						timeArray.push(endTime);
						return timeArray;
					}
					this.weatherMaster = true;
				}
			} else {
				this.$message.info('Failed to retrieve meteorological data!');
			}
			// });
		},
		showTyphoonWeather() {
			this.typhoonChart = true;
			let speed = this.fieldList.find((item) => {
				return item.key == 'AvgSTW';
			});
			let WPTList = JSON.parse(JSON.stringify(this.tableData));
			let weatherPoint = mapobj.getWeatherPointInfo(WPTList, speed.value);
			let trackInfo = [];
			typhoonData.tropInfo.forEach((element) => {
				console.log(element.trackInfolist);
				if (element.tropID == '2023084NIBB') {
					if (element.forecastInfolist && element.forecastInfolist && element.forecastInfolist.forecastInfo.length) {
						element.forecastInfolist.forecastInfo.forEach((ele) => {
							trackInfo.push(ele);
						});
					}
					if (element.trackInfolist && element.trackInfolist && element.trackInfolist.trackInfo.length) {
						element.trackInfolist.trackInfo.forEach((ele) => {
							trackInfo.push(ele);
						});
					}
					if (element.analysisInfolist && element.analysisInfolist && element.analysisInfolist.analysisInfo.length) {
						element.analysisInfolist.analysisInfo.forEach((ele) => {
							trackInfo.push(ele);
						});
					}
				}
			});
			trackInfo = trackInfo.sort((a, b) => {
				return new Date(a.validTime).getTime() - new Date(b.validTime).getTime();
			});
			this.typhoonDataList = [];
			weatherPoint.forEach((element) => {
				let datePoint = trackInfo.reduce(function (prev, cur) {
					const prevDiff = Math.abs(new Date(element.dataTime).getTime() - new Date(prev.validTime).getTime());
					const currDiff = Math.abs(new Date(element.dataTime).getTime() - new Date(cur.validTime).getTime());
					return currDiff < prevDiff ? cur : prev;
				});
				if (datePoint.point) {
					var from = turf.point([datePoint.point.lon, datePoint.point.lat]);
					var to = turf.point([element.lng, element.lat]);
					var distance = turf.distance(from, to) * 0.539957;
					this.typhoonDataList.push({
						distance: distance.toFixed(2),
						time: mapobj.formatDateTime(element.dataTime, false, true),
					});
				}
			});
			console.log(this.typhoonDataList);
		},
		// 气象
		showWeather() {
			this.dialogVisible = true;
			let speed = this.fieldList.find((item) => {
				return item.key == 'AvgSTW';
			});
			let WPTList = JSON.parse(JSON.stringify(this.tableData));
			let weatherPoint = mapobj.getWeatherPointInfo(WPTList, speed.value);
			setTimeout(async () => {
        let item1 = await mapobj.getWeatherInfoByPromise(weatherPoint);
        if (item1 && item1.length) {
						let speed = this.fieldList.find((item) => {
							return item.key == 'AvgSTW';
						});
						if (speed) {
							speed = parseFloat(speed.value);
						}
						let routeForecast = [];
						let cfList = [];
						for (let index = 0; index < item1.length; index++) {
							const element = item1[index];
							let json = {
								currentDir: mapobj.get_compass_pos(element.currentDir),
								currentFactor: '',
								currentSpeed: parseFloat(element.currentSpeed)?.toFixed(2),
								dateTime: mapobj.formatDateTime(element.paramTime),
								position: mapobj.ChangeToDFM(element['lat'], 0) + '/' + mapobj.ChangeToDFM(element['lng'], 1),
								seaHt: '',
								sigDir: '',
								sigHgt: parseFloat(element.sigWaveHeight)?.toFixed(2),
								sigPrd: '',
								swellDir: mapobj.get_compass_pos(element.swellDir),
								swellHt: parseFloat(element.swellHeight)?.toFixed(2),
								swellPrd: '',
								windDir: mapobj.get_compass_pos(element.windDir),
								windSpeed: parseFloat(element.windSpeed)?.toFixed(2),
							};
							let course = 0;
							if (index < item1.length - 1) {
								const nextWpt = item1[index + 1];
								var from = turf.point([element.lng, element.lat]);
								var to = turf.point([nextWpt.lng, nextWpt.lat]);
								course = turf.bearing(from, to);
							}

							let json1 = {
								index: index,
								currentSpeed: element.currentSpeed,
								currentDir: element.currentDir,
								windSpeed: element.windSpeed,
								windDir: element.windDir,
								sigWaveHeight: element.sigWaveHeight,
								windWaveDir: element.windWaveDirection,
								swellDir: element.swellDir,
								windWaveHeight: element.windWaveHeight,
								swellHeight: element.swellHeight,
								cog: course,
								vesselSpeed: speed,
							};
							cfList.push(json1);
							routeForecast.push(json);
						}
						request({
							url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/monitoring/report/cf-wf',
							method: 'POST',
							body: cfList,
							header: {
								Authorization: 'Bearer ' + getToken(),
							},
						}).then((res) => {
							if (res && res.data && res.data.length) {
								for (let index = 0; index < res.data.length; index++) {
									const element = res.data[index];
									routeForecast[index] = {
										...routeForecast[index],
										cf: element.cf.toFixed(2),
										wf: element.wf.toFixed(2),
									};
								}
								this.selecLineData = routeForecast;
								console.log(1111111111, this.selecLineData);
							}
						});
					}
			}, 100);
		},
		handleSelectionChange() {},
		// 退出编辑
		exitEdit() {
			if (mapobj.editLineLayer) {
				mapobj.editLineLayer.pm.disable();
				mapobj.editLineLayerStepList = [];
				mapobj.editLineLayer.clearLayers();
			}
		},
		closeBottom() {
			this.editIndex = -1;
			this.exitEdit();
			this.$emit('close');
		},
		getTop() {
			let windowHeight = document.documentElement.clientHeight;
			let dom1Height = document.getElementsByClassName('bottom-content')[0].clientHeight;
			this.top = windowHeight - dom1Height;
			this.height = dom1Height;
		},
	},
};
</script>
<style lang="scss" scoped>
.bottom-content {
	z-index: 9;
	position: absolute;
	bottom: 0;
	left: 379px;
	width: calc(100vw - 379px - 739px);
	background: #eff1f5;
	height: 350px;
	min-width: calc(100vw - 379px - 739px);
	.title {
		// cursor: move;
		display: flex;
		align-items: center;
		background: #eff1f5;
		border-bottom: 1px solid #d2dae4;
		padding: 10px 15px;
		position: relative;

		.resize-line {
			height: 6px;
			width: 40px;
			background: rgba(0, 0, 0, 0.2);
			cursor: move;
			background: #ccc;
			position: absolute;
			top: 3px;
			left: 50%;
			border-radius: 5px;
		}

		.field-list {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.field {
				font-size: 12px;
				margin-right: 30px;
				line-height: 22px;
				font-weight: bold;
			}
		}

		.btn-list {
			display: flex;
			align-items: center;

			img {
				cursor: pointer;
			}
		}
	}

	::v-deep .el-table {
		width: 100%;
		border-radius: 6px;
		.el-table__header {
			.table-header {
				color: $defaultFontColor;
				font-weight: bold;

				// th {
				// 	background: #eff1f5;
				// }
			}
		}
		.edit-row {
			background: rgb(203, 234, 234) !important;
		}
		.el-table__row {
			.el-form-item {
				margin-bottom: 0;
				color: $defaultFontColor;

				.lng-lat-input {
					width: 240px !important;
				}
			}

			.el-button {
				color: #d9d9d9;
			}
		}
	}
}

.left {
	width: calc(100vw - 739px);
	left: 0;
}

.right {
	width: calc(100vw - 379px);
	left: 379px;
}

.left-right {
	width: 100vw;
	left: 0;
}
</style>

import * as turf from '@turf/turf'
import mapobj from '@/utils/mapw.js';
export let pointToLine = (point, line, returnLine) => {
    console.log("🚀 ~ pointToLine ~ point, line:", line)
    let lineArray = [], minDistanceLine
    let minDistance
    if (line.geometry.type === 'MultiLineString') {
        line.geometry.coordinates.forEach(element => {
            if (element.length > 1) {
                lineArray.push(turf.lineString(element))
            }
        });
    } else {
        lineArray = (turf.lineString(line.geometry.coordinates))
    }
    var pt = turf.point(point);
    lineArray.forEach(e => {
        let distance = turf.pointToLineDistance(pt, e) * 0.539957;
        if (!minDistance || minDistance > distance) {
            minDistance = distance
            minDistanceLine = e
        }
    })
    if (returnLine) {
        return minDistanceLine
    }
    return minDistance
}
/**
 * 
 * @param {*} inputTime 输入时间
 * @param {*} array ais点位
 * @param {*} line ais线
 * @returns 
 */
export let getPointListByTime = (inputTime, array, line) => {
    let data = array || mapobj.vesselTrackData.trackPoints
    if (!data) {
        return null
    }
    // 初始化前一时间和后一时间的占位符
    let previousTime = null;
    let nextTime = null;
    // 遍历数组查找最接近的前一时间和后一时间
    data.forEach(item => {
        const itemTime = item.time;
        if (itemTime < inputTime) {
            if (!previousTime || itemTime > previousTime.time) {
                previousTime = item;
            }
        } else if (itemTime > inputTime) {
            if (!nextTime || itemTime < nextTime.time) {
                nextTime = item;
            }
        }
    });

    if (previousTime && nextTime) {
        let start = turf.point([previousTime.lon, previousTime.lat]);
        let stop = turf.point([nextTime.lon, nextTime.lat]);
        let inputLine = pointToLine([previousTime.lon, previousTime.lat], line, true)
        let timeRatio = (inputTime - previousTime.time) / (nextTime.time - previousTime.time);
        return lineSliceByPoint({start, stop, inputLine}, timeRatio, '')
    }else{
        return null
    }
}
export let lineSliceByPoint = ({start, stop, inputLine}, timeRatio) => {
    // 裁切
    var sliced = turf.lineSlice(start, stop, inputLine);
    var length = turf.length(sliced, {units: 'miles'});
    var sliced = turf.lineSliceAlong(sliced, 0, length * timeRatio, {units: 'miles'});
    let coordinates = sliced.geometry.coordinates[sliced.geometry.coordinates.length - 1]
    return [mapobj.ChangeToDFM(coordinates[0], 1), mapobj.ChangeToDFM(coordinates[1], 0)]
}

export let getDistanceSlrByTime = ({startTime, endTime, starLatLon, endLatLon}, array) => {
    // 给定的时间范围
    let data = array || mapobj.vesselTrackData.trackPoints
    // 筛选和排序数据
    const filteredData = data.filter(entry => entry.time >= startTime && entry.time <= endTime)
                            .sort((a, b) => a.time - b.time);
    let lineArray = []
    lineArray.push(starLatLon)
    if (filteredData && filteredData.length) {
        filteredData.forEach(e =>{
            lineArray.push([e.lon, e.lat])
            // if (startTime === 1709553600000) {
            //     L.marker({
            //         lat: e.lat,
            //         lng: e.lon
            //     }).addTo(mapobj.Lmap);
            // }
        })
    }
    lineArray.push(endLatLon)
    var line = turf.lineString(lineArray);
    var length = turf.length(line) * 0.539957;
    return length
}
<template>
	<div>
		<LeftContent v-show="leftContentShow" @showRightContent="rightContentShow = true"></LeftContent>
		<TopContent :leftContentShow="leftContentShow" ref="topContent"></TopContent>
		<RightContent
			ref="rightContent"
			v-show="rightContentShow"
			@closeRightContent="rightContentShow = false"
			@changeRight="changeRight"
			@changeWidth="changeRightWidth"
		>
		</RightContent>
		<BottomContent
			v-show="bottomContentShow"
			@close="bottomContentShow = false"
			@show="bottomContentShow = true"
			:selectList="selectList"
			ref="bottomContent"
			:model-type="{ left: leftContentShow, right: rightContentShow }"
			:simulationData="simulationData"
			:rightWidth="rightWidth"
		></BottomContent>
		<!-- 下放报文详情 -->
		<report-info v-show="reportInfoShow" @close="reportInfoShow = false" :reportTable="reportTable" ref="reportInfo" />
		<div
			id="windy"
			ref="mapContainer"
			:class="[
				'map-container',
				!leftContentShow ? 'left' : '',
				!rightContentShow ? 'right' : '',
				!leftContentShow && !rightContentShow ? 'left-right' : '',
			]"
			:title="latLon"
		></div>
	</div>
</template>

<script>
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
import mainProts from '@/assets/svg/mainProts.svg';
import mapobj from '@/utils/mapw.js';
import LeftContent from '@/components/LeftContent';
import TopContent from '@/components/TopContent';
import RightContent from '@/components/RightContent';
import BottomContent from '@/components/BottomContent';
import { request } from '@/api/basic';
import './lib/leaflet-1.9.4-src';
import './lib/libBoot';
import './lib/Leaflet.VectorGrid.bundled';
import './lib/leaflet-mapbox-gl';
import './lib/leaflet.rotatedMarker';
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';
import { transform2DM } from '@/tool';
import './css/PolylineMeasure.css';
import * as turf from '@turf/turf';
let mapbox;
// 初始图层——todo
let vesselsLayer = new L.LayerGroup();
let aisLayerGroup = new L.LayerGroup();
let routePlanLayerGroup = new L.LayerGroup();
let routePlanPointsLayerGroup = new L.LayerGroup();
let dailyReportLayerGroup = new L.LayerGroup();
let portsLayerGroup = new L.LayerGroup();

let icon = L.icon({
	iconUrl: require('../../assets/img/redPoint.png'), // 图标文件路径
	iconSize: [40, 40], // 图标尺寸
	iconAnchor: [20, 20], // 图标偏移位置
});
export default {
	components: {
		LeftContent,
		TopContent,
		RightContent,
		BottomContent,
		ReportInfo: () => import('@/components/BottomContent/report-info'),
	},
	data() {
		return {
			latLon: '',
			leftContentShow: true,
			rightContentShow: false,
			core: null,
			bottomContentShow: false,
			selectList: [], // 右侧选择列表集合 路线列表所选列索引
			isGreatCircle: null,
			reportInfoShow: false,
			reportTable: [],
			simulationData: null,
			rightWidth: 739,
		};
	},
	// watch: {
	// 	leftContentShow: {
	// 		handler(val) {
	// 			mapbox._update()
	// 		}
	// 	},
	// 	rightContentShow: {
	// 	}
	// },
	created() {},
	mounted() {
		// 报文详情弹窗数据
		this.$bus.$off('reportTable');
		this.$bus.$on('reportTable', (data) => {
			this.reportTable = JSON.parse(JSON.stringify(data));
		});
		// 报文详情弹窗数据
		this.$bus.$off('reportInfoShow');
		this.$bus.$on('reportInfoShow', (data) => {
			this.reportInfoShow = data;
		});
		delete require.cache[require.resolve('./lib/libBoot')];
		require('./lib/libBoot');
		window.meteoApi(
			{
				verbose: false,
				lat: 32.99,
				lon: 105.78,
				zoom: 1,
				maxZoom: 20,
				preserveDrawingBuffer: true, // 显式设置 preserveDrawingBuffer 为 true
			},
			(core) => {
				this.core = core;
				mapobj.Lmap = core.map;
				core.store.set('product', 'ecmwf');
				mapbox = L.mapboxGL({
					accessToken:
						'pk.eyJ1IjoibGl0dGxlZml2ZTE5OTUiLCJhIjoiY2w1OWRtdTB3MmJqbTNjcXFpcWE4dDIxdSJ9.3ZH5BCZI085b4B9XVflZpg',
					style: 'mapbox://styles/littlefive1995/clpasntyo002t01re90w03axq',
					padding: 0,
					dragPan: true,
					pane: 'mapboxgl-tile-pane',
					paneZindex: 399,
					mouseEvent: true,
					preserveDrawingBuffer: true, // 显式设置 preserveDrawingBuffer 为 true, 必备
					// transformRequest: (url) => {
					// 	if (url.startsWith('https://www.hifleet.com/hifleetapi')) {
					// 		console.log(url);
					// 		return {
					// 			url: url,
					// 			headers: {
					// 				cookie: 'sadasff',
					// 				Cookie:
					// 					'_gcl_au=1.1.764434361.1715256884; Hm_lvt_5a549381614f27b883ebd27bf0e218a0=1715256883,1715331377; JSESSIONID=FCFFE79B7E9B20C51C25A5B69A45508B; HFJSESSIONID=BE090D2DC5C10B23C1871615BF9CBB9C; TGC=TGT-268448-UZUkJ8XifpByR8GzJQ7gYarZW9g3vsaDn-OpeJakf-uALMSTDihYqdlr8GPJZA0vXzEiZ1y6w208fk1crZ; Hm_lpvt_5a549381614f27b883ebd27bf0e218a0=1715343624',
					// 			},
					// 		};
					// 	}
					// },
				}).addTo(core.map);
				vesselsLayer.addTo(mapobj.Lmap);
				// ais历史轨迹图
				mapobj.vesselTrackLayer = new L.LayerGroup();
				mapobj.vesselTrackLayer.addTo(mapobj.Lmap);
				// 创建报文图层组
				mapobj.reportLayer = new L.layerGroup();
				mapobj.reportLayer.addTo(mapobj.Lmap);
				// this.addinitLayer()
				// 获取maobox对象
				mapobj.mapboxMap = mapbox.getMapboxMap();
				mapobj.mapboxMap.on('load', () => {
					// mapobj.mapboxMap.addSource('wmts-test-source', {
					// 	type: 'raster',
					// 	tiles: [
					// 		//'https://static.hifleet.com/autical_chart/osm_cn/osm/{z}/{x}/{y}.png',
					// 		'https://www.hifleet.com/hifleetapi/getNauticalChart.do?z={z}&x={x}&y={y}&_v=5.3.317&service=WMS&request=GetMap&layers=0&styles=&format=image%2Fpng&transparent=true&version=1.3.0&width=256&height=256&crs=EPSG%3A3857',
					// 		// 如 "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg"
					// 	],
					// 	tileSize: 256,
					// });
					// mapobj.mapboxMap.addLayer({
					// 	id: 'wmts-test-layer',
					// 	type: 'raster',
					// 	source: 'wmts-test-source',
					// 	paint: {
					// 		'raster-opacity': 0.9, // 设置透明度
					// 	},
					// });
					mapobj.addBaseLayer();
					// 单独设置symbol-spacing属性
					// mapobj.mapboxMap.on('click', 'load-line-polygon', (e) => {
					// 	const properties = e.features[0].properties;
					// 	console.log('🚀 ~ file: index.vue:129 ~ mapobj.mapboxMap.on ~ properties:', properties)
					// })
					mapobj.mapboxMap.on('click', 'ports', (e) => {
						const properties = e.features[0].properties;
						console.log('🚀 ~ file: index.vue:129 ~ mapobj.mapboxMap.on ~ properties:', properties);
						const portInfoPopup = L.popup({
							closeButton: true,
							offset: [0, 0],
							autoClose: true,
							closeOnClick: false,
						});
						portInfoPopup
							.setContent(
								'<div class="polyline-measure-tooltip leaflet-zoom-animated" tabindex="0" role="button" style="box-shadow: none;margin-left: 4px; margin-top: 4px; width: 12px; height: 12px; z-index: 210; padding: 0;padding-right: 20px;background: none">' +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="width: 80px;text-align: right;color: #C8C8C8;font-size: 12px;font-weight: 400">Position:</div>' +
									'<div class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									`${transform2DM(properties.x)}${properties.x >= 0 ? 'E' : 'W'} ${transform2DM(properties.y)}${
										properties.y >= 0 ? 'S' : 'N'
									}` +
									'</div></div>' +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="width: 80px;text-align: right;color: #C8C8C8;font-size: 12px;font-weight: 400">Country:</div>' +
									'<div>' +
									'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									properties.country +
									'</span>' +
									'</div></div>' +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="width: 80px;text-align: right;color: #C8C8C8;font-size: 12px;font-weight: 400">Port Code:</div>' +
									'<div>' +
									'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									properties.port_code +
									'</span>' +
									'</div></div>' +
									'</div>',
							)
							.setLatLng(e.lngLat)
							.openOn(vesselsLayer);
					});
					mapobj.initSprite();
					//mapobj.initCharsLayer();
					// 获取地图的样式数据
					const styleData = mapobj.mapboxMap.getStyle();
					// 遍历图层数据
					const layers = styleData.layers;
					for (let i = 0; i < layers.length; i++) {
						const layer = layers[i];
						// 在这里处理图层数据
						if (layer.id === 'ports') {
							let layout = layer.layout;
							// 获取图层数据
							var features = mapobj.mapboxMap.querySourceFeatures('composite', {
								sourceLayer: 'ports',
							});
							// Add the sprite resource to the map
							let img = new Image(20, 20);
							img.onload = () => mapobj.mapboxMap.addImage('mainProts', img);
							img.src = mainProts;
							mapobj.initPortsList(features, layout);
							break;
						}
					}
				});
				// // 格网
				// core.map.latlngGraticule = L.latlngGraticule({
				// 	fontColor: 'black',
				// 	dashArray: [5, 10],
				// 	weight: 2,
				// }).addTo(core.map);
				mapobj.addAreaData();
				// // 创建鼠标位置显示控件
				// mapobj.Lmap.on('mousemove', e => {
				// 	this.latLon = mapobj.ChangeToDFM(e.latlng.lat, 0) + '/' + mapobj.ChangeToDFM(e.latlng.lng, 1)
				// });
				// 监听地图的zoomend事件
				mapobj.Lmap.on('zoomend', function () {
					// 重新创建矩形以匹配circleMarker的当前大小
					mapobj.createRectangle();
				});
				mapobj.Lmap.on('click', (e) => {
					var latlng = e.latlng;
					if (mapobj.selectPointType) {
						let data = mapobj.selectPointList.find((e) => e.index === mapobj.selectPointType.index);
						if (data) {
							mapobj.Lmap.removeLayer(data.marker);
							mapobj.selectPointList = mapobj.selectPointList.filter((e) => e.index !== mapobj.selectPointType.index);
						} else {
							data = {
								marker: null,
								...mapobj.selectPointType,
							};
						}
						if (mapobj.selectPointType.type === 'red') {
							data.marker = L.marker(latlng, { icon: icon }).addTo(mapobj.Lmap);
						} else {
							data.marker = L.marker(latlng).addTo(mapobj.Lmap);
						}
						mapobj.selectPointList.push(data);
						//console.log(mapobj.selectPointList);
						this.$bus.$emit('selectPortPoint', latlng);
						mapobj.selectPointType = null;
						var body = document.querySelector('body');
						body.style.cursor = 'auto';
					}
					let to = turf.point([latlng.lng, latlng.lat]);
					this.reportLayerClick(to);
					// 查询属性
					if (mapobj.pointLayer && mapobj.pointData) {
						let minDistance = null;
						let properties;
						mapobj.pointData.features.forEach((e) => {
							let from = turf.point(e.geometry.coordinates);
							var distance = turf.distance(from, to, { units: 'miles' });
							if (!minDistance) {
								minDistance = distance;
								properties = e.properties;
							}
							if (minDistance > distance) {
								minDistance = distance;
								properties = e.properties;
							}
						});
						// 当点击点小于距离20米时触发
						if (minDistance < 20) {
							// 如果已经开启了编辑
							if (properties?.linenum || properties.linenum === 0) {
								mapobj.createEditLine(properties.linenum, properties?.pdf, properties?.routeId);
								let resultArray = mapobj.pointData.features.filter((item) => {
									return item.properties.linenum === properties.linenum;
								});
								let index = resultArray.findIndex(
									(obj) => obj.properties.Lat === properties.Lat && obj.properties.Lon === properties.Lon,
								);
								setTimeout((e) => {
									this.$refs.bottomContent.selectRow({
										index: index,
										Lon: properties.Lon,
										Lat: properties.Lat,
										isScrollTop: true,
									});
								}, 200);
							}
						}
					}
				});
				//监听键盘事件, 当开启绘制时用table切换
				document.addEventListener('keydown', this.popupKeydown, false);
				document.addEventListener('keyup', this.popupKeyup, false);

				core.map.createPane('measure');
				core.map.getPane('measure').style.zIndex = 9999;
				core.map.createPane('measure-line');
				core.map.getPane('measure-line').style.zIndex = 9998;
				this.measureControl = L.control
					.polylineMeasure({
						showBearings: true,
						clearMeasurementsOnStop: true,
						showClearControl: false,
						showUnitControl: false,
						position: 'topright',
					})
					.addTo(mapobj.Lmap);
			},
		);
	},
	methods: {
		// 报文图层查询
		reportLayerClick(to) {
			let minDistance = null;
			let dateUtc;
			// 遍历reportLayer图层中的每个图层对象
			mapobj.reportLayer.eachLayer((layer) => {
				// 在这里对每个图层对象进行操作
				let from = turf.point([layer._latlng.lng, layer._latlng.lat]);
				var distance = turf.distance(from, to, { units: 'miles' });
				if (!minDistance) {
					minDistance = distance;
					dateUtc = layer._icon.innerText;
				}
				if (minDistance > distance) {
					minDistance = distance;
					dateUtc = layer._icon.innerText;
				}
				// 如果需要进一步操作图层对象，可以在这里添加您的代码
			});
			// 当点击点小于距离20米时触发
			if (minDistance && minDistance < 100) {
				this.reportInfoShow = true;
				this.$nextTick((e) => {
					this.$refs.reportInfo.selectRow({
						dateUtc,
						isScrollTop: true,
					});
				});
			}
		},
		// 更改右侧报文列表
		changeRight(data) {
			this.simulationData = data;
		},
		dataURLToBlob(dataurl) {
			let arr = dataurl.split(',');
			let mime = arr[0].match(/:(.*?);/)[1];
			let bstr = atob(arr[1]);
			let n = bstr.length;
			let u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], { type: mime });
		},
		// 修改气象
		changMapState(meteoType, state) {
			let switchType = state ? 'on' : 'off';
			let meteoTypeForControl;
			let switchTypeForControl;
			switch (meteoType) {
				case 'pressure':
					meteoTypeForControl = 'isolines';
					switchTypeForControl = switchType === 'on' ? meteoType : 'off';
					break;
				case 'current':
					meteoTypeForControl = 'particlesAnim';
					switchTypeForControl = switchType;
					'on' === switchType && this.core.store.set('overlay', 'wind');
					break;
				case 'typhoon':
					if (this.core.map.hurricanesLayers) {
						switchType === 'on'
							? this.core.map.hurricanesLayers.forEach((item) => item.addTo(this.core.map))
							: this.core.map.hurricanesLayers.forEach((item) => item.removeFrom(this.core.map));
						meteoTypeForControl = null;
					} else {
						meteoTypeForControl = 'hurricanes';
						switchTypeForControl = switchType;
					}
					break;
				case 'wind':
					meteoTypeForControl = 'discrete';
					switchTypeForControl = switchType;
					break;
				case 'wave':
				case 'swell1':
					meteoTypeForControl = 'isobands';
					switchTypeForControl = switchType === 'on' ? meteoType : 'off';
					break;
			}

			//console.log(meteoTypeForControl, switchTypeForControl);
			if (meteoTypeForControl) {
				this.core.store.set(meteoTypeForControl, switchTypeForControl);
			}
		},
		// 修改状态
		voyageLayerControl(layerIds, status) {
			const visibilityValue = status === true ? 'visible' : 'none';
			layerIds?.forEach?.((layerId) => {
				mapobj.mapboxMap.setLayoutProperty(layerId, 'visibility', visibilityValue);
				// 根据数据源 ID 获取数据源对象
				const source = mapobj.mapboxMap.getSource(layerId);
				// 检查数据源是否存在
				if (source) {
					// 获取图层的数据
					const data = source._data; // 注意：这是 Mapbox GL JS 内部属性，可能会有变化
				}
			});
		},
		switchMeasure(isGreatCircleParam) {
			if (this.measureControl.options.greatCircle === isGreatCircleParam) {
				this.isGreatCircle = null;
				this.measureControl.options.greatCircle = null;
				this.measureControl._toggleMeasure(false);
			} else {
				this.isGreatCircle = isGreatCircleParam;
				this.measureControl.options.greatCircle = isGreatCircleParam;
				if (!this.measureControl._measuring) {
					this.measureControl._toggleMeasure();
				}
			}
		},
		//键盘事件函数
		popupKeydown(e) {
			if (e.code === 'Tab') {
				e.returnValue = false;
			}
		},
		//键盘事件函数
		popupKeyup(e) {
			if (e.code === 'Tab') {
				if (this.isGreatCircle != null) {
					// this.switchMeasure(!this.measureControl.options.greatCircle)
					let name = !this.isGreatCircle ? 'Great Circle' : 'Rhumb Line';
					if (this.$refs.topContent) {
						const index = this.$refs.topContent.statusList.findIndex((item) => item.label === name);
						this.$refs.topContent.statusBtnClick(index, name);
					}
				}
				e.returnValue = false;
			} else if (e.code === 'Escape') {
				if (this.isGreatCircle != null) {
					// this.switchMeasure(this.isGreatCircle)
					let name = this.isGreatCircle ? 'Great Circle' : 'Rhumb Line';
					if (this.$refs.topContent) {
						const index = this.$refs.topContent.statusList.findIndex((item) => item.label === name);
						this.$refs.topContent.statusBtnClick(index, name);
					}
				}
				e.returnValue = false;
			}
			// else if(e.code === "z"){
			// 	mapobj.backEdit()
			// }
		},
		addLayer() {},
		changeRightWidth(width) {
			console.log(width);
			this.rightWidth = width;
		},
	},
};
</script>
<style lang="scss" scoped>
.map-container {
	position: absolute;
	height: 100vh;
	// display: none;
}
.left {
	width: calc(100vw - 739px) !important;
	left: 0 !important;
}
.right {
	width: calc(100vw - 0.1px) !important;
	left: 379px !important;
}
.left-right {
	width: 100vw !important;
	left: 0 !important;
}
</style>

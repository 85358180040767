<template>
	<div class="tabpane">
		<div class="port-list">
			<div :class="['port-item', '']" v-for="(item, index) in portList" :key="item.uuid">
				<div class="port-index">{{ index + 1 }}</div>
				<div class="type">
					<el-select class="type-select" size="mini" v-model="item.type" clearable>
						<el-option v-for="v in typeList" :key="v.name" :value="v.name" :label="v.name"> </el-option>
					</el-select>
				</div>
				<div class="port">
					<el-autocomplete
						class="port-select"
						v-model="item.portName"
						size="mini"
						clearable
						@blur="cancelBlur"
						@focus="selectPoint(index)"
						:fetch-suggestions="getPortNameList"
						placeholder="请输入港口或点击地图获取坐标"
						@select="handleSelect"
					></el-autocomplete>
					<!-- <el-select class="port-select" size="mini" @focus="selectPoint(index)" @blur="cancel"
						@clear="clearPortName(index)" v-model="item.name" allow-create filterable clearable>
						<el-option v-for="v in portNameList" :key="v.port_code" :value="v.port_code" :label="v.port_name">
						</el-option>
					</el-select> -->
				</div>
				<div class="time" v-if="index != portList.length - 1">
					<mydatePicker
						size="mini"
						align="center"
						v-model="item.time"
						type="datetime"
						format="yyyy-MM-dd HH:mm"
						value-format="yyyy-MM-dd HH:mm"
					>
					</mydatePicker>
					<!-- <mydatePicker v-if="item.type === 'Loading'" size="mini" align="center" v-model="item.timeOther"
						type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm">
					</mydatePicker> -->
				</div>
				<!-- <div class="btns">
					<el-button @click="addPortItem(item.type, index)" icon="el-icon-plus" circle size="small"></el-button>
					<el-button @click="removePortItem(index)" icon="el-icon-minus" circle size="small"></el-button>
				</div> -->
			</div>
		</div>
		<el-dialog
			v-dialogDrag
			:modal="false"
			width="960"
			title="生成推荐航线PDF"
			:visible.sync="dialogVisible"
			v-loading="loading"
			:close-on-click-modal="false"
		>
			<div class="dialog-content">
				<div class="map-content">
					<pdf-map ref="pdfMap" />
				</div>
				<!-- <img :src="pdfimage" width="100%" alt="" /> -->
				<h3 style="margin: 20px 0 10px 0">请选择一条主推荐航线</h3>
				<el-table
					size="small"
					ref="multipleLineTable"
					stripe
					border
					class="route-plan-table"
					@selection-change="handleLineSelectionChange"
					:data="selecLineData"
					header-row-class-name="table-header"
				>
					<el-table-column type="selection" align="center" width="55"> </el-table-column>
					<el-table-column label="Route Plan" prop="Summary.routePlan" min-width="90" align="center"> </el-table-column>
					<el-table-column label="Distance" prop="Summary.TotalVoyage" align="center">
						<template slot-scope="scope">
							{{ scope.row.Summary.TotalVoyage | rounding }}
						</template>
					</el-table-column>
					<!-- <el-table-column label="ECA Distance" prop="Summary.ecaDistance" align="center">
						<template slot-scope="scope">
							{{ scope.row.Summary.ecaDistance | rounding }}
						</template>
					</el-table-column> -->
					<el-table-column label="ETA" prop="Summary.ETA" min-width="110" align="center"></el-table-column>
					<el-table-column label="Duration" prop="Summary.Durations" align="center">
						<template slot-scope="scope">
							{{ scope.row.Summary.Durations | rounding }}
						</template>
					</el-table-column>
					<el-table-column label="HSFO/LSFO/MDO/MGO" prop="Summary.FuelConsumption" min-width="180" align="center">
						<template slot-scope="scope">
							{{ scope.row.Summary.FuelConsumption }}
						</template>
					</el-table-column>
					<!-- <el-table-column label="ECA FUEL" prop="ecaFuel" min-width="90" align="center">
						<template slot-scope="scope">
							{{ scope.row.Summary.ecaFuel | rounding }}
						</template>
					</el-table-column> -->
				</el-table>
				<h3 style="margin: 20px 0 10px 0">请选择主航线的转向点</h3>
				<el-table
					:data="wptPointList"
					stripe
					style="width: 100%"
					size="small"
					ref="multiWptPointTable"
					header-row-class-name="table-header"
				>
					<el-table-column type="selection" width="55" align="center"></el-table-column>
					<el-table-column fixed prop="WPT_Name" label="Waypoint Name" width="120" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="LegType" label="Nav. Type" width="120" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="LatLon" label="Lat/Lon" align="center" width="160" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="ETD" label="Date/Time" min-width="180" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="StayTime" label="Stoppage(hrs)" min-width="120" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="STW" label="Speed" min-width="150" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.STW | rounding }}
						</template>
					</el-table-column>
					<el-table-column prop="Distance" label="Distance" min-width="100" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.Distance | rounding }}
						</template>
					</el-table-column>
					<el-table-column prop="DayFuel" label="Daily Fuel(mt)" min-width="120" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.DayFuel | rounding }}
						</template>
					</el-table-column>
					<el-table-column
						prop="AccuFuel"
						label="Remaining BOB(mt)"
						min-width="180"
						align="center"
						show-overflow-tooltip
					>
						<template slot-scope="scope">
							{{ scope.row.AccuFuel | rounding }}
						</template>
					</el-table-column>
					<el-table-column prop="Course" label="Heading" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.Course | rounding }}
						</template>
					</el-table-column>
					<el-table-column
						prop="RemainDistance"
						label="Remaining Distance"
						min-width="180"
						align="center"
						show-overflow-tooltip
					>
						<template slot-scope="scope">
							{{ scope.row.RemainDistance | rounding }}
						</template>
					</el-table-column>
					<el-table-column prop="Durations" label="Duration(hrs)" min-width="120" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.Durations | rounding }}
						</template>
					</el-table-column>
					<el-table-column
						prop="RemainTime"
						label="Remaining Duration(hrs)"
						min-width="200"
						align="center"
						show-overflow-tooltip
					>
						<template slot-scope="scope">
							{{ scope.row.RemainTime | rounding }}
						</template>
					</el-table-column>
					<el-table-column prop="" label="WF(knts)" min-width="100" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="" label="CF(knts)" min-width="100" align="center" show-overflow-tooltip>
					</el-table-column>
					<el-table-column
						prop="WindSpeed"
						label="Wind Speed(knts)"
						min-width="180"
						align="center"
						show-overflow-tooltip
					>
						<template slot-scope="scope">
							{{ scope.row.WindSpeed | rounding }}
						</template>
					</el-table-column>
					<el-table-column prop="WindDir" label="Wind direction" min-width="180" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.WindDir | rounding }}
						</template>
					</el-table-column>
					<el-table-column
						prop="SigWaveHeight"
						label="Sig.Wave Height(m)"
						min-width="180"
						align="center"
						show-overflow-tooltip
					>
						<template slot-scope="scope">
							{{ scope.row.SigWaveHeight | rounding }}
						</template>
					</el-table-column>
					<el-table-column
						prop="WindWaveHeight"
						label="Wave Height(m)"
						min-width="180"
						align="center"
						show-overflow-tooltip
					>
						<template slot-scope="scope">
							{{ scope.row.WindWaveHeight | rounding }}
						</template>
					</el-table-column>
					<el-table-column
						prop="CurrentDir"
						label="Current Direction"
						min-width="180"
						align="center"
						show-overflow-tooltip
					>
						<template slot-scope="scope">
							{{ scope.row.CurrentDir | rounding }}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="downSimulationData">确 定</el-button>
			</span>
		</el-dialog>
		<el-divider></el-divider>

		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label-bold">· CP Performance</div>
			</el-col>
		</el-row>

		<el-row :gutter="20" class="text-row-class">
			<el-form :model="tableForm" ref="form" size="small" class="table-form">
				<el-table
					:data="tableForm.tableData"
					border
					size="small"
					header-row-class-name="table-header"
					@selection-change="handleCpSelectionChange"
					ref="singleCheckTable"
				>
					<el-table-column type="selection" width="55" align="center"> </el-table-column>
					<el-table-column label="L/D" prop="ld" align="center"></el-table-column>
					<el-table-column label="Speed" prop="speed" align="center" width="100">
						<!-- <template v-slot="scope">
							<el-form-item :prop="'tableData.' + scope.$index + '.' + 'speed'">
								<el-input disabled v-model="scope.row.speed" placeholder="knot"> </el-input>
							</el-form-item>
						</template> -->
					</el-table-column>
					<el-table-column label="HSFO" prop="hsfo" align="center" width="100">
						<!-- <template v-slot="scope">
							<el-form-item :prop="'tableData.' + scope.$index + '.' + 'hsfo'">
								<el-input disabled v-model="scope.row.hsfo" placeholder="MT/Day"> </el-input>
							</el-form-item>
						</template> -->
					</el-table-column>
					<el-table-column label="LSFO" prop="lsfo" width="100" align="center">
						<!-- <template v-slot="scope">
							<el-form-item :prop="'tableData.' + scope.$index + '.' + 'lsfo'">
								<el-input disabled v-model="scope.row.lsfo" placeholder="MT/Day"> </el-input>
							</el-form-item>
						</template> -->
					</el-table-column>
					<el-table-column label="MDO" prop="mdo" width="100" align="center">
						<!-- <template v-slot="scope">
							<el-form-item :prop="'tableData.' + scope.$index + '.' + 'mdo'">
								<el-input disabled v-model="scope.row.mdo" placeholder="MT/Day"> </el-input>
							</el-form-item>
						</template> -->
					</el-table-column>
					<el-table-column label="MGO" prop="mgo" width="100" align="center">
						<!-- <template v-slot="scope">
							<el-form-item :prop="'tableData.' + scope.$index + '.' + 'mgo'">
								<el-input disabled v-model="scope.row.mgo" placeholder="MT/Day"> </el-input>
							</el-form-item>
						</template> -->
					</el-table-column>
					<!-- <el-table-column label="" align="center" width="100">
						<template v-slot="scope">
							<el-button @click="addCpPerformance" icon="el-icon-plus" circle size="small"></el-button>
							<el-button @click="removeCpPerformance(scope.row)" icon="el-icon-minus" circle
								size="small"></el-button>
						</template>
					</el-table-column> -->
				</el-table>
			</el-form>
		</el-row>

		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label-bold">· Route Plan</div>
			</el-col>
			<el-col :span="16">
				<el-popover placement="left" title="Collect List" width="450" trigger="manual" v-model="collectListShow">
					<button class="voyage-btn" slot="reference" @click="showCollectList">Collect List</button>
					<div class="Collect List">
						<button class="voyage-btn" slot="reference" @click="delCollectList">Del</button>
						<button class="voyage-btn" slot="reference" @click="addCollectList">Show</button>
						<el-table
							size="small"
							ref="CollectTable"
							stripe
							border
							class="route-plan-table"
							:data="collectLineData"
							header-row-class-name="table-header"
						>
							<el-table-column type="selection" align="center" width="55"> </el-table-column>
							<el-table-column label="Name" prop="name" min-width="90" align="center"> </el-table-column>
							<el-table-column label="Time" prop="time" min-width="90" align="center"> </el-table-column>
							<el-table-column label="Start Port" prop="startName" min-width="90" align="center"> </el-table-column>
							<el-table-column label="End Port" prop="endName" align="center"> </el-table-column>
						</el-table>
					</div>
				</el-popover>
			</el-col>
		</el-row>

		<el-row :gutter="20" class="text-row-class route-plan-table-row">
			<el-table
				size="small"
				ref="multipleTable"
				stripe
				border
				class="route-plan-table"
				:row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange"
				:data="routePlanTableData"
				header-row-class-name="table-header"
			>
				<el-table-column type="selection" align="center" width="55"> </el-table-column>
				<el-table-column label="Name" prop="Summary.routePlan" min-width="90" align="center">
					<template slot-scope="scope">
						<div
							@dblclick="changeEnddate(scope.$index, 'editRoutePlan', scope.row)"
							style="height: 40px; line-height: 40px"
						>
							<span v-if="!scope.row.editRoutePlan">{{ scope.row.Summary.routePlan }}</span>
							<el-input
								:ref="'enddateinput' + 'editRoutePlan' + '&' + scope.$index"
								@change="changeTableData"
								@blur="switchShow(scope.$index, 'editRoutePlan')"
								@keyup.enter.native="$event.target.blur"
								v-show="scope.row.editRoutePlan"
								size="mini"
								v-model="scope.row.Summary.routePlan"
							></el-input>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="Distance" prop="Summary.TotalVoyage" align="center">
					<template slot-scope="scope">
						{{ scope.row.Summary.TotalVoyage | rounding }}
					</template>
				</el-table-column>
				<el-table-column label="PDF Time" prop="Summary.updateTime" min-width="90" align="center">
					<template slot-scope="scope">
						{{ scope.row.Summary.updateTime }}
					</template>
				</el-table-column>
				<el-table-column label="ETD/ATD" prop="Summary.ETD" align="center" min-width="90">
					<template slot-scope="scope">
						<div v-if="scope.row.Summary.ATD">
							{{ scope.row.Summary.ATD }}
						</div>
						<div v-else>
							{{ scope.row.Summary.ETD }}
						</div>
					</template>
				</el-table-column>

				<el-table-column label="ETA" prop="Summary.ETA" min-width="110" align="center"></el-table-column>
				<el-table-column label="Remaining Duration" prop="Summary.Durations" align="center">
					<template slot-scope="scope">
						{{ scope.row.Summary.Durations | rounding }}
					</template>
				</el-table-column>
				<el-table-column label="HSFO/LSFO/MDO/MGO" prop="Summary.FuelConsumption" align="center" min-width="180">
					<template slot-scope="scope">
						{{ scope.row.Summary.FuelConsumption }}
					</template>
				</el-table-column>
				<el-table-column label="PDF" prop="SummarypdfList" min-width="90" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.Summary.pdfList">
							<i
								class="el-icon-tickets"
								v-for="(item, index) in scope.row.Summary.pdfList"
								:key="index"
								@click="downPdfAgain(item)"
								style="font-size: 20px"
							></i>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="ECA FUEL" prop="Summary.ecaFuel" min-width="90" align="center">
					<template slot-scope="scope">
						{{ scope.row.Summary.ecaFuel | rounding }}
					</template>
				</el-table-column> -->
				<el-table-column fixed="right" label="MANAGE" width="180">
					<template slot-scope="scope">
						<el-button @click="editClick(scope.row)" type="text" size="small">Edit</el-button>
						<el-button
							@click="deleteSimulationData(scope.row)"
							v-if="!scope.row.Summary.pdfList"
							type="text"
							size="small"
							>Del</el-button
						>
						<el-button @click="sendClick(scope)" type="text" size="small">Send</el-button>
						<el-button @click="collectSimulationData(scope.row)" type="text" size="small">Collect</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-row>

		<el-divider></el-divider>

		<el-row :gutter="20" class="text-row-class">
			<el-col :span="8">
				<div class="text-label-bold">· Remarks</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="text-row-class">
			<el-input class="remarks" type="textarea" :rows="4" v-model="remarks"></el-input>
		</el-row>
	</div>
</template>

<script>
// import portNameList from '@/utils/port.json';
import { v4 as uuidv4 } from 'uuid';
import { exportpdf, exportpdfAgain, addCollect, findCollect, deleteCollect, findCollectInfo } from '@/api/pdf';
import { routeSave, routeUpdate, getRouteLine, getRouteLineInfo, delRouteLine } from '@/api/basic';
import CryptoJS from 'crypto-js';
import mapobj from '@/utils/mapw.js';
import { request } from '@/api/basic';
import { getToken } from '@/api/cookies';
import * as turf from '@turf/turf';
export default {
	props: {
		simulationData: {
			type: Object,
			default: () => {},
		},
	},
	filters: {
		rounding(value) {
			if (value || value === 0) {
				//这个判断是为了防止数据为空，页面渲染为NAN
				return Number(value).toFixed(2);
			}
		},
	},
	components: {
		PdfMap: () => import('./mapboxMap/index'),
	},
	data() {
		return {
			recommendVal: false,
			collectListShow: false,
			collectLineData: [],
			wptPointList: [],
			loading: false, // 主推荐航线加载
			portNameList: [],
			selecLineData: [],
			dialogVisible: false,
			pdfimage: '',
			portList: [],
			remarks: 'Pls find the following major waypoints for your safe navigation.',
			tableForm: {
				tableData: [
					{
						ld: 'Ballast', //TabpaneSimulation2需要修改为Laden
						speed: '',
						hsfo: '',
						lsfo: '',
						mdo: '',
						mgo: '',
						uuid: uuidv4(),
					},
					{
						ld: 'Ballast', //TabpaneSimulation2需要修改为Laden
						speed: '',
						hsfo: '',
						lsfo: '',
						mdo: '',
						mgo: '',
						uuid: uuidv4(),
					},
				],
			},
			customerId: '',
			routePlanTableData: [
				// {
				// 	routePlan: 'Plan1',
				// 	distance: 'text',
				// 	ecaDistance: 'text',
				// 	eta: 'text',
				// 	duration: 'text',
				// 	fuel: 'text',
				// 	ecaFuel: 'text',
				// },
				// {
				// 	routePlan: 'Plan2',
				// 	distance: 'text',
				// 	ecaDistance: 'text',
				// 	eta: 'text',
				// 	duration: 'text',
				// 	fuel: 'text',
				// 	ecaFuel: 'text',
				// },
				// {
				// 	routePlan: 'Plan3',
				// 	distance: 'text',
				// 	ecaDistance: 'text',
				// 	eta: 'text',
				// 	duration: 'text',
				// 	fuel: 'text',
				// 	ecaFuel: 'text',
				// },
				// {
				// 	routePlan: 'Plan4',
				// 	distance: 'text',
				// 	ecaDistance: 'text',
				// 	eta: 'text',
				// 	duration: 'text',
				// 	fuel: 'text',
				// 	ecaFuel: 'text',
				// },
			],
			typeList: [
				{
					name: 'Departure',
				},
				{
					name: 'Loading',
				},
				{
					name: 'Transit',
				},
				{
					name: 'Discharing',
				},
				{
					name: 'Bunker',
				},
				{
					name: 'Redelivery',
				},
			],
			singleCheckSelection: {},
			actBottomInfo: null,
			selectList: [], // 当前
		};
	},

	watch: {
		simulationData: {
			handler: function (val) {
				if (Object.keys(val)) {
					if (val.portList.length) {
						let portList = JSON.parse(JSON.stringify(val.portList));
						let index = portList.findLastIndex((item) => {
							return item.type == 'Loading';
						});
						if (index > -1) {
							this.portList = portList.splice(0, index + 1); //TabpaneSimulation2需要修改为this.portList = portList.splice(index, portList.length - 1)
						} else {
							this.portList = portList;
						}
						this.remarks = 'Pls find the following major waypoints for your safe navigation.';
					} else {
						this.initPortList();
					}
					//this.handleSelect();
					let table = val.cpData.filter((item) => item.ld === 'Ballast'); //TabpaneSimulation2需要修改为Laden
					this.tableForm.tableData = JSON.parse(JSON.stringify(table));
					setTimeout(() => {
						this.setSingleCheckTable();
					}, 1000);
				}
			},
			deep: true,
		},
	},
	mounted() {
		this.initPortList();
		this.$bus.$on('changeBottonTable', (data) => {
			if (this.$parent.$parent.$parent.activeName == 'first') {
				//TabpaneSimulation2需要修改为!="first"
				this.changeRoutePlanTableData(data);
			}
		});
		this.$bus.$on('newVoyage', () => {
			this.customerId = '';
			this.portList = [];
			this.tableForm.tableData = [];
			this.routePlanTableData = [];
		});

		// 从地图拾取经纬度作为 port name
		this.$bus.$on('selectPortPoint', (point) => {
			let index = Number(mapobj.selectPointType.index);
			this.portList.splice(index, 1, {
				...this.portList[index],
				portName: point.lng + ', ' + point.lat,
			});
		});
	},
	methods: {
		editClick(row) {
			if (!this.$parent.$parent.$parent.$parent.selectList.includes(row.rowIndex)) {
				this.$refs.multipleTable.toggleRowSelection(row);
				let lineNum = this.$parent.$parent.$parent.$parent.selectList.indexOf(row.rowIndex);
				let data = {
					routeId: row.Summary.routeId,
					pdf: row.Summary.pdf,
					linenum: lineNum,
				};
				this.actBottomInfo = data;
			} else {
				let lineNum = this.$parent.$parent.$parent.$parent.selectList.indexOf(row.rowIndex);
				let data = {
					routeId: row.Summary.routeId,
					pdf: row.Summary.pdf,
					linenum: lineNum,
				};
				this.$bus.$emit('infoClick', data);
			}
		},
		sendClick(scope) {
			let row = scope.row;
			if (!scope.isSelected) {
				this.$message.warning('请勾选航线后发送');
				return;
			}
			if (!row.Summary.RouteId) {
				this.$message.warning('请先保存航线后发送');
				return;
			}
			let basicData1 = this.$parent.$parent.$parent.basicData1;
			// let basicData2 = this.$parent.$parent.$parent.basicData2;
			//let basicData3 = this.$parent.$parent.$parent.basicData3;
			var data = this.$refs.singleCheckTable.selection;
			data = JSON.parse(JSON.stringify(data));
			let parameter = {};
			if (data && data.length) {
				if (data[0].speed) {
					parameter.speed = parseFloat(data[0].speed);
				} else {
					parameter.speed = null;
				}
				parameter.LSFO = data[0].lsfo ? parseFloat(data[0].lsfo) : 0;
				parameter.HSFO = data[0].hsfo ? parseFloat(data[0].hsfo) : 0;
				parameter.MDO = data[0].mdo ? parseFloat(data[0].mdo) : 0;
				parameter.MGO = data[0].mgo ? parseFloat(data[0].mgo) : 0;
			}
			let ballastData = {
				uuid: row.Summary.RouteId,
				voyageNo: basicData1.voyageNo,
				legType: 'Ballast',
				planName: row.Summary.routePlan,
				createPlanTime: row.Summary.updateTime
					? new Date(row.Summary.updateTime).getTime() - new Date().getTimezoneOffset() * 60000
					: new Date(row.Summary.createTime).getTime() - new Date().getTimezoneOffset() * 60000,
				totalDistance: parseFloat(row.Summary.TotalVoyage),
				avgSpeed: parseFloat(row.Summary.speed),
				duration: parseFloat(row.Summary.Durations),
				etd: row.Summary.ETD ? new Date(row.Summary.ETD).getTime() - new Date().getTimezoneOffset() * 60000 : null,
				atd: row.Summary.ATD ? new Date(row.Summary.ATD).getTime() - new Date().getTimezoneOffset() * 60000 : null,
				eta: row.Summary.ETA ? new Date(row.Summary.ETA).getTime() - new Date().getTimezoneOffset() * 60000 : null,
				ecaDistance: row.Summary.EcaDistance ? parseFloat(row.Summary.EcaDistance) : 0,
				ttlConsHsfo: row.Summary.HSFO ? parseFloat(row.Summary.HSFO) : 0,
				ttlConsLsfo: row.Summary.LSFO ? parseFloat(row.Summary.LSFO) : 0,
				ttlConsMdo: row.Summary.MDO ? parseFloat(row.Summary.MDO) : 0,
				ttlConsMgo: row.Summary.MGO ? parseFloat(row.Summary.MGO) : 0,
				departurePortName: this.portList && this.portList.length ? this.portList[0].portName : '',
				arrivalPortName: this.portList && this.portList.length ? this.portList[this.portList.length - 1].portName : '',
				cpSpeed: parameter.speed ? parseFloat(parameter.speed) : null,
				cpFoCons: parameter.LSFO + parameter.HSFO,
				cpDgoCons: parameter.MDO + parameter.MGO,
			};
			request({
				url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/sync/route-plan',
				method: 'POST',
				body: ballastData,
				header: {
					Authorization: 'Bearer ' + getToken(),
				},
			}).then((res) => {
				if (res.code == 200) {
					this.$message.success('Route Plan Send Success');
				}
			});
			let pointData = {
				list: [],
			};
			if (row.WPTList && row.WPTList.length) {
				row.WPTList.forEach((element) => {
					if (element.WPT_Name) {
						pointData.list.push({
							routePlanUuid: row.Summary.RouteId,
							dateTime: element.ETD ? new Date(element.ETD).getTime() - new Date().getTimezoneOffset() * 60000 : '',
							waypointName: element.WPT_Name,
							navType: element.LegType,
							lat: parseFloat(element.Lat),
							lon: parseFloat(element.Lon),
							stoppage: element.StayTime,
							speed: element.STW,
							distance: element.Distance,
							dailyFuel: element.DayFuel,
							remainingBob: element.AccuFuel,
							heading: element.Course,
							remainingDistance: element.RemainDistance,
							duration: element.Durations,
							remainingDuration: element.RemainTime,
							wf: element.WF,
							cf: element.CF,
							windSpeed: element.WindSpeed,
							windDir: element.windDir,
							sigwaveHeight: element.SigWaveHeight,
							swellHeight: element.SwellHeight,
							swellDir: element.SwellDir,
						});
					}
				});
			}
			request({
				url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/sync/way-point',
				method: 'POST',
				body: pointData,
				header: {
					Authorization: 'Bearer ' + getToken(),
				},
			}).then((res) => {
				if (res.code == 200) {
					this.$message.success('WayPoint Send Success');
				}
			});
		},
		handleSelect(val) {
			if (val) {
				this.portList.forEach((item) => {
					if (item.portName == val.portName) {
						item.name = val.portCode;
					}
				});
			}
			mapobj.heightLightByNameList(this.portList);
		},
		getPortNameList(queryString, cb) {
			request({
				url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/port/search',
				method: 'GET',
				body: {
					keyword: queryString,
					pageNum: 1,
					pageSize: 20,
				},
				header: {
					Authorization: 'Bearer ' + getToken(),
				},
			}).then((res) => {
				if (res && res.code == '200') {
					let array = [];
					res.data.forEach((element) => {
						element.value = element.portName;
						array.push(element);
					});
					cb(array);
				} else {
					this.$message.warning(res.data);
				}
			});
		},
		goDetail(id) {
			this.customerId = id;
			this.getHasSaveLine(id);
			this.handleSelect();
		},
		setSingleCheckTable() {
			if (this.tableForm.tableData.length) {
				this.$refs.singleCheckTable.toggleRowSelection(this.tableForm.tableData[0], true);
			}
		},
		changeTableData() {
			this.$bus.$emit('changeRoutePlan');
		},
		// 切换input框的显示状态
		switchShow(index, tag) {
			// 切换
			this.$set(this.routePlanTableData[index], tag, !this.routePlanTableData[index][tag]);
		},
		// 显示input框, 使光标焦点当前input框 - todo未生效
		changeEnddate(index, tag, rowdata) {
			// 关闭之前所有输入框
			this.routePlanTableData.forEach((e) => {
				for (let key in e) {
					if (key.includes('edit')) {
						e[key] = false;
					}
				}
			});
			this.switchShow(index, tag);
			this.$nextTick(() => {
				this.$refs['enddateinput' + tag + '&' + index].focus();
			});
		},
		// 下发表格编辑 || 底图线编辑 后修改右侧对应数据
		changeRoutePlanTableData(data) {
			let index = this.$parent.$parent.$parent.$parent.selectList[data.atcLinenum];
			// let routeId = data.routeId;
			let value = data.value;
			if (this.routePlanTableData[index]) {
				this.routePlanTableData[index].WPTList = value;
				this.routePlanTableData[index] = mapobj.clearWPTpoint(this.routePlanTableData[index], data);
				var data = this.$refs.singleCheckTable?.selection;
				if (data && data.length) {
					if (data[0].speed) {
						this.routePlanTableData[index].Summary.AvgSTW = data[0].speed;
					} else {
						this.routePlanTableData[index].Summary.AvgSTW = '';
					}
				}
			}
			mapobj.routePlanTableData = this.routePlanTableData;
		},
		// 为每行数据设置索引 rowIndex 就是当前所在行的索引
		tableRowClassName(row) {
			row.row.rowIndex = row.rowIndex;
		},
		delCollectList() {
			let mainLine = this.$refs.CollectTable.selection;
			mainLine.forEach((element, i) => {
				deleteCollect({
					id: element.id,
				}).then((res) => {
					this.collectLineData.splice(this.$refs.CollectTable.selection[i].index - 1, 1);
				});
			});
		},
		addCollectList() {
			let mainLine = this.$refs.CollectTable.selection;
			mainLine.forEach((element) => {
				findCollectInfo({
					id: element.id,
				}).then((res) => {
					if (res && res.value) {
						let portList = JSON.parse(res.value);
						portList = portList.map((item) => {
							return [parseFloat(item[0]), parseFloat(item[1])];
						});
						this.uploadSimulationData(portList);
					}
				});
			});
			this.collectListShow = false;
		},
		showCollectList() {
			this.collectListShow = !this.collectListShow;
			if (this.collectListShow) {
				var startId, endId;
				this.portList.forEach((element, index) => {
					if (index == 0) {
						if (element.portName.indexOf(',') > -1 && !isNaN(parseFloat(element.portName))) {
							startId = false;
						} else {
							startId = element.name;
						}
					}
					if (index == this.portList.length - 1) {
						if (element.portName.indexOf(',') > -1 && !isNaN(parseFloat(element.portName))) {
							endId = false;
						} else {
							endId = element.name;
						}
					}
				});
				findCollect({
					startId: startId,
					endId: endId,
				}).then((res) => {
					this.collectLineData = res;
				});
			}
		},
		handleSelectionChange(val) {
			let that = this;
			if (mapobj.editLineLayer) {
				mapobj.editLineLayer.pm.disable();
				mapobj.editLineLayerStepList = [];
				mapobj.editLineLayer.clearLayers();
			}
			mapobj.removeRectangle(); // 清空高亮边框
			this.$parent.$parent.$parent.$parent.selectList = [];
			val.forEach((element) => {
				this.$parent.$parent.$parent.$parent.selectList.push(element.rowIndex);
				if (element.Summary && element.Summary.RouteId && !element.WPTList.length) {
					getRouteLineInfo({
						id: element.Summary.RouteId,
					}).then((res) => {
						if (res && res.length) {
							setTimeout(() => {
								var data = this.$refs.singleCheckTable.selection;
								var parameter = {};
								if (data && data.length) {
									if (data[0].speed) {
										parameter.speed = data[0].speed;
									} else {
										parameter.speed = data[0].speed;
									}
									parameter.LSFO = data[0].lsfo;
									parameter.HSFO = data[0].hsfo;
									parameter.MDO = data[0].mdo;
									parameter.MGO = data[0].mgo;
									parameter.DayFuel = data[0].lsfo ? data[0].lsfo : data[0].hsfo;
								}
								element.WPTList = mapobj.clearPgWptToWptData(res, parameter);
							}, 0);
						}
					});
				}
			});
			setTimeout(() => {
				that.recommendVal = val;
				mapobj.addRecommendPoint(that.recommendVal, this.$parent.$parent.$parent.$parent.$refs.topContent.timesValue);
				mapobj.getGeojsonData(val);
				if (this.actBottomInfo) {
					this.$bus.$emit('infoClick', this.actBottomInfo);
					this.$bus.$emit('changeRoutePlan');
					this.actBottomInfo = null;
				}
			}, 1500);
		},
		handleLineSelectionChange(val) {
			if (val.length > 1) {
				this.$refs.multipleLineTable.clearSelection();
				this.$refs.multipleLineTable.toggleRowSelection(val[val.length - 1]);
			}
			if (val.length) {
				this.wptPointList = val[val.length - 1].WPTList;
				this.$refs.multiWptPointTable.toggleAllSelection();
				var data = this.$refs.singleCheckTable.selection;
				let speed = 0;
				if (data && data.length) {
					if (data[0].speed) {
						speed = data[0].speed;
					}
				}
				this.$refs.pdfMap.addAllLineByWpt(this.selecLineData, val[val.length - 1], speed);
				this.$refs.pdfMap.addAISPoint(this.$parent.$parent.$parent.basicData2.mmsi);
			} else {
				this.wptPointList = [];
				this.$refs.pdfMap.clearLayer();
			}
		},
		// CP table 选择(单选)
		handleCpSelectionChange(val) {
			if (val.length > 1) {
				this.$refs.singleCheckTable.clearSelection();
				this.$refs.singleCheckTable.toggleRowSelection(val[val.length - 1]);
			}
			this.singleCheckSelection = val[val.length - 1];
		},

		// 解密
		decrypt(word, key = 'g8uyfdsoi4fd5f21') {
			key = CryptoJS.enc.Utf8.parse(key);
			let base64 = CryptoJS.enc.Base64.parse(word);
			let src = CryptoJS.enc.Base64.stringify(base64);
			// 解密模式为ECB，补码方式为PKCS5Padding（也就是PKCS7）
			let decrypt = CryptoJS.AES.decrypt(src, key, {
				mode: CryptoJS.mode.ECB,
				padding: CryptoJS.pad.Pkcs7,
			});
			let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
			return decryptedStr.toString();
		},
		getHasSaveLine(id, arr) {
			getRouteLine({
				id: id,
				type: 'ballast', //TabpaneSimulation2需要修改为laden
			}).then((res) => {
				if (res && res.length) {
					setTimeout(() => {
						var data = this.$refs.singleCheckTable.selection;
						var parameter = {};
						if (data && data.length) {
							if (data[0].speed) {
								parameter.speed = data[0].speed;
							} else {
								parameter.speed = data[0].speed;
							}
							parameter.LSFO = data[0].lsfo;
							parameter.HSFO = data[0].hsfo;
							parameter.MDO = data[0].mdo;
							parameter.MGO = data[0].mgo;
							parameter.DayFuel = data[0].lsfo ? data[0].lsfo : data[0].hsfo;
						}
						res = mapobj.clearPgDataToWptData(res, parameter);
						this.routePlanTableData = res;
						if (arr) {
							setTimeout(() => {
								this.routePlanTableData.forEach((element, index) => {
									if (arr.includes(element.Summary.RouteId)) {
										this.$refs.multipleTable.toggleRowSelection(this.routePlanTableData[index], true);
									}
								});
							}, 200);
						}
					}, 1000);
				} else {
					this.routePlanTableData = [];
				}
			});
		},
		saveSimulationData() {
			var data = this.$refs.multipleTable.selection;
			if (!data.length) {
				this.$message.warning('Please select routePlan');
				return;
			}
			let params = mapobj.clearWptDataToPgData(data, {
				remarks: this.remarks,
				customerId: this.customerId,
				type: 'ballast', //TabpaneSimulation2需要修改为laden
			});
			let array = [];
			let updateArray = [];
			params.forEach((element) => {
				if (element.id) {
					updateArray.push(element);
				} else {
					array.push(element);
				}
			});
			if (array.length) {
				routeSave(array).then((res) => {
					if (res.code == 200) {
						this.getHasSaveLine(this.customerId, res.data);
						this.$message.success('保存成功');
					} else {
						this.$message.error('保存失败');
					}
				});
			}
			if (updateArray.length) {
				routeUpdate(updateArray).then((res) => {
					if (res.code == 200) {
						this.$message.success('更新成功');
					} else {
						this.$message.error('更新失败');
					}
				});
			}
		},
		async copySimulationData() {
			var selection = this.$refs.multipleTable.selection;
			if (!selection.length) {
				this.$message.warning('请选择一条要复制的航线');
				return;
			}
			this.$refs.multipleTable.clearSelection();
			var data = this.$refs.singleCheckTable.selection;
			let parameter = {};
			if (data && data.length) {
				if (data[0].speed) {
					parameter.speed = data[0].speed;
				} else {
					parameter.speed = data[0].speed;
				}
				parameter.LSFO = data[0].lsfo;
				parameter.HSFO = data[0].hsfo;
				parameter.MDO = data[0].mdo;
				parameter.MGO = data[0].mgo;
				parameter.DayFuel = data[0].lsfo ? data[0].lsfo : data[0].hsfo;
			}
			this.portList.forEach((element, index) => {
				if (index == 0) {
					parameter.etd = element.time;
					parameter.ETD = element.time;
				}
			});
			parameter = await this.$parent.$parent.$parent.getAtdData(parameter);
			selection.forEach((element) => {
				let json = JSON.parse(JSON.stringify(element));
				json = mapobj.getWptPointInfo(json, parameter);
				json.Summary.RouteId = '';
				json.Summary.pdf = '';
				json.Summary.pdfList = '';
				json.Summary.routePlan = 'routePlan ' + (this.routePlanTableData.length + 1);
				json = mapobj.clearWPTpoint(json);
				this.routePlanTableData.unshift(json);
				this.$refs.multipleTable.toggleRowSelection(this.routePlanTableData[0], true);
			});
		},
		async updateSimulationData(aisData) {
			var selection = this.$refs.multipleTable.selection;
			if (selection.length != 1) {
				this.$message.warning('请选择一条更新的航线');
				return;
			}
			var data = this.$refs.singleCheckTable.selection;
			let parameter = {};
			if (data && data.length) {
				if (data[0].speed) {
					parameter.speed = data[0].speed;
				} else {
					parameter.speed = data[0].speed;
				}
				parameter.LSFO = data[0].lsfo;
				parameter.HSFO = data[0].hsfo;
				parameter.MDO = data[0].mdo;
				parameter.MGO = data[0].mgo;
				parameter.DayFuel = data[0].lsfo ? data[0].lsfo : data[0].hsfo;
			}

			this.portList.forEach((element, index) => {
				if (index == 0) {
					parameter.ETD = element.time;
				}
			});
			parameter = await this.$parent.$parent.$parent.getAtdData(parameter);
			parameter.etd = mapobj.formatDateTime(aisData.lastTime);
			selection.forEach((element) => {
				let json = JSON.parse(JSON.stringify(element));
				json = mapobj.getWptByAis(json, aisData);
				this.resolveWptPointByLine(json.coordinates, parameter, json.arrayWpt);
			});
		},
		async searchSimulationDataByPorts() {
			if (!this.portList.length && this.portList.length < 2) {
				this.$message.warning('请选择起点终点');
				return;
			}
			let params = {};
			var parameter = {};
			this.portList.forEach((element, index) => {
				if (index == 0) {
					parameter.ETD = element.time;
					parameter.etd = element.time;
					params.depPort = element.name;
				}
				if (index == this.portList.length - 1) {
					parameter.eta = element.time;
					params.arrPort = element.name;
				}
			});
			if (!parameter.etd) {
				this.$message.warning('请添加离港时间');
				return;
			}

			var data = this.$refs.singleCheckTable.selection;
			if (data && data.length) {
				if (data[0].speed) {
					parameter.speed = data[0].speed;
				} else {
					parameter.speed = data[0].speed;
				}
				parameter.LSFO = data[0].lsfo;
				parameter.HSFO = data[0].hsfo;
				parameter.MDO = data[0].mdo;
				parameter.MGO = data[0].mgo;
				parameter.DayFuel = data[0].lsfo ? data[0].lsfo : data[0].hsfo;
			}
			if (!parameter.speed) {
				this.$message.warning('请添加速度');
				return;
			}
			parameter = await this.$parent.$parent.$parent.getAtdData(parameter);
			parameter.lineName = 'Big Data';
			request({
				url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/route/db/route/vm',
				method: 'GET',
				body: params,
				header: {
					Authorization: 'Bearer ' + getToken(),
					'Content-Type': 'application/json',
				},
			}).then((item1) => {
				if (item1 && item1.data && item1.data.length) {
					item1.data.forEach((res) => {
						res.routeObject.features.forEach((element) => {
							if (element.geometry.type == 'MultiLineString') {
								let coordinates = [];
								element.geometry.coordinates.forEach((ele, index) => {
									ele.forEach((item, i) => {
										if (index > 0 && i == 0) {
										} else {
											if (item[0] <= 0) {
												item = [item[0] + 360, item[1]];
											}
											coordinates.push(item);
										}
									});
								});
								this.resolveWptPointByLine(coordinates, parameter);
							} else {
								this.resolveWptPointByLine(element.geometry.coordinates, parameter);
							}
						});
					});
				} else {
					this.$message.error('未找到大数据历史航线');
				}
			});
		},
		resolveWptPointByLine(pointList, parameter, typeList) {
			var lstLonDiff = [];
			for (var i = 0; i < pointList.length - 1; i++) {
				var detLon = pointList[i + 1][0] - pointList[i][0];
				if (Math.abs(detLon) > 180) {
					if (detLon > 0) {
						detLon -= 360;
					} else {
						detLon += 360;
					}
				}
				lstLonDiff.push(detLon);
			}
			if (pointList[0][0] < 0) pointList[0][0] += 360;
			for (var i = 0; i < pointList.length - 1; i++) {
				pointList[i + 1][0] = pointList[i][0] + lstLonDiff[i];
			}
			let WPTList = [];
			pointList.forEach((element, index) => {
				let LegType = 'RL';
				if (typeList && typeList.length) {
					LegType = typeList[index];
				}
				WPTList.push({
					WPT_No: 1,
					WPT_Name: index < 10 ? 'W00' + index : 'W0' + index,
					LegType: LegType,
					WPType: index == 0 ? 'DEP' : index == pointList.length - 1 ? 'ARR' : 'WPT',
					Lat: element[1],
					Lon: element[0],
					ETD: parameter.etd,
					STW: parameter.speed,
					Course: 0,
					Distance: 0,
					Durations: 0,
					ETA: parameter.etd,
					AccuDistance: 0,
					AccuTime: 0,
					RemainDistance: 0,
					RemainTime: 0,
					IntermediatePort: false,
					AnchTime: 0,
					WorkTime: 0,
					StayTime: 0,
					IntermediateTotalTime: 0,
					SOG: parameter.speed,
					TurnRadius: 0,
					RPM: 0,
					Power: 0,
					DayFuel: parameter.DayFuel,
					CurrentFactor: 0,
					WeatherFactor: 0,
					EstRPM: 0,
					EstPower: 0,
					EstFuel: 0,
					AccuFuel: 0,
					WindDir: 0,
					WindSpeed: 0,
					WindWaveHeight: 0,
					SigWaveHeight: 0,
					SwellDir: 0,
					SwellHeight: 0,
					SwellPeriod: 0,
					CurrentDir: 0,
					CurrentSpeed: 0,
					AirPressure: 0,
					isFixedETA: true,
					isFixedSTW: false,
					LSFO: parameter.LSFO,
					HSFO: parameter.HSFO,
					MDO: parameter.MDO,
					MGO: parameter.MGO,
				});
			});
			let lineName = parameter.lineName ? parameter.lineName : 'routePlan ';
			let result = {
				Summary: {
					ATD: parameter.atd,
					TotalVoyage: 0,
					ETA: '',
					ETD: parameter.ETD,
					FuelConsumption: '',
					Durations: '',
					ecaDistance: '',
					ecaFuel: '',
					RouteId: '',
					LSFO: parameter.LSFO,
					HSFO: parameter.HSFO,
					MDO: parameter.MDO,
					MGO: parameter.MGO,
					AvgSTW: parameter.speed,
					DayFuel: parameter.DayFuel,
					routePlan: lineName + (this.routePlanTableData.length + 1),
				},
				WPTList: WPTList,
			};
			result = mapobj.clearWPTpoint(result);
			this.routePlanTableData.unshift(result);
			setTimeout(() => {
				this.$refs.multipleTable.toggleRowSelection(this.routePlanTableData[0]);
			}, 200);
			mapobj.routePlanTableData = this.routePlanTableData;
		},
		async uploadSimulationData(pointList, arrayWpt) {
			var parameter = {};
			this.portList.forEach((element, index) => {
				if (index == 0) {
					parameter.etd = element.time;
					parameter.ETD = element.time;
				}
			});
			if (!parameter.etd) {
				this.$message.warning('请添加离港时间');
				return;
			}
			var data = this.$refs.singleCheckTable.selection;
			if (data && data.length) {
				if (data[0].speed) {
					parameter.speed = data[0].speed;
				} else {
					parameter.speed = data[0].speed;
				}
				parameter.LSFO = data[0].lsfo;
				parameter.HSFO = data[0].hsfo;
				parameter.MDO = data[0].mdo;
				parameter.MGO = data[0].mgo;
				parameter.DayFuel = data[0].lsfo ? data[0].lsfo : data[0].hsfo;
			}
			if (!parameter.speed) {
				this.$message.warning('请添加速度');
				return;
			}
			parameter = await this.$parent.$parent.$parent.getAtdData(parameter);
			this.resolveWptPointByLine(pointList, parameter, arrayWpt);
		},
		async searchSimulationData() {
			if (!this.portList.length && this.portList.length < 2) {
				this.$message.warning('请选择起点终点');
				return;
			}
			let params = '';
			var parameter = {};
			this.portList.forEach((element, index) => {
				if (index != 0) {
					params += '_';
				}
				if (index == 0) {
					parameter.ETD = element.time;
					parameter.etd = element.time;
				}
				if (index == this.portList.length - 1) {
					parameter.eta = element.time;
				}
				if (element.portName.indexOf(',') > -1 && !isNaN(parseFloat(element.portName))) {
					params += '(' + element.portName + ')';
				} else {
					params += element.name;
				}
			});
			if (!parameter.etd) {
				this.$message.warning('请添加离港时间');
				return;
			}
			var data = this.$refs.singleCheckTable.selection;
			if (data && data.length) {
				if (data[0].speed) {
					parameter.speed = data[0].speed;
				} else {
					parameter.speed = data[0].speed;
				}
				parameter.LSFO = data[0].lsfo;
				parameter.HSFO = data[0].hsfo;
				parameter.MDO = data[0].mdo;
				parameter.MGO = data[0].mgo;
				parameter.DayFuel = data[0].lsfo ? data[0].lsfo : data[0].hsfo;
			}
			if (!parameter.speed) {
				this.$message.warning('请添加速度');
				return;
			}
			parameter = await this.$parent.$parent.$parent.getAtdData(parameter);
			request({
				url: process.env.VUE_APP_FLEET_API + '/distbl/',
				method: 'GET',
				body: {
					ports: params,
				},
				header: {
					'Content-Type': 'application/json',
				},
			}).then((item1) => {
				if (item1 && item1.data && item1.data.path) {
					let pointList = [];
					if (item1.data.path.geometry.type == 'MultiLineString') {
						// pointList = item1.data.path.geometry.coordinates[0]
						item1.data.path.geometry.coordinates.forEach((ele, index) => {
							ele.forEach((item, i) => {
								if (index > 0 && i == 0) {
								} else {
									if (item[0] <= 0) {
										item = [item[0] + 360, item[1]];
									}
									pointList.push(item);
								}
							});
						});
					} else {
						pointList = item1.data.path.geometry.coordinates;
					}
					this.resolveWptPointByLine(pointList, parameter);
					// let WPTList = [];
					// pointList.forEach((element, index) => {
					// 	WPTList.push({
					// 		"WPT_No": 1,
					// 		"WPT_Name": index < 10 ? "W00" + index : "W0" + index,
					// 		"LegType": "RL",
					// 		"WPType": index == 0 ? "DEP" : index == pointList.length - 1 ? "ARR" : "WPT",
					// 		"Lat": element[1],
					// 		"Lon": element[0],
					// 		"ETD": parameter.etd,
					// 		"STW": parameter.speed,
					// 		"Course": 0,
					// 		"Distance": 0,
					// 		"Durations": 0,
					// 		"ETA": parameter.etd,
					// 		"AccuDistance": 0,
					// 		"AccuTime": 0,
					// 		"RemainDistance": 0,
					// 		"RemainTime": 0,
					// 		"IntermediatePort": false,
					// 		"AnchTime": 0,
					// 		"WorkTime": 0,
					// 		"StayTime": 0,
					// 		"IntermediateTotalTime": 0,
					// 		"SOG": parameter.speed,
					// 		"TurnRadius": 0,
					// 		"RPM": 0,
					// 		"Power": 0,
					// 		"DayFuel": parameter.DayFuel,
					// 		"CurrentFactor": 0,
					// 		"WeatherFactor": 0,
					// 		"EstRPM": 0,
					// 		"EstPower": 0,
					// 		"EstFuel": 0,
					// 		"AccuFuel": 0,
					// 		"WindDir": 0,
					// 		"WindSpeed": 0,
					// 		"WindWaveHeight": 0,
					// 		"SigWaveHeight": 0,
					// 		"SwellDir": 0,
					// 		"SwellHeight": 0,
					// 		"SwellPeriod": 0,
					// 		"CurrentDir": 0,
					// 		"CurrentSpeed": 0,
					// 		"AirPressure": 0,
					// 		"isFixedETA": true,
					// 		"isFixedSTW": false
					// 	},)
					// });
					// let result = {
					// 	Summary: {
					// 		TotalVoyage: 0,
					// 		ETA: "",
					// 		ETD: "",
					// 		FuelConsumption: "",
					// 		Durations: "",
					// 		ecaDistance: "",
					// 		ecaFuel: "",
					// 		RouteId: "",
					// 		routePlan: 'routePlan ' + (this.routePlanTableData.length + 1)
					// 	},
					// 	WPTList: WPTList
					// }
					// result = mapobj.clearWPTpoint(result);
					// this.routePlanTableData.unshift(result);
					// setTimeout(() => {
					// 	this.$refs.multipleTable.toggleRowSelection(this.routePlanTableData[0]);
					// }, 200);
					// mapobj.routePlanTableData = this.routePlanTableData;
				} else {
					this.$message.error('未找到规划航线');
				}
			});
		},
		// searchSimulationData() {
		// 	if (!this.portList.length && this.portList.length < 2) {
		// 		this.$message.warning('请选择起点终点');
		// 		return;
		// 	}
		// 	let ceshi = {
		// 		waypoints: [],
		// 		voyageOptions: { etd: '2023-09-25 12:46:18' },
		// 		routeOptions: { Canals: 'suez' },
		// 	};
		// 	var data = this.$refs.singleCheckTable.selection;
		// 	var DayFuel = 0;
		// 	if (data && data.length) {
		// 		ceshi.voyageOptions.speed = data[0].speed;
		// 		DayFuel = data[0].lsfo ? data[0].lsfo : data[0].hsfo;
		// 	}
		// 	this.portList.forEach((element, index) => {
		// 		let portInfo = this.portNameList.find((res) => {
		// 			return res.port_code == element.name;
		// 		});

		// 		if (portInfo) {
		// 			ceshi.waypoints.push({
		// 				Lon: portInfo.lon,
		// 				Lat: portInfo.lat,
		// 			});
		// 		}
		// 		else if (element.name.indexOf(",") > -1) {
		// 			let arr = element.name.split(",");
		// 			if (arr && arr.length) {
		// 				ceshi.waypoints.push({
		// 					Lon: parseFloat(arr[0]),
		// 					Lat: parseFloat(arr[1]),
		// 				});
		// 			}
		// 		}
		// 		if (index == 0) {
		// 			ceshi.voyageOptions.etd = element.time;
		// 		}
		// 		if (index == this.portList.length - 1 && !ceshi.voyageOptions.speed) {
		// 			ceshi.voyageOptions.eta = element.time;
		// 		}
		// 	});
		// 	// ceshi = {
		// 	// 	waypoints: [
		// 	// 		{ Lon: 121.88875, Lat: 25.93085 },
		// 	// 		{ Lon: 123.78996666666667, Lat: 34.4541 },
		// 	// 	],
		// 	// 	voyageOptions: { etd: '2023-09-25 12:46:18', speed: '16' },
		// 	// 	routeOptions: { Canals: 'suez' },
		// 	// };
		// 	routeSearch({
		// 		data: zcZip.compressToEncodedURIComponent(JSON.stringify(ceshi)),
		// 	}).then((res) => {
		// 		if (res) {
		// 			let key = sessionStorage.getItem('Route-Secret');
		// 			res = this.decrypt(res, key);
		// 			res = zcZip.decompressFromEncodedURIComponent(res);
		// 			res = JSON.parse(res);
		// 			res = mapobj.getWptPointInfo(res[0], DayFuel);
		// 			res = mapobj.clearWPTpoint(res);
		// 			res.Summary.routePlan = 'routePlan ' + (this.routePlanTableData.length + 1);

		// 			this.routePlanTableData.unshift(res);
		// 			setTimeout(() => {
		// 				this.$refs.multipleTable.toggleRowSelection(this.routePlanTableData[0]);
		// 			}, 200);
		// 			mapobj.routePlanTableData = this.routePlanTableData;
		// 		}
		// 	});
		// },
		async collectSimulationData(row) {
			if (!this.portList.length && this.portList.length < 2) {
				this.$message.warning('暂无选择的港口');
				return;
			}
			let list = [];
			if (row && row.WPTList && row.WPTList.length) {
				list = row.WPTList;
			} else if (row.Summary.RouteId) {
				list = await getRouteLineInfo({
					id: row.Summary.RouteId,
					type: 'ballast', //TabpaneSimulation2需要修改为laden
				});
			}
			let pointList = [];
			list.forEach((element) => {
				let lon = element.LON || element.Lon || element.lon;
				let lat = element.LAT || element.Lat || element.lat;
				pointList.push([lon, lat]);
			});
			var startId, endId;
			this.portList.forEach((element, index) => {
				if (index == 0) {
					if (element.portName.indexOf(',') > -1 && !isNaN(parseFloat(element.portName))) {
						startId = false;
					} else {
						startId = element.name;
					}
				}
				if (index == this.portList.length - 1) {
					if (element.portName.indexOf(',') > -1 && !isNaN(parseFloat(element.portName))) {
						endId = false;
					} else {
						endId = element.name;
					}
				}
			});
			if (startId && endId) {
				addCollect({
					startId: startId,
					endId: endId,
					name: row.Summary.routePlan,
					time: mapobj.formatDateTime(new Date()),
					value: JSON.stringify(pointList),
					parentId: row.Summary.RouteId,
				}).then((res) => {
					if (res && res.code == 200) {
						this.$message.success('收藏成功');
					}
				});
			} else {
				this.$message.warning('请选择起止港口');
			}
		},
		deleteSimulationData(row) {
			if (row.Summary.RouteId) {
				delRouteLine({ id: row.Summary.RouteId }).then((res) => {
					this.routePlanTableData.splice(row.rowIndex, 1);
				});
				request({
					url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/sync/route-plan/' + row.Summary.RouteId,
					method: 'DELETE',
					body: {},
					header: {
						Authorization: 'Bearer ' + getToken(),
					},
				}).then((res) => {
					if (res.code == 200) {
						this.$message.success('Route Plan Delete Success');
					}
				});
				request({
					url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/sync/way-point/' + row.Summary.RouteId,
					method: 'DELETE',
					body: {},
					header: {
						Authorization: 'Bearer ' + getToken(),
					},
				}).then((res) => {
					if (res.code == 200) {
						this.$message.success('WayPoint Delete Success');
					}
				});
			} else {
				this.routePlanTableData.splice(row.rowIndex, 1);
			}

			// for (var i = this.$refs.multipleTable.selection.length - 1; i >= 0; i--) {
			// 	if (!this.$refs.multipleTable.selection[i].Summary.RouteId) {
			// 		this.routePlanTableData.splice(this.$refs.multipleTable.selection[i].index - 1, 1)
			// 	}
			// }
		},
		beforeDownSimulationData() {
			this.dialogVisible = true;
			this.selecLineData = this.$refs.multipleTable.selection;
			//this.loading = true;
			this.$nextTick((e) => {
				setTimeout(() => {
					this.$refs.pdfMap.addLineByPoint(this.$parent.$parent.$parent.basicData2.mmsi, this.selecLineData);
				}, 100);
			});
			// mapobj.exportImage();
			// setTimeout(() => {
			// 	this.pdfimage = mapobj.pdfImage;
			// }, 10000);
		},
		downSimulationData() {
			// 导出mapbox图片
			this.pdfimage = this.$refs.pdfMap.map.getCanvas().toDataURL('image/png');
			let params = {
				arrivalPort: '',
				arrivalPortDate: '',
				companyName: '',
				departurePort: '',
				departurePortDate: '',
				image: '',
				majorWaypoint: [
					{
						dist: '',
						majorWaypoint: '',
						name: '',
						nav: '',
						sog: '',
						time: '',
					},
				],
				remark: '',
				reportCreated: mapobj.formatDateTimeWeather(new Date().getTime() + new Date().getTimezoneOffset() * 60000),
				routeForecast: [
					{
						currentDir: '',
						currentFactor: '',
						currentSpeed: '',
						dateTime: '',
						position: '',
						seaHt: '',
						sigDir: '',
						sigHgt: '',
						sigPrd: '',
						swellDir: '',
						swellHt: '',
						swellPrd: '',
						windDir: '',
						windSpeed: '',
					},
				],
				routeSummary: [
					{
						avgSpdWxCurFactor: '',
						cpCons: '',
						cpSpd: '',
						eta: '',
						id: '',
						route: '',
						ttlCons: '',
						ttlDist: '',
					},
				],
				vesselName: '',
				voyageCondition: '',
			};
			let basic1 = this.$parent.$parent.$parent.$refs.tabpaneBasic1Ref.form;
			let basic2 = this.$parent.$parent.$parent.$refs.tabpaneBasic2Ref.vesselForm;
			let basic3 = this.$parent.$parent.$parent.$refs.tabpaneBasic3Ref.portList;
			params.companyName = basic1.customerName;
			basic3.forEach((element, index) => {
				if (index == 0) {
					params.departurePort = element.portName;
					params.departurePortDate = mapobj.formatDateTimeWeather(element.time, true);
				}
				if (index == basic3.length - 1) {
					params.arrivalPort = element.portName;
				}
			});

			params.vesselName = basic2.vesselName;
			params.voyageCondition = 'Ballast'; //TabpaneSimulation2需要修改为Laden
			var data = this.$refs.singleCheckTable.selection;
			var speed = 0;
			var cpCons = 0;
			var cpMdo = 0;
			if (data && data.length) {
				speed = data[0].speed;
				cpCons = data[0].lsfo ? data[0].lsfo : data[0].hsfo;
				cpMdo = data[0].mdo ? data[0].mdo : data[0].mgo;
			}
			cpCons = cpCons ? cpCons : 0;
			cpMdo = cpMdo ? cpMdo : 0;
			params.remark = this.remarks;
			var selection = this.$refs.multipleTable.selection;
			let array = [];
			let majorWaypoint = [];
			let mainLine = this.$refs.multipleLineTable.selection;
			if (!mainLine.length) {
				this.$message.warning('请选择一条主航线');
				return;
			}
			if (mainLine && mainLine.length) {
				mainLine = mainLine[0];
			}
			if (mainLine.Summary.ATD) {
				params.departurePortDate = mapobj.formatDateTimeWeather(mainLine.Summary.ATD, true);
			}
			let selectWaypoint = this.$refs.multiWptPointTable.selection;
			var weatherPoint = [];
			selection.forEach((element) => {
				let ttlConsFO = '-';
				let ttlConsMDO = '-';
				var avgWfCf = {
					WfCount: 0,
					CfCount: 0,
					WfValue: 0,
					CfValue: 0,
				};
				element.WPTList.forEach((element) => {
					if (element.WF) {
						element.WF = parseFloat(element.WF);
						if (element.WF < 999) {
							avgWfCf.WfCount++;
							avgWfCf.WfValue += element.WF;
						}
					}
					if (element.CF) {
						element.CF = parseFloat(element.CF);
						if (element.CF < 999) {
							avgWfCf.CfCount++;
							avgWfCf.CfValue += element.CF;
						}
					}
				});
				let speedFactor = parseFloat(speed);
				let charFactor = '';
				if (avgWfCf.WfCount) {
					let WF = avgWfCf.WfValue / avgWfCf.WfCount;
					speedFactor += WF;
					charFactor += '/' + WF.toFixed(2);
				} else {
					charFactor += '/ -';
				}
				if (avgWfCf.CfCount) {
					let CF = avgWfCf.CfValue / avgWfCf.CfCount;
					speedFactor += CF;
					charFactor += '/' + CF.toFixed(2);
				} else {
					charFactor += '/ -';
				}
				if (speedFactor) {
					ttlConsFO = (element.Summary.TotalVoyage / speedFactor / 24) * cpCons;
					ttlConsMDO = (element.Summary.TotalVoyage / speedFactor / 24) * cpMdo;
				}
				let json = {
					avgSpdWxCurFactor: speedFactor.toFixed(2) + charFactor,
					cpCons: cpCons + 'FO/' + cpMdo + 'DO',
					cpSpd: speed,
					eta: element.Summary.ETA,
					id: element.Summary.RouteId,
					route: element.Summary.routePlan,
					ttlCons: ttlConsFO?.toFixed(2) + 'FO/' + ttlConsMDO?.toFixed(2) + 'DO',
					ttlDist: parseFloat(element.Summary.TotalVoyage)?.toFixed(2),
				};
				if (element.WPTList && element.WPTList.length && mainLine.Summary.RouteId == element.Summary.RouteId) {
					element.WPTList.forEach((ele, index) => {
						// if (index == 0) {
						// 	params.departurePortDate = mapobj.formatDateTimeWeather(ele.ETD, true);
						// }
						if (index == element.WPTList.length - 1) {
							params.arrivalPortDate = mapobj.formatDateTimeWeather(ele.ETD, true);
						}
						if (
							selectWaypoint.find((res) => {
								return res.WPT_Name == ele.WPT_Name;
							})
						) {
							majorWaypoint.push({
								dist: parseFloat(ele.RemainDistance)?.toFixed(2),
								majorWaypoint: mapobj.ChangeToDFM(ele['Lat'], 0) + '/' + mapobj.ChangeToDFM(ele['Lon'], 1),
								name: ele.WPT_Name,
								nav: ele.LegType,
								sog: parseFloat(ele.STW).toFixed(2),
								time: mapobj.formatDateTimeWeather(ele.ETD),
							});
						}
					});
					let WPTList = JSON.parse(JSON.stringify(element.WPTList));
					mapobj.get_wptlist_for_circle(WPTList);
					weatherPoint = mapobj.getWeatherPointInfo(WPTList, speed);
				}
				if (mainLine.id == element.id) {
					array.unshift(json);
				} else {
					array.push(json);
				}
			});

			params.routeSummary = array;
			params.majorWaypoint = majorWaypoint;
			params.image = this.pdfimage.replace('data:image/png;base64,', '');
			setTimeout(async () => {
				let weatherPointList = [];
				if (weatherPoint && weatherPoint.length && weatherPoint.length > 40) {
					for (let index = 0; index < 40; index++) {
						const element = weatherPoint[index];
						weatherPointList.push(element);
					}
				} else {
					weatherPointList = weatherPoint;
				}
				let item1 = await mapobj.getWeatherInfoByPromise(weatherPointList);
				if (item1 && item1.length) {
					let routeForecast = [];
					let cfList = [];
					for (let index = 0; index < item1.length; index++) {
						const element = item1[index];
						let json = {
							currentDir: mapobj.get_compass_pos(element.currentDir),
							currentFactor: '',
							currentSpeed: parseFloat(element.currentSpeed)?.toFixed(2),
							dateTime: mapobj.formatDateTimeWeather(element.paramTime),
							position: mapobj.ChangeToDFM(element['lat'], 0) + '/' + mapobj.ChangeToDFM(element['lng'], 1),
							seaHt: '',
							sigDir: mapobj.get_compass_pos(element.windWaveDirection),
							sigHgt: parseFloat(element.sigWaveHeight)?.toFixed(2),
							sigPrd: parseFloat(element.windWavePeriod)?.toFixed(2),
							swellDir: mapobj.get_compass_pos(element.swellDir),
							swellHt: parseFloat(element.swellHeight)?.toFixed(2),
							swellPrd: parseFloat(element.swellPeriod)?.toFixed(2),
							windDir: mapobj.get_compass_pos(element.windDir),
							windSpeed: parseFloat(element.windSpeed)?.toFixed(2),
						};
						let course = 0;
						if (index < item1.length - 1) {
							const nextWpt = item1[index + 1];
							var from = turf.point([element.lng, element.lat]);
							var to = turf.point([nextWpt.lng, nextWpt.lat]);
							course = turf.bearing(from, to);
						}

						let json1 = {
							index: index,
							currentSpeed: element.currentSpeed,
							currentDir: element.currentDir,
							windSpeed: element.windSpeed,
							windDir: element.windDir,
							sigWaveHeight: element.sigWaveHeight,
							windWaveDir: element.windWaveDirection,
							swellDir: element.swellDir,
							windWaveHeight: element.windWaveHeight,
							swellHeight: element.swellHeight,
							cog: course,
							vesselSpeed: speed,
						};
						cfList.push(json1);
						routeForecast.push(json);
					}
					//this.selecLineData = routeForecast;
					request({
						url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1/monitoring/report/cf-wf',
						method: 'POST',
						body: cfList,
						header: {
							Authorization: 'Bearer ' + getToken(),
						},
					}).then((res) => {
						if (res && res.data && res.data.length) {
							for (let index = 0; index < res.data.length; index++) {
								const element = res.data[index];
								routeForecast[index] = {
									...routeForecast[index],
									currentFactor: element.cf && element.cf != 'NaN' ? element.cf.toFixed(2) : 0,
									//cf: element.cf.toFixed(2),
									//wf: element.wf.toFixed(2),
								};
							}
							params.routeForecast = routeForecast;
							this.exportpdfByParams(params);
						} else {
							this.exportpdfByParams(params);
						}
					});
				} else {
					this.exportpdfByParams(params);
				}
			}, 100);
		},
		downPdfAgain(id) {
			exportpdfAgain({
				id: id,
			}).then((res) => {
				if (res) {
					this.$message.success('导出成功');
					let url = window.URL.createObjectURL(res);
					let a = document.createElement('a');
					document.body.appendChild(a);
					a.href = url;
					a.download = '报告';
					a.click();
				}
			});
		},
		exportpdfByParams(params) {
			exportpdf(params).then((res) => {
				if (res) {
					this.dialogVisible = false;
					this.$message.success('导出成功');
					let url = window.URL.createObjectURL(res);
					let a = document.createElement('a');
					document.body.appendChild(a);
					a.href = url;
					a.download = '报告';
					a.click();
				}
			});
		},
		addPortItem(type, index) {
			this.portList.splice(index + 1, 0, {
				type,
				name: '',
				portName: '',
				time: '',
				timeOther: '',
				uuid: uuidv4(),
			});
		},

		removePortItem(index) {
			this.portList.splice(index, 1);
		},

		upItem(index) {
			if (index === 1) {
				this.$message.info('禁止移动到第一级');
				return;
			}
			this.swap(this.portList, index, index - 1);
		},

		downItem(index) {
			if (index === this.portList.length - 2) {
				this.$message.info('禁止移动到最后一级');
				return;
			}
			this.swap(this.portList, index, index + 1);
		},

		// CP Performance Table 增减项
		addCpPerformance() {
			this.tableForm.tableData.push({
				uuid: uuidv4(),
				ld: `Type${Math.floor(Math.random() * 10)}`,
				speed: '',
				hsfo: '',
				lsfo: '',
				mdo: '',
				mgo: '',
			});
		},

		removeCpPerformance(row) {
			this.tableForm.tableData = this.tableForm.tableData.filter((item) => item.uuid !== row.uuid);
		},

		swap(arr, index1, index2) {
			const ele1 = arr[index1];
			const ele2 = arr[index2];
			this.$set(this.portList, index1, ele2);
			this.$set(this.portList, index2, ele1);
		},
		//
		clearPortName(index) {
			mapobj.removeSelectPoint(index);
		},
		// 地图选点
		selectPoint(index) {
			if (mapobj.selectPointType) {
				mapobj.selectPointType = null;
				return;
			}
			// 当类型位Redelivery或者为最后一个点时设为红色点
			mapobj.selectPointType = {
				index: index,
				type: this.portList[index].type === 'Redelivery' || index === this.portList.length - 1 ? 'red' : 'blue',
			};
			let body = document.querySelector('body');
			body.style.cursor = 'crosshair';
		},
		cancelBlur() {
			setTimeout(() => {
				this.cancel();
			}, 500);
		},
		// 取消地图选点
		cancel() {
			mapobj.selectPointType = null;
			let body = document.querySelector('body');
			body.style.cursor = 'auto';
		},

		initPortList() {
			this.portList = [
				{
					type: 'Departure',
					name: '',
					portName: '',
					time: '',
					timeOther: '',
					uuid: uuidv4(),
				},
				{
					type: 'Redelivery',
					name: '',
					portName: '',
					time: '',
					timeOther: '',
					uuid: uuidv4(),
				},
			];
		},
	},
};
</script>

<style lang="scss" scoped>
.port-list {
	.port-item {
		display: flex;
		align-items: end;
		margin-bottom: 15px;

		.port-index {
			margin-right: 15px;
			width: 32px;
			height: 32px;
			border-radius: 16px;
			text-align: center;
			line-height: 32px;
			color: rgba(53, 86, 145, 0.9);
			font-size: 16px;

			background: rgba(53, 86, 145, 0.2);
			border: 1px solid rgba(53, 86, 145, 0.2);
			border-radius: 32px;
		}

		.type,
		.port,
		.time {
			margin-right: 15px;

			.port-name,
			.port-time {
				font-size: 14px;
				color: #484848;
				line-height: 22px;
			}

			.el-select {
				width: 120px;
			}

			.port-select {
				width: 200px;
			}

			.el-input {
				width: 180px;
			}
		}

		.port {
			.el-select {
				width: 180px;
			}
		}

		.btns {
			// ::v-deep .el-button {
			// 	color: #d9d9d9;
			// }
		}
	}

	.loading-type {
		.el-input {
			width: 90px !important;
			margin-right: 10px;
		}
	}
}

.voyage-btn {
	padding: 5px 5px;
	margin-right: 10px;
	font-size: 12px;
	min-width: 55px;
	height: 32px;
	border-radius: 6px;
	background: #fff;
	color: #355691;
	border: 1px solid #d9d9d9;
	cursor: pointer;
	float: right;
}

::v-deep .table-form,
::v-deep .route-plan-table-row {
	.el-table {
		width: 97%;
		margin: 0px 1.5%;
		border-radius: 6px;

		.el-table__header {
			.table-header {
				color: $defaultFontColor;
				font-weight: bold;

				th {
					background: #eff1f5;

					.el-checkbox {
						display: none;
					}
				}
			}
		}

		.el-table__row {
			.el-form-item {
				margin-bottom: 0;
				color: $defaultFontColor;
			}

			.el-button {
				color: #d9d9d9;
			}

			.el-button--text {
				color: #409eff;
			}
		}
	}
}

// 覆盖层元素增加可穿透点击事件
::v-deep .el-dialog__wrapper {
	pointer-events: none;
}

::v-deep .el-dialog {
	// 弹窗层元素不可穿透点击事件（不影响弹窗层元素的点击事件）
	pointer-events: auto;

	.dialog-content {
		max-height: 70vh;
		overflow-y: auto;
		overflow-x: hidden;

		.map-content {
			width: 800px;
			height: 450px;
			// background: #dddddd;
		}

		.route-plan-table {
			margin-left: 0;
		}
	}
}

.route-plan-table {
	margin: 0 10px;
}

.remarks {
	margin: 0 10px;
}
</style>

<template>
	<div>
		<div class="right-content" ref="rWrap">
			<div ref="rResizeLine" id="resize" class="resize-line" @mousedown="handleMouseDown"></div>
			<div class="basic-list">
				<div
					:class="activeButton == item.id ? 'basic-btn active' : 'basic-btn'"
					v-for="(item, index) in basicBtnList"
					:key="item.icon + index"
					@click="basicBtnClick(index)"
				>
					<img :src="item.icon" class="basic-icon" alt="" />
				</div>
			</div>
			<div class="content">
				<!-- Basic -->
				<div class="basic-content" v-show="activeButton == 'info'">
					<div class="top">
						<div class="title-class">
							<div class="title">Basic</div>
							<div class="vice-title">Created Date: 2023/12/20 07:31</div>
						</div>
						<div class="voyage-button">
							<button class="voyage-btn" v-if="showEdit" @click="showEdit = false">Edit</button>
							<button class="voyage-btn" v-else @click="saveBasicData">Save</button>
							<button class="voyage-btn" @click="closeRightContent">Close</button>
							<button class="voyage-btn" @click="deleteRightContent">Delete</button>
						</div>
					</div>
					<div class="center">
						<el-tabs v-model="activeName" @tab-click="tabClick">
							<el-tab-pane label="Customer" name="first">
								<TabpaneBasic1
									ref="tabpaneBasic1Ref"
									@setCustomerId="handleSetCustomerId"
									:customer-id="customerId"
									:basic-data="basicData1"
									:show-edit="showEdit"
								>
								</TabpaneBasic1>
							</el-tab-pane>
							<el-tab-pane label="CP Terms" name="second">
								<TabpaneBasic2
									ref="tabpaneBasic2Ref"
									:customer-id="customerId"
									:basic-data="basicData2"
									:show-edit="showEdit"
								>
								</TabpaneBasic2>
							</el-tab-pane>
							<el-tab-pane label="Port&Remarks" name="third">
								<TabpaneBasic3
									ref="tabpaneBasic3Ref"
									:customer-id="customerId"
									:basic-data="basicData3"
									:show-edit="showEdit"
								>
								</TabpaneBasic3>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>

				<!-- Simulation -->
				<div class="simulation-content" v-show="activeButton == 'simulation'">
					<div class="top">
						<div class="title-class">
							<div class="title">Simulation</div>
							<div class="vice-title">Created Date: 2023/12/20 07:31</div>
						</div>
						<div class="voyage-button">
							<button class="voyage-btn" @click="copySimulationData">Copy</button>
							<button class="voyage-btn" @click="searchSimulationData">Search</button>
							<button class="voyage-btn voyage-btn-size" @click="searchSimulationDataByPorts">Route Data</button>
							<!-- <button class="voyage-btn voyage-btn-size" @click="updateSimulationData">Update</button> -->

							<el-popover placement="bottom" title="AIS Info" width="380" trigger="click">
								<button class="voyage-btn" slot="reference" @click="updateSimulationData">Update</button>
								<div class="ais-position">
									<el-row :gutter="20" class="text-row-class">
										<el-col :span="6">
											<div class="text-label-ais">DR Position:</div>
										</el-col>
										<el-col :span="9">
											<div class="text-label">
												<el-input size="mini" v-model="aisInfo.lat" class="el-search-input"> </el-input>
											</div>
										</el-col>
										<el-col :span="9">
											<div class="text-label">
												<el-input size="mini" v-model="aisInfo.lon" class="el-search-input"> </el-input>
											</div>
										</el-col>
									</el-row>
									<el-row :gutter="20" class="text-row-class">
										<el-col :span="6">
											<div class="text-label-ais">DR Time:</div>
										</el-col>
										<el-col :span="18">
											<div class="text-label">
												<mydatePicker
													size="mini"
													align="center"
													style="width: 100%"
													v-model="aisInfo.dateTime"
													type="datetime"
													format="yyyy-MM-dd HH:mm"
													value-format="yyyy-MM-dd HH:mm"
												>
												</mydatePicker>
											</div>
										</el-col>
									</el-row>
									<el-row :gutter="20" class="text-row-class">
										<button class="voyage-btn-ais" @click="searchUpdateSimulationData">Search</button>
									</el-row>
								</div>
							</el-popover>
							<el-upload class="upload-demo" :action="uploadUrl" :show-file-list="false" :on-success="uploadSuccess">
								<button class="voyage-btn">Upload</button>
							</el-upload>
							<button class="voyage-btn" @click="saveSimulationData">Save</button>
							<button class="voyage-btn common-btn" @click="downSimulationData">Download</button>
						</div>
					</div>
					<div class="center">
						<el-tabs v-model="activeName" @tab-click="tabSimulationClick">
							<el-tab-pane label="Ballast" name="first">
								<TabpaneSimulation1 ref="RoutePlan" :simulation-data="simulationData"></TabpaneSimulation1>
							</el-tab-pane>
							<el-tab-pane label="Laden" name="second">
								<TabpaneSimulation2 ref="RoutePlan1" :simulation-data="simulationData"></TabpaneSimulation2>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
				<!-- masterReport  -->
				<div class="basic-content" v-show="activeButton == 'report'">
					<div class="top">
						<div class="title-class">
							<div class="title">Master Report</div>
							<div class="vice-title">Created Date: 2023/12/20 07:31</div>
						</div>
						<div class="voyage-button">
							<!-- <button class="voyage-btn" @click="addReport = true">Add</button> -->
							<!-- <button class="voyage-btn" v-else @click="saveBasicData">Save</button>
							<button class="voyage-btn" @click="closeRightContent">Close</button>
							<button class="voyage-btn" @click="deleteRightContent">Delete</button> -->
						</div>
					</div>
					<div class="center">
						<el-tabs v-model="activeName" @tab-click="tabClick">
							<el-tab-pane label="Ballast" name="first">
								<master-report
									v-if="activeName === 'first' && activeButton === 'report'"
									ref="report"
									:simulation-data="simulationData"
									:tableData="reportTableData"
								></master-report>
								<!-- <master-report-simulation ref="reportSimulation" :simulation-data="simulationData"></master-report-simulation> -->
							</el-tab-pane>
							<el-tab-pane label="Laden" name="second">
								<master-report
									v-if="activeName === 'second' && activeButton === 'report'"
									ref="report"
									:simulation-data="simulationData"
									:tableData="reportTableData"
								></master-report>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</div>
		<!-- 添加报文 -->
		<add-report
			v-if="addReport"
			@close="addReport = false"
			ref="addReport"
			:customer-id="customerId"
			:simulation-data="simulationData"
			:typeBL="typeBL"
			:isEdit="addReportEdit"
			:voyage-no="voyageNo"
		/>
	</div>
</template>

<script>
import { getBasicPageInfo, deleteBasicPageInfo, request, getMasterReport, updateMasterReport } from '@/api/basic';
import { pointToLine, getPointListByTime, getDistanceSlrByTime } from '@/utils/myTruf.js';
import { getToken } from '@/api/cookies';
import isError from 'lodash/isError';
import mapobj from '@/utils/mapw.js';
import { loadVesselTrack } from '@/utils/aisVesselTrack';
import TabpaneBasic1 from './components/TabpaneBasic1';
import TabpaneBasic2 from './components/TabpaneBasic2';
import TabpaneBasic3 from './components/TabpaneBasic3';
import TabpaneSimulation1 from './components/TabpaneSimulation1';
import TabpaneSimulation2 from './components/TabpaneSimulation2';
export default {
	props: {},
	components: {
		TabpaneBasic1,
		TabpaneBasic2,
		TabpaneBasic3,
		TabpaneSimulation1,
		TabpaneSimulation2,
		MasterReport: () => import('./components/master-report/index'),
		AddReport: () => import('./components/master-report/add-report'),
	},
	data() {
		return {
			activeButton: 'info',
			activeName: 'first',
			timesValue: '',
			hoursValue: 6,
			uploadUrl: process.env.VUE_APP_ENV == 'production' ? '/prod-api/api/pdf/uploadFile' : '/api/pdf/uploadFile',
			basicBtnList: [
				{
					id: 'info',
					icon: require('../../assets/right-img/info.png'),
				},
				{
					id: 'simulation',
					icon: require('../../assets/right-img/simulation.png'),
				},
				{
					id: 'report',
					icon: require('../../assets/right-img/report.png'),
				},
			],
			aisInfo: {
				lon: '',
				lat: '',
				dateTime: '',
			},
			customerId: '',
			basicData1: null,
			basicData2: null,
			basicData3: null,
			isLoading: false,
			simulationData: {},
			showEdit: true,
			addReport: false, // 添加报文弹窗
			reportTableData: [],
			voyageNo: null,
			addReportEdit: false,
			typeBL: ['Laden', 'laden'],
			addReportForm: {},
			addReportAllData: null,
		};
	},
	created() {},
	mounted() {
		// 编辑报文详情
		this.$bus.$off('editReportTable');
		this.$bus.$on('editReportTable', (editReportId) => {
			this.getReport(editReportId);
		});
		// 复制或者新增报文
		this.$bus.$off('copyReportTable');
		this.$bus.$on('copyReportTable', (formData, selectValue, allData) => {
			this.addReportEdit = false;
			this.addReportForm = {};
			this.addReportAllData = null;
			this.typeBL = ['Laden', 'laden'];
			if (this.activeName == 'first') {
				this.typeBL = ['Ballast', 'ballast'];
			}
			this.addReportSelectValue = selectValue || 'COSP';
			// 复制或编辑
			if (formData && selectValue) {
				// 编辑
				if (allData) {
					this.addReportEdit = true;
					this.addReportAllData = allData;
				}
				this.addReportForm = formData;
			}
			this.addReport = true;
		});
		this.$bus.$on('refreshList', async () => {
			this.showEdit = true;
			await this.getBasicPageInfo(this.customerId || sessionStorage.getItem('customerId'));
			this.sendBasicInfo();
		});
		// 新增航线
		this.$bus.$on('newVoyage', () => {
			this.customerId = '';
			this.voyageNo = null;
			this.showEdit = false;
			this.basicData1 = null;
			this.basicData2 = null;
			this.basicData3 = null;
			this.$refs.tabpaneBasic2Ref.resetForm();
			mapobj.clearHeightLightPorts();
			this.activeButton = 'info';
		});
		// this.$bus.$on('updatePortList', (data) => {
		// 	this.beforeSavePortList(data);
		// });
		// 截图完成
		mapobj.addEventListern('exportImage', (e) => {
			if (this.activeName == 'first') {
				this.$refs.RoutePlan.pdfimage = e.image;
				this.$refs.RoutePlan.loading = false;
			} else {
				this.$refs.RoutePlan1.pdfimage = e.image;
				this.$refs.RoutePlan1.loading = false;
			}
		});
	},
	methods: {
		sendBasicInfo() {
			let basicData1 = this.basicData1;
			let basicData2 = this.basicData2;
			let ballastPort = this.$refs.RoutePlan.portList;
			let ladenPort = this.$refs.RoutePlan1.portList;
			if (ballastPort && ballastPort.length && ballastPort.length > 1) {
				request({
					url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1//sync/voyage-leg',
					method: 'POST',
					body: {
						voyageNo: basicData1.voyageNo,
						legType: 'Ballast',
						vesselName: basicData2.vesselName,
						pic: basicData1.picName,
						imo: basicData2.imo,
						mmsi: basicData2.mmsi,
						departurePortTime: ballastPort[0].time ? new Date(ballastPort[0].time).getTime() : '',
						departurePortName: ballastPort[0].portName,
						departurePortCode: ballastPort[0].name,
						depPortLon: ballastPort[0].lon,
						depPortLat: ballastPort[0].lat,
						eta: ballastPort[ballastPort.length - 1].time
							? new Date(ballastPort[ballastPort.length - 1].time).getTime()
							: '',
						arrivalPortName: ballastPort[ballastPort.length - 1].portName,
						arrivalPortCode: ballastPort[ballastPort.length - 1].name,
						arrPortLon: ballastPort[ballastPort.length - 1].lon,
						arrPortLat: ballastPort[ballastPort.length - 1].lat,
						companyCode: basicData1.customerName,
					},
					header: {
						Authorization: 'Bearer ' + getToken(),
					},
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: 'send Ballast voyageNo Success!',
						});
					} else {
						this.$message({
							type: 'error',
							message: 'send Ballast voyageNo Error!',
						});
					}
				});
			}
			if (ladenPort && ladenPort.length && ladenPort.length > 1) {
				request({
					url: process.env.VUE_APP_FLEET_API + '/gateway/voyage/v1//sync/voyage-leg',
					method: 'POST',
					body: {
						voyageNo: basicData1.voyageNo,
						legType: 'Laden',
						vesselName: basicData2.vesselName,
						pic: basicData1.picName,
						imo: basicData2.imo,
						mmsi: basicData2.mmsi,
						departurePortTime: ladenPort[0].time ? new Date(ladenPort[0].time).getTime() : '',
						departurePortName: ladenPort[0].portName,
						departurePortCode: ladenPort[0].name,
						depPortLon: ladenPort[0].lon,
						depPortLat: ladenPort[0].lat,
						eta: ladenPort[ladenPort.length - 1].time ? new Date(ladenPort[ladenPort.length - 1].time).getTime() : '',
						arrivalPortName: ladenPort[ladenPort.length - 1].portName,
						arrivalPortCode: ladenPort[ladenPort.length - 1].name,
						arrPortLon: ladenPort[ladenPort.length - 1].lon,
						arrPortLat: ladenPort[ladenPort.length - 1].lat,
						companyCode: basicData1.customerName,
					},
					header: {
						Authorization: 'Bearer ' + getToken(),
					},
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: 'send Laden voyageNo Success!',
						});
					} else {
						this.$message({
							type: 'error',
							message: 'send Laden voyageNo Error!',
						});
					}
				});
			}
		},
		changeShipPosition(time) {
			if (this.activeName == 'first') {
				mapobj.addRecommendPoint(this.$refs.RoutePlan.recommendVal, time);
			} else {
				mapobj.addRecommendPoint(this.$refs.RoutePlan1.recommendVal, time);
			}
		},
		async getAtdData(parameter) {
			let typeBL = ['Laden', 'laden'];
			if (this.activeName == 'first') {
				typeBL = ['Ballast', 'ballast'];
			}
			let res = await getMasterReport({
				id: this.customerId,
				type: typeBL[1],
			});
			if (res && res.length) {
				res.forEach((element) => {
					if (element.masterReportCosp) {
						parameter.atd = element.masterReportCosp.cospDate;
					}
				});
			}
			return parameter;
		},
		// 获取日报
		getReport(editReportId) {
			let typeBL = ['Laden', 'laden'];
			if (this.activeName == 'first') {
				typeBL = ['Ballast', 'ballast'];
			}
			this.reportTableData = [];
			// 移除日报图层
			mapobj.reportLayer.clearLayers();
			getMasterReport({
				id: this.customerId,
				type: typeBL[1],
			}).then((res) => {
				if (res) {
					let line;
					if (mapobj.vesselTrackData?.vesselTrackGeometrys) {
						mapobj.vesselTrackData.vesselTrackGeometrys.features.forEach((e) => {
							if (e.geometry.type.includes('Line')) {
								line = e;
							}
						});
					}
					if (line) {
						res.forEach((element, index) => {
							element.masterReportInfo.notice = [];
							// 参考经纬度
							element.masterReportInfo.latConsult = null;
							element.masterReportInfo.lonConsult = null;
							// 参考distanceSlr
							element.masterReportInfo.distanceSlrConsult = 0;
							// 参考timeSlr
							element.masterReportInfo.timeSlrConsult = null;
							let lat = mapobj.ChangeToDu(element.masterReportInfo.lat.replace('′', ''));
							let lon = mapobj.ChangeToDu(element.masterReportInfo.lon.replace('′', ''));
							if (lat && lon) {
								// 将时间字符串转换为时间戳（毫秒）
								const inputTime = new Date(element.masterReportInfo.dateUtc).getTime();
								let consult = getPointListByTime(inputTime, null, line);
								let distance = pointToLine([lon, lat], line);
								if (distance > 3) {
									// 取报文的时间字段，然后提取该时间前后相邻两个时间的AIS点位，按照报文时间在这两个AIS点时间中的位置比例，作为报文分析点位在两AIS点连线上对应比例的位置。
									if (consult) {
										element.masterReportInfo.latConsult = consult[1];
										element.masterReportInfo.lonConsult = consult[0];
										element.masterReportInfo.notice.push('LAT');
										element.masterReportInfo.notice.push('LON');
										// element.masterReportInfo.notice.push(
										// 	'报文中的经纬度信息，与AIS中分析得到的经纬度信息距离，相差超过3nm告警；',
										// );
										// console.log('🚀 ~ res.forEach ~ 报文中的经纬度信息，与AIS中分析得到的经纬度信息距离，相差超过3nm告警；:')
									}
								}
							}
							if (index > 0) {
								// 参考timeSlr
								if (element.masterReportInfo.timeSlr !== '-') {
									const date1 = new Date(element.masterReportInfo.dateUtc);
									const date2 = new Date(res[index - 1].masterReportInfo.dateUtc);
									const diffInMilliseconds = Math.abs(date2 - date1);
									let timeSlr = parseFloat((diffInMilliseconds / (1000 * 60 * 60)).toFixed(3));
									if (timeSlr * 1 != element.masterReportInfo.timeSlr) {
										element.masterReportInfo.timeSlrConsult = timeSlr;
										element.masterReportInfo.notice.push('Time SLR');
									}
								}
								// 参考distanceSlr
								// 两个午报点之间的所有AIS点之间的线段距离相加，与dist SLR对比，超过3nm，dist SRL标红，铃铛变红，鼠标移到铃铛上时会显示异常信息，鼠标移到dist slr框时显示系统分析值。
								// 在计算AIS点之间的线段距离时，导入的午报经纬度也作为AIS点参与计算
								function returnCoordinates(data) {
									lat = mapobj.ChangeToDu(data.lat.replace('′', ''));
									lon = mapobj.ChangeToDu(data.lon.replace('′', ''));
									lon = lon < 0 ? lon + 360 : lon;
									return [lon, lat];
								}
								let aisDistance = getDistanceSlrByTime({
									startTime: new Date(res[index - 1].masterReportInfo.dateUtc).getTime(),
									endTime: new Date(element.masterReportInfo.dateUtc).getTime(),
									starLatLon: returnCoordinates(res[index - 1].masterReportInfo),
									endLatLon: returnCoordinates(element.masterReportInfo),
								});
								if (aisDistance) {
									if (Math.abs(element.masterReportInfo.distanceSlr - aisDistance) > 3) {
										element.masterReportInfo.notice.push('Dist SLR');
										element.masterReportInfo.distanceSlrConsult = aisDistance.toFixed(3);
									}
								}
								// 当前报文的total cons，应该等于这份报文的BROB-前份报文的BROB的绝对值
								let typeList = ['Hfo', 'Lsfo', 'Mdo', 'Mgo'];
								let needSumList = ['totalConsSlr', 'brob'];
								let filed = true;
								// 判断是否存在字段
								typeList.forEach((e) => {
									needSumList.forEach((val) => {
										if (
											(!element.masterReportInfo[val + e] && element.masterReportInfo[val + e] !== 0) ||
											element.masterReportInfo[val + e] === '-'
										) {
											filed = false;
										}
										if (
											(!res[index - 1].masterReportInfo['brob' + e] &&
												res[index - 1].masterReportInfo['brob' + e] !== 0) ||
											res[index - 1].masterReportInfo['brob' + e] === '-'
										) {
											filed = false;
										}
									});
								});
								if (filed) {
									// 参考totalConsSlr
									typeList.forEach((e) => {
										if (
											element.masterReportInfo['totalConsSlr' + e] * 10000 !=
											Math.abs(
												res[index - 1].masterReportInfo['brob' + e] * 10000 -
													element.masterReportInfo['brob' + e] * 10000,
											)
										) {
											element.masterReportInfo['totalConsSlr' + e + 'Consult'] =
												(res[index - 1].masterReportInfo['brob' + e] * 10000 -
													element.masterReportInfo['brob' + e] * 10000) /
												10000;
											element.masterReportInfo.notice.push('Total Cons ' + e.toUpperCase());
										}
									});
								}
							}
							if (
								Math.abs(
									element.masterReportInfo.avgSpeedSlr -
										element.masterReportInfo.distanceSlr / element.masterReportInfo.timeSlr,
								) > 0.2
							) {
								element.masterReportInfo.notice.push('Avg Speed SLR');
								// element.masterReportInfo.notice.push('Avg Speed SLR与Dist To Go/Time SLR两者之间，相差超过0.2告警）');
							}
							this.reportTableData.push(element.masterReportInfo);
						});
					} else {
						res.forEach((element) => {
							this.reportTableData.push(element.masterReportInfo);
						});
					}
					mapobj.addPointByTable(this.reportTableData);
					this.$bus.$emit('reportTable', res);
					if (editReportId) {
						let data = res.find((e) => e.masterReportInfo.id === editReportId);
						if (data.masterReportInfo.notice) {
							data.masterReportInfo.notice = data.masterReportInfo.notice.toString();
						} else {
							data.masterReportInfo.notice = '';
						}
						console.log('🚀 ~ getReport ~ data:', data);
						if (data) {
							updateMasterReport({
								...data,
								updateType: 'bottom',
								voyageNo: this.voyageNo,
							}).then((res) => {
								console.log('🚀 ~ getReport ~ res:', res);
							});
						}
					}
				}
			});
		},
		async goDetail(id) {
			this.isLoading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
			});
			this.showEdit = true;
			await this.getBasicPageInfo(id);
			this.customerId = id;
			if (this.activeButton == 'simulation') {
				this.getReport();
				if (this.activeName == 'second') {
					this.$refs.RoutePlan1.goDetail(id);
				} else {
					this.$refs.RoutePlan.goDetail(id);
				}
			} else if (this.activeButton == 'info') {
				setTimeout(() => {
					this.$refs.tabpaneBasic3Ref.handleSelect();
				}, 1000);
			} else if (this.activeButton == 'report') {
				this.getReport();
			}
		},
		uploadSuccess(response) {
			if (response && response.code == 200) {
				if (response.data && response.data.length) {
					let pointList = [];
					let arrayWpt = [];
					response.data.forEach((element) => {
						let lon = element.LON || element.Lon || element.lon || element.LNG;
						let lat = element.LAT || element.Lat || element.lat;
						let type = element.NAV || element.Nav || element.nav;
						if (lon && lat) {
							if (typeof lon == 'object') {
								let str = '';
								lon.forEach((element, index) => {
									if (index == 0) {
										str += parseInt(element);
										str += '°';
									} else {
										str += element;
									}
								});
								lon = str;
							}
							if (typeof lat == 'object') {
								let str = '';
								lat.forEach((element, index) => {
									if (index == 0) {
										str += parseInt(element);
										str += '°';
									} else {
										str += element;
									}
								});
								lat = str;
							}
							lon = mapobj.ChangeToDu(lon);
							lat = mapobj.ChangeToDu(lat);
							pointList.push([lon, lat]);
						}
						if (type) {
							arrayWpt.push(type);
						}
					});
					if (this.activeName == 'first') {
						this.$refs.RoutePlan.uploadSimulationData(pointList, arrayWpt);
					} else {
						this.$refs.RoutePlan1.uploadSimulationData(pointList, arrayWpt);
					}
				} else {
					this.$message.error('未读取到航线点位信息');
				}
			} else {
				this.$message.error('上传航线失败');
			}
		},
		saveSimulationData() {
			if (this.activeName == 'first') {
				this.$refs.RoutePlan.saveSimulationData();
			} else {
				this.$refs.RoutePlan1.saveSimulationData();
			}
			this.savePortList();
		},
		// beforeSavePortList(data) {
		// 	let portList = this.activeName == 'first' ? this.$refs.RoutePlan.portList : this.$refs.RoutePlan1.portList;
		// 	if (portList.length) {
		// 		portList.forEach((element, index) => {
		// 			if (index < data.length) {
		// 				element.time = data[index].time;
		// 			}
		// 		});
		// 	}
		// 	this.savePortList();
		// },
		savePortList() {
			let routePlanPortList = this.$refs.RoutePlan.portList;
			let routePlan1PortList = this.$refs.RoutePlan1.portList;
			let routePortList = JSON.parse(JSON.stringify(routePlanPortList));
			if (routePlan1PortList.length) {
				routePlan1PortList.forEach((element, index) => {
					if (index > 0) {
						routePortList.push(element);
					} else if (this.activeName != 'first' && routePortList.length) {
						routePortList[routePortList.length - 1] = element;
					}
				});
			}
			this.$refs.tabpaneBasic3Ref.portList = routePortList;
			this.$refs.tabpaneBasic3Ref.saveBasicInfo(true);
			setTimeout(async () => {
				let data = await getBasicPageInfo({
					id: this.customerId || sessionStorage.getItem('customerId'),
				});
				this.simulationData = {
					portList: data.basicPortRemarks?.basicPortRemarksSonList || [],
					cpData: data.basicCpTerms?.basicCpTermsCpPerformances || [],
				};
			}, 2000);
		},
		downSimulationData() {
			if (this.activeName == 'first') {
				this.$refs.RoutePlan.beforeDownSimulationData();
			} else {
				this.$refs.RoutePlan1.beforeDownSimulationData();
			}
		},
		deleteSimulationData() {
			if (this.activeName == 'first') {
				this.$refs.RoutePlan.deleteSimulationData();
			} else {
				this.$refs.RoutePlan1.deleteSimulationData();
			}
		},
		searchSimulationData() {
			if (this.activeName == 'first') {
				this.$refs.RoutePlan.searchSimulationData();
			} else {
				this.$refs.RoutePlan1.searchSimulationData();
			}
		},
		updateSimulationData() {
			request({
				url: process.env.VUE_APP_FLEET_API + `/gateway/voyage/v1/ais/cmes/high/data`,
				//url: process.env.VUE_APP_FLEET_API + `/gateway/voyage/v1/ais/shipdt/current/track`,
				//url: `http://api.fltins.com/gateway/voyage/v1/ais/shipdt/track?mmsi=${mmsi}&days=1`,
				// url: `https://api.fltins.com/gateway/voyage/v1/ais/shipdt/track?mmsi=357928000&days=7`,
				method: 'GET',
				body: {
					mmsi: this.basicData2.mmsi,
					startDate: mapobj.formatDateTime(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
					endDate: mapobj.formatDateTime(new Date()),
					//days: 7,
				},
				header: {
					Authorization: 'Bearer ' + getToken(),
				},
			}).then((res) => {
				if (res && res.code == 200) {
					this.aisInfo = {
						lon: mapobj.ChangeToDFM(res.data.current.lon, 1),
						lat: mapobj.ChangeToDFM(res.data.current.lat, 0),
						dateTime: new Date(
							new Date(res.data.current.updateTime).getTime() + new Date().getTimezoneOffset() * 60000,
						),
					};
				} else {
					this.$message.error('获取AIS数据失败');
				}
			});
		},
		searchUpdateSimulationData() {
			if (this.aisInfo && this.aisInfo.lat && this.aisInfo.lon) {
				let json = {
					lon: mapobj.ChangeToDu(this.aisInfo.lon),
					lat: mapobj.ChangeToDu(this.aisInfo.lat),
					lastTime: mapobj.formatDateTime(this.aisInfo.dateTime),
				};
				if (this.activeName == 'first') {
					this.$refs.RoutePlan.updateSimulationData(json);
				} else {
					this.$refs.RoutePlan1.updateSimulationData(json);
				}
			} else {
				this.$message.warning('请确认AIS位置信息');
			}
		},
		searchSimulationDataByPorts() {
			if (this.activeName == 'first') {
				this.$refs.RoutePlan.searchSimulationDataByPorts();
			} else {
				this.$refs.RoutePlan1.searchSimulationDataByPorts();
			}
		},
		copySimulationData() {
			if (this.activeName == 'first') {
				this.$refs.RoutePlan.copySimulationData();
			} else {
				this.$refs.RoutePlan1.copySimulationData();
			}
		},
		async getBasicPageInfo(id) {
			let params = {
				id: id,
			};
			// 移除历史轨迹
			mapobj.vesselTrackLayer.clearLayers();
			// 移除日报图层
			mapobj.reportLayer.clearLayers();
			let data = await getBasicPageInfo(params);
			if (this.isLoading) {
				this.isLoading.close();
			}
			if (!isError(data)) {
				this.customerId = data.basicCustomer.id;
				this.basicData1 = data.basicCustomer;
				this.basicData2 = data.basicCpTerms;
				this.basicData3 = data.basicPortRemarks;
				this.voyageNo = data.basicCustomer.voyageNo;
				this.$bus.$emit('updateVoyageNo', data.basicCustomer.voyageNo);
				// 记录parentId
				this.simulationData = {
					portList: data.basicPortRemarks?.basicPortRemarksSonList || [],
					cpData: data.basicCpTerms?.basicCpTermsCpPerformances || [],
				};
				this.$emit('changeRight', this.simulationData);
				if (data.basicCpTerms?.mmsi) {
					this.addAISPoint(data.basicCpTerms);
				}
			}
		},
		// AIS点位上图
		async addAISPoint(data) {
			let mmsi = data.mmsi;
			let paramsTime = await this.getAtdData({});
			if (paramsTime && paramsTime.atd) {
				paramsTime.atd = mapobj.formatDateTime(new Date(paramsTime.atd).getTime());
			} else {
				paramsTime.atd = mapobj.formatDateTime(new Date().getTime() - 1000 * 60 * 60 * 24 * 15);
			}
			request({
				url: process.env.VUE_APP_FLEET_API + `/gateway/voyage/v1/ais/cmes/high/data`,
				//url: process.env.VUE_APP_FLEET_API + `/gateway/voyage/v1/ais/shipdt/current/track`,
				//url: `http://api.fltins.com/gateway/voyage/v1/ais/shipdt/track?mmsi=${mmsi}&days=1`,
				//url: `https://api.fltins.com/gateway/voyage/v1/ais/shipdt/track?mmsi=357928000&days=7`,
				method: 'GET',
				body: {
					mmsi: mmsi,
					startDate: paramsTime.atd,
					endDate: mapobj.formatDateTime(new Date()),
					//days: 7,
				},
				header: {
					Authorization: 'Bearer ' + getToken(),
				},
			})
				.then((res) => {
					if (res && res.code == 200) {
						mapobj.addAiSPoint([res.data.current.lon, res.data.current.lat], res.data.current.course);
						// 历史轨迹
						if (res.data?.route) {
							mapobj.aisGeojsonData = res.data.route;
							loadVesselTrack(res.data.route, true);
						}
					} else {
						this.$message.error('获取AIS数据失败');
					}
				})
				.catch((e) => {
					this.$message.error('获取AIS数据失败');
				});
		},
		// 历史轨迹
		addTrack(item, path) {
			request({
				url: process.env.VUE_APP_FLEET_API + `/s3file/shiptrack/${path}`,
				method: 'GET',
				body: {},
				header: {},
			}).then((data) => {
				data.properties = item;
				loadVesselTrack(data, true);
			});
		},
		handleSetCustomerId(id) {
			this.customerId = id;
			sessionStorage.setItem('customerId', id);
		},

		async tabClick() {
			await this.getReport();
			this.addAISPoint(this.basicData2);
			// if (this.activeName !== 'second') {
			// 	// 关闭报文详情
			// 	this.$bus.$emit('reportInfoShow', false);
			// 	// 移除日报图层
			// 	mapobj.reportLayer.clearLayers();
			// } else {
			// 	await this.getReport();
			// }
		},
		tabSimulationClick() {
			// 移除日报图层
			mapobj.reportLayer.clearLayers();
			this.$parent.bottomContentShow = false;
			if (this.activeName == 'first') {
				this.$refs.RoutePlan1.$refs.multipleTable.clearSelection();
				this.$refs.RoutePlan.goDetail(this.customerId);
			} else {
				this.$refs.RoutePlan.$refs.multipleTable.clearSelection();
				this.$refs.RoutePlan1.goDetail(this.customerId);
			}
			this.getReport();
			this.addAISPoint(this.basicData2);
		},
		rightContentClick() {
			this.rightContentShow = !this.rightContentShow;
		},
		basicBtnClick(index) {
			// 关闭报文详情
			this.$bus.$emit('reportInfoShow', false);
			// 移除日报图层
			mapobj.reportLayer.clearLayers();
			this.activeName = 'first';
			this.activeButton = this.basicBtnList[index].id;
			if (this.activeButton == 'simulation') {
				this.getReport();
				if (this.activeName == 'second') {
					this.$refs.RoutePlan1.goDetail(this.customerId);
				} else {
					this.$refs.RoutePlan.goDetail(this.customerId);
				}
			} else if (this.activeButton == 'info') {
				this.$refs.tabpaneBasic3Ref.handleSelect();
			} else {
				mapobj.removeRectangle(); // 清空高亮边框
				mapobj.removeSelectPoint();
				mapobj.clearHeightLightPorts(); // 清空高亮港口图层
				if (mapobj.editLineLayer) {
					mapobj.editLineLayer.pm.disable();
					mapobj.editLineLayerStepList = [];
					mapobj.editLineLayer.clearLayers();
				}
				if (mapobj.circleLineLayer) {
					// 清除当前图层的所有要素
					mapobj.circleLineLayer.clearLayers();
					mapobj.Lmap.removeLayer(mapobj.circleLineLayer);
					mapobj.circleLineLayer = null;
				}
				if (mapobj.pointLayer) {
					// 清除当前图层的所有要素
					mapobj.pointLayer.clearLayers();
					mapobj.pointLayer.removeLayer(mapobj.pointLayer);
					mapobj.pointLayer = null;
				}
				this.getReport();
			}
		},
		saveBasicData() {
			let obj = {
				first: 'tabpaneBasic1Ref',
				second: 'tabpaneBasic2Ref',
				third: 'tabpaneBasic3Ref',
			};
			if (!this.isSaveStatus) {
				this.isSaveStatus = true;
				setTimeout(() => {
					this.isSaveStatus = false;
				}, 2000);
				if (this.customerId) {
					if (!this.$refs[obj['first']].form.customerName) {
						this.$message.info('Customer Name is Empty!');
						return;
					}
					if (!this.$refs[obj['second']].vesselForm || !this.$refs[obj['second']].vesselForm.mmsi) {
						this.$message.info('MMSI is Empty!');
						return;
					}
					if (!this.$refs[obj['third']].portList.length || !this.$refs[obj['third']].portList[0].time) {
						this.$message.info('Time is Empty!');
						return;
					}
					for (let key in obj) {
						this.$refs[obj[key]].saveBasicInfo();
					}
				} else {
					if (!this.$refs[obj['first']].form.customerName) {
						this.$message.info('Customer Name is Empty!');
						return;
					}
					if (!this.$refs[obj['second']].vesselForm || !this.$refs[obj['second']].vesselForm.mmsi) {
						this.$message.info('MMSI is Empty!');
						return;
					}
					if (!this.$refs[obj['third']].portList.length || !this.$refs[obj['third']].portList[0].time) {
						this.$message.info('Time is Empty!');
						return;
					}
					this.$refs[obj['first']].saveBasicInfo(true);
				}
			}
		},
		saveBasicInfoAwait() {
			let obj = {
				first: 'tabpaneBasic1Ref',
				second: 'tabpaneBasic2Ref',
				third: 'tabpaneBasic3Ref',
			};
			this.$refs[obj['second']].saveBasicInfo();
			this.$refs[obj['third']].saveBasicInfo();
		},
		deleteRightContent() {
			this.$confirm('确认要删除此航次吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				deleteBasicPageInfo({
					id: this.customerId,
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '删除成功!',
					});
					request({
						url:
							process.env.VUE_APP_FLEET_API +
							'/gateway/voyage/v1/sync/voyage-leg?voyageNo=' +
							this.basicData1.voyageNo +
							'&legType=Ballast',
						method: 'DELETE',
						body: {},
						header: {
							Authorization: 'Bearer ' + getToken(),
						},
					}).then((res) => {});
					request({
						url:
							process.env.VUE_APP_FLEET_API +
							'/gateway/voyage/v1/sync/voyage-leg?voyageNo=' +
							this.basicData1.voyageNo +
							'&legType=Laden',
						method: 'DELETE',
						body: {
							voyageNo: this.basicData1.voyageNo,
							legType: 'Laden',
						},
						header: {
							Authorization: 'Bearer ' + getToken(),
						},
					}).then((res) => {});
					this.$bus.$emit('refreshList');
					this.$parent.rightContentShow = false;
				});
			});
		},
		closeRightContent() {
			this.$emit('closeRightContent');
			this.$bus.$emit('cancelLeftActive');
		},

		/**
		 * 处理鼠标按下事件
		 *
		 * @param {MouseEvent} 鼠标事件
		 */
		handleMouseDown(event) {
			// 禁止用户选择网页中文字
			document.onselectstart = () => false;
			// 禁止用户拖动元素
			document.ondragstart = () => false;
			// 保存鼠标最后移动的位置（Y轴）
			this.dragState = {
				// 鼠标开始移动的位置（Y轴）
				startMouseRight: event.clientX,
				// 鼠标最后移动的位置（Y轴）
				endMouseRight: event.clientX,
			};
			const { rWrap, rResizeLine } = this.$refs;
			const oldTextAreawidth = rWrap.getBoundingClientRect().width;
			console.log('🚀 ~ handleMouseDown ~ oldTextAreawidth:', oldTextAreawidth);
			// 绑定鼠标移动事件
			document.addEventListener('mousemove', this.handleMouseMove);
			// 绑定鼠标放开事件
			document.addEventListener('mouseup', this.handleMouseUp);
		},

		/**
		 * 处理鼠标移动事件
		 *
		 * @param {MouseEvent} 鼠标事件
		 */
		handleMouseMove(event) {
			const TextAreaWrap = {
				MaxWidth: 1350,
				MinWidth: 32,
			};
			const { rWrap, rResizeLine } = this.$refs;
			// 获取鼠标最后移动的位置（Y轴）
			const { endMouseRight } = this.dragState;
			// 获取当前的文本框高度
			const oldTextAreawidth = rWrap.getBoundingClientRect().width;
			console.log(oldTextAreawidth);
			// 新的文本框高度
			let newTextAreaWidth = 0;

			// 计算鼠标移动的距离
			const distance = Math.abs(parseInt(((endMouseRight - event.clientX) * 100).toString(), 10) / 100);

			// 若鼠标向上移动
			if (endMouseRight > event.clientX) {
				// 发送框高度达到最大
				if (oldTextAreawidth >= TextAreaWrap.MaxWidth) {
					// 修改光标为可被向右侧移动
					rResizeLine.style.cursor = 'w-resize';
					return false;
				}

				// 计算新的发送框高度
				newTextAreaWidth = oldTextAreawidth + distance;
			}
			// 若鼠标向下移动
			else {
				// 发送框高度达到最小
				if (oldTextAreawidth <= TextAreaWrap.MinWidth) {
					// 修改光标为可被向上移动
					rResizeLine.style.cursor = 'e-resize';
					return false;
				}

				// 计算新的发送框高度
				newTextAreaWidth = oldTextAreawidth - distance;
			}

			// 兼容鼠标快速拖动的情况：新的发送框高度不能超过最大值
			if (newTextAreaWidth > TextAreaWrap.MaxWidth) {
				newTextAreaWidth = TextAreaWrap.MaxWidth;
			}

			// 兼容鼠标快速拖动的情况：新的发送框高度不能小于最小值
			if (newTextAreaWidth < TextAreaWrap.MinWidth) {
				newTextAreaWidth = TextAreaWrap.MinWidth;
			}

			// 修改发送框高度
			rWrap.style.width = newTextAreaWidth + 'px';
			// 修改光标为可移动
			rResizeLine.style.cursor = 'move';

			// 更新鼠标最后移动的位置（Y轴）
			this.dragState.endMouseRight = event.clientX;
			this.$emit('changeWidth', newTextAreaWidth);
			// this.tableHeight = document.getElementsByClassName('drag-bottom1')[0].clientHeight - 70;
		},

		/**
		 * 处理鼠标放开事件
		 */
		handleMouseUp() {
			// 移除鼠标移动事件
			document.removeEventListener('mousemove', this.handleMouseMove);
			// 移除鼠标放开事件
			document.removeEventListener('mouseup', this.handleMouseUp);
			// 允许用户选择网页中文字
			document.onselectstart = null;
			// 允许用户拖动元素
			document.ondragstart = null;
		},
	},
};
</script>

<style lang="scss" scoped>
.text-row-class {
	margin-top: 7px;
}

.voyage-btn-ais {
	padding: 5px 5px;
	margin-right: 10px;
	font-size: 12px;
	min-width: 70px;
	height: 32px;
	border-radius: 6px;
	background: #fff;
	color: #355691;
	border: 1px solid #d9d9d9;
	cursor: pointer;
	float: right;
}

.text-label-ais {
	color: #484848;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 450;
	line-height: 22px;
	/* 183.333% */
}

.right-content {
	position: absolute;
	width: 739px;
	top: 0;
	right: 0;
	z-index: 999;
	height: 100%;
	border-width: 0px, 1px, 1px, 0px;
	border-style: solid;
	border-color: #e1e1e1;

	display: flex;
	flex-flow: row;

	.resize-line {
		height: 40px;
		width: 6px;
		background: rgba(0, 0, 0, 0.2);
		cursor: move;
		background: #ccc;
		position: absolute;
		top: 50%;
		left: 6px;
		border-radius: 5px;
	}

	.basic-list {
		width: 32px;
		font-size: 22px;
		color: #355691;
		cursor: pointer;
		background: #eef1f4;

		.basic-btn {
			width: 32px;
			height: 32px;
			cursor: pointer;
			background: rgba(53, 86, 145, 0.06);

			padding: 2px 0 0 8px;
		}

		.active {
			background: #fff;
		}
	}

	.content {
		overflow: auto;

		::v-deep .el-descriptions-item__cell {
			text-align: center;
		}

		.table-item {
			padding: 5px;
		}

		::v-deep .el-descriptions__body {
			border: 1px solid #d9d9d9;
			border-radius: 4px;
		}

		width: calc(100% - 32px);
		height: 100%;
		padding: 20px;
		background: white;

		::v-deep .basic-content,
		::v-deep .simulation-content {
			.top {
				display: flex;
				flex-flow: row;
				justify-content: space-between;

				.title-class {
					// width: 582px;

					.title {
						color: #484848;
						font-size: 20px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					.vice-title {
						font-size: 12px;
						height: 22px;
						line-height: 22px;
						letter-spacing: 0em;
						text-align: left;
						color: #adadad;
					}
				}

				.voyage-button {
					display: flex;
					max-width: 680px;
					overflow: auto;
					.ais-position {
						width: 500px;
					}

					.voyage-btn {
						padding: 5px 5px;
						margin-right: 5px;
						font-size: 12px;
						min-width: 55px;
						height: 32px;
						border-radius: 6px;
						background: #fff;
						color: #355691;
						border: 1px solid #d9d9d9;
						cursor: pointer;
					}

					.voyage-btn-size {
						padding: 0;
						min-width: 90px;
					}

					.common-btn {
						color: #484848;
						border: 1px solid #d9d9d9;
					}
				}
			}

			.elrow-class {
				margin-top: 3px;
			}

			.text-row-class {
				margin-top: 7px;
			}

			.text-label {
				color: #484848;
				font-family: Roboto;
				font-size: 12px;
				font-style: normal;
				font-weight: 450;
				line-height: 22px;
				/* 183.333% */
			}

			.text-label-bold {
				font-family: Roboto;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 22px;
				color: rgba(0, 0, 0, 0.85);
				/* 157.143% */
			}

			.el-search-input {
				width: 100%;
			}

			.el-table-input {
				width: 61px;
			}
		}
	}
}
</style>

<style>
.custom-content {
	display: flex !important;
	width: 300px !important;
}

.custom-icon {
	width: 20px !important;
	height: 20px !important;
}

.custom-text {
	background: red;
	height: 20px;
	width: 150px;
	text-align: center;
	line-height: 20px;
	color: #fff;
	font-size: 14px;
	border-radius: 5px;
	margin-left: 5px;
}

.monitor-yt-popover {
	background-color: #303133;
	border-color: #303133;
	color: #fff;
}

.monitor-yt-popover .el-popover__title {
	color: white;
}

.monitor-yt-popover .popper__arrow::after {
	border-top-color: #303133 !important;
}
</style>

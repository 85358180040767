<template>
	<div class="container">
		<div class="header">
			<div class="icon"></div>
			<div class="title">
				<div class="main-title">Voyage list</div>
				<div class="vice-title">voyage No.23CW001</div>
			</div>
			<div class="voyage-button">
				<el-button @click="newVoyage" size="mini" type="primary" style="background: #467996">+ New Voyage</el-button>
			</div>
		</div>
		<div class="search">
			<div class="search-input">
				<el-input
					placeholder="Search"
					class="el-search-input"
					@blur="search"
					@keyup.enter.native="search"
					suffix-icon="el-icon-search"
					v-model="searchKey"
					clearable
				>
				</el-input>
			</div>
			<!-- <div class="filter-btn"></div> -->
		</div>
		<div class="line"></div>
		<div class="card-list-total" v-loading="isLoading">
			<div
				:class="['card-list', item.id === currentItemId ? 'active-card' : '']"
				@click="goDetail(item)"
				v-for="(item, index) in infoList"
				:key="index"
			>
				<el-card class="box-card" shadow="hover">
					<el-row>
						<el-col :span="12">
							<div class="grid-content bg-purple-dark card-title-left">
								{{ item.basicCpTerms?.vesselName }}
							</div>
						</el-col>
						<el-col :span="12">
							<div class="grid-content bg-purple-dark card-right">
								<el-tag>Preparation</el-tag>
							</div>
						</el-col>
					</el-row>
					<el-row class="card-content">
						<el-col :span="12">
							<div class="grid-content bg-purple-dark card-content-left">{{ item.customerName }}</div>
						</el-col>
						<el-col :span="12">
							<div class="grid-content bg-purple-dark card-right card-content-right">Commence date</div>
						</el-col>
					</el-row>
					<el-row class="card-content">
						<el-col :span="12">
							<div class="grid-content bg-purple-dark card-content-left">{{ item.deliveryPort }}</div>
						</el-col>
						<el-col :span="12">
							<div class="grid-content bg-purple-dark card-right card-content-right">{{ item.reDeliveryPort }}</div>
						</el-col>
					</el-row>
					<el-row class="card-content">
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">Cargo:</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">{{ item.basicPortRemarks.cargo }}</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">Deck cargo:</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">{{ item.basicPortRemarks.deckCargo }}</div>
						</el-col>
					</el-row>
					<el-row class="card-content">
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">GM:</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">{{ item.basicPortRemarks.gm }}</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">Roll Period:</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">{{ item.basicPortRemarks.rollPeriod }}</div>
						</el-col>
					</el-row>
					<el-row class="card-content">
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">FORE Drift:</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">{{ item.basicPortRemarks.foreDrift }}</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">AFT Drift:</div>
						</el-col>
						<el-col :span="6">
							<div class="grid-content bg-purple-dark card-content-left">{{ item.basicPortRemarks.aftDrift }}</div>
						</el-col>
					</el-row>
				</el-card>
			</div>
		</div>
	</div>
</template>
<script>
import { getBasicPageList } from '@/api/basic';
import isError from 'lodash/isError';
import { getToken } from '@/api/cookies';
import { request } from '@/api/basic';
import { cloneDeep } from 'lodash';
import mapobj from '@/utils/mapw.js';
export default {
	name: 'LeftContent',
	components: {},
	data() {
		return {
			searchKey: '',
			fullScreen: false,
			infoList: [],
			isLoading: true,
			currentItemId: 0,
		};
	},

	mounted() {
		this.getBasicPageList();
		this.$bus.$on('refreshList', () => {
			//console.log('refreshList');
			this.getBasicPageList();
		});
		this.$bus.$on('cancelLeftActive', () => {
			this.currentItemId = 0;
		});
	},
	methods: {
		async getBasicPageList() {
			let params = {
				pageNum: 1,
				pageSize: 9999,
				value: this.searchKey,
			};
			this.isLoading = true;
			let data = await getBasicPageList(params);
			this.isLoading = false;

			if (!isError(data)) {
				this.infoList = data.list.map((item) => {
					let deliveryPort = item.basicPortRemarks?.basicPortRemarksSonList[0]?.name;
					let reDeliveryPort =
						item.basicPortRemarks?.basicPortRemarksSonList[item.basicPortRemarks?.basicPortRemarksSonList.length - 1]
							?.name;
					return {
						...item,
						deliveryPort,
						reDeliveryPort,
					};
				});
				this.initInfoList = cloneDeep(this.infoList);
			}
		},
		clearLayer() {
			// 关闭报文详情
			this.$bus.$emit('reportInfoShow', false);
			mapobj.removeRectangle(); // 清空高亮边框
			mapobj.clearHeightLightPorts(); // 清空高亮港口图层
			this.currentItemId = 0;
			mapobj.removeSelectPoint();
			// 移除历史轨迹
			mapobj.vesselTrackLayer.clearLayers();
			// 移除日报图层
			mapobj.reportLayer.clearLayers();
			mapobj.addAiSPoint();
			// if (mapobj.aisPointLayer) {
			// 	console.log(2222222222222222);
			// 	//mapobj.Lmap.removeLayer(mapobj.aisPointLayer);
			// }
		},
		newVoyage() {
			sessionStorage.removeItem('customerId');
			this.$bus.$emit('newVoyage');
			this.$emit('showRightContent');
			this.clearLayer();
		},

		goDetail(item) {
			// 关闭报文详情
			this.$bus.$emit('reportInfoShow', false);
			mapobj.removeRectangle(); // 清空高亮边框
			mapobj.clearHeightLightPorts(); // 清空高亮港口图层
			this.$emit('showRightContent');
			//this.$bus.$emit('goDetail', item.id);
			this.currentItemId = item.id;
			sessionStorage.setItem('customerId', item.id);
			mapobj.removeSelectPoint();
			setTimeout(() => {
				this.$parent.bottomContentShow = false;
				this.$parent.$refs.rightContent.goDetail(item.id);
			}, 100);
		},

		search() {
			this.pageNum = 1;
			this.getBasicPageList();
			return;
			if (!this.searchKey) {
				this.infoList = this.initInfoList;
				return;
			}
			this.infoList = this.initInfoList.filter((item) => {
				let name = item.basicCpTerms?.vesselName;
				if (name && name.toUpperCase().includes(this.searchKey.toUpperCase())) {
					return item;
				}
			});
		},

		toggleFull() {
			this.fullScreen = !this.fullScreen;
		},
		close() {
			this.$emit('close');
		},
		save() {
			this.$emit('save');
		},
	},
};
</script>
<style lang="scss" scoped>
.container {
	position: absolute;
	left: 0;
	top: 0;
	width: 379px;
	height: 100%;
	background: #eef1f4;
	z-index: 199;
	border: 0px, 0px, 0px, 1px;
	border-color: #d9d9d9;
	padding: 30px 24px;

	.header {
		height: 52px;
		display: flex;
		flex-flow: row;
		font-size: 14px;

		.icon {
			margin-top: 5px;
			width: 8px;
			height: 18px;
			border-radius: 37px;
			background: #355691;
		}

		.title {
			margin-left: 7px;

			.main-title {
				color: #484848;
				font-size: 24px;
				font-weight: 500;
				line-height: 28.13px;
			}

			.vice-title {
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0em;
				text-align: left;
				color: #adadad;
				margin-top: 5px;
			}
		}

		.voyage-button {
			position: absolute;
			right: 24px;
		}
	}

	.search {
		display: flex;
		flex-flow: row;
		margin-top: 12px;

		.el-search-input {
			width: 339px;
		}

		// ::v-deep .el-input__suffix {
		// 	left: 8px;
		// 	right: unset;
		// }

		::v-deep .el-input__inner {
			text-indent: 20px;
		}
	}

	.line {
		width: 347px;
		border: 1px #d9d9d9 solid;
		margin-top: 8px;
		position: relative;
		left: -4px;
	}

	.card-list-total {
		height: 780px;
		width: 105%;
		overflow-y: auto;
		overflow-x: hidden;
		cursor: pointer;

		.card-list {
			margin-top: 8px;
			width: 339px;

			.card-title-left {
				font-family: Roboto;
				font-size: 14px;
				font-weight: 600;
				line-height: 22px;
				letter-spacing: 0em;
				text-align: left;
				color: #000000;
			}

			.card-content-left {
				font-family: Roboto;
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0em;
				text-align: left;
				color: #adadad;
			}

			.card-content {
				margin-top: 4px;
			}

			::v-deep .el-tag {
				height: 22px;
				line-height: 22px;
				font-size: 12px;
			}

			.card-right {
				float: right;
			}

			.card-content-right {
				font-family: Roboto;
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0em;
				text-align: left;
				color: #adadad;
			}
		}
		.active-card {
			.box-card {
				border: 1px solid #409eff;
			}
		}
	}
}
</style>
